import { action } from "typesafe-actions"
import { ENUMERATE_GRANTS, REVOKE_GRANT } from "./actionDef";
import { GrantInformation } from "../../../shared/utils/DataAccess";


export interface RevokeGrantInformation {
  clientId: string,
  id: number
}

export interface GrantsAction {
  type: string;
  payload?: GrantInformation[] | RevokeGrantInformation
}


export const enumerateGrants = (): GrantsAction => action(ENUMERATE_GRANTS);
export const revokeGrant = (revoke: RevokeGrantInformation): GrantsAction => action(REVOKE_GRANT, revoke);