import { Middleware } from 'redux';

import {
    ResourceInformation
} from '../../../../../../shared/utils/DataAccess';
import {
    LOAD_RESOURCE, LOAD_RESOURCE_SUCCESS, LOAD_RESOURCE_ERROR,
    UPDATE_RESOURCE, UPDATE_RESOURCE_SUCCESS, UPDATE_RESOURCE_ERROR,
} from './actionDef';
import { AdminResourceAction, UpdateResourceRequestWithId } from './actions';
import APIClientFactory from '../../../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../../../shared/spinner/redux/reducer';



const adminResourceDataService: Middleware = store => next => (requestedAction: AdminResourceAction) => {
    next(requestedAction);

    const Resource = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case LOAD_RESOURCE:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            Resource.getResource(requestedAction.payload as number)
                .then((newResource: ResourceInformation) => {
                    store.dispatch(action(LOAD_RESOURCE_SUCCESS, newResource));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(LOAD_RESOURCE_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case UPDATE_RESOURCE:
            const updateWithID: UpdateResourceRequestWithId = (requestedAction.payload as UpdateResourceRequestWithId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            Resource.updateResource(updateWithID.id, updateWithID.request)
                .then(() => {
                    store.dispatch(action(UPDATE_RESOURCE_SUCCESS, updateWithID));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(UPDATE_RESOURCE_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminResourceDataService;