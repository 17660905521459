import { GrantsAction, RevokeGrantInformation } from "./actions";
import { GrantInformation } from "../../../shared/utils/DataAccess";
import { ENUMERATE_GRANTS_SUCCESS, ENUMERATE_GRANTS_ERROR, REVOKE_GRANT_SUCCESS, REVOKE_GRANT_ERROR } from "./actionDef";


export interface GrantsState {
  list: {
    byId: {
      [id: number]: GrantInformation
    };
    allIds: number[];
  }
};


export const initialState: GrantsState = {
  list: {
    byId: {},
    allIds: []
  }
};


export function grantsReducer(state: GrantsState = initialState, action: GrantsAction): GrantsState {
  switch (action.type) {
    case ENUMERATE_GRANTS_SUCCESS:
      const result = action.payload as GrantInformation[];

      var byIds: any = {};
      var allIDs: number[] = [];

      for (var idx: number = 0; idx < result.length; idx++) {
        byIds[result[idx].created.getTime()] = result[idx];
        allIDs.push(result[idx].created.getTime());
      }

      return {
        ...state,
        list: {
          byId: byIds,
          allIds: allIDs
        }
      };
    case REVOKE_GRANT_SUCCESS:
      const revoke = action.payload as RevokeGrantInformation;
      const newAllIds = [...state.list.allIds];
      const idIndex = newAllIds.findIndex(el => el === (revoke.id));
      newAllIds.splice(idIndex, 1);

      const newByids = { ...state.list.byId };
      delete newByids[revoke.id];

      return {
        ...state,
        list: {
          byId: {
            ...newByids
          },
          allIds: newAllIds
        }
      };
    case ENUMERATE_GRANTS_ERROR:
    case REVOKE_GRANT_ERROR:
    default:
      return state;
  }
}