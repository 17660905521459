export const DISPLAY_STATE_CHANGE = "DISPLAY_STATE_CHANGE";
export type DISPLAY_STATE_CHANGE = typeof DISPLAY_STATE_CHANGE;

export const ENUMERATE_USERS = "ENUMERATE_USERS";
export type ENUMERATE_USERS = typeof ENUMERATE_USERS;
export const ENUMERATE_USERS_SUCCESS = "ENUMERATE_USERS_SUCCESS";
export type ENUMERATE_USERS_SUCCESS = typeof ENUMERATE_USERS_SUCCESS;
export const ENUMERATE_USERS_ERROR = "ENUMERATE_USERS_ERROR";
export type ENUMERATE_USERS_ERROR = typeof ENUMERATE_USERS_ERROR;

export const ADD_USER = "ADD_USER";
export type ADD_USER = typeof ADD_USER;
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export type ADD_USER_SUCCESS = typeof ADD_USER_SUCCESS;
export const ADD_USER_ERROR = "ADD_USER_ERROR";
export type ADD_USER_ERROR = typeof ADD_USER_ERROR;

export const UPDATE_USER = "UPDATE_USER";
export type UPDATE_USER = typeof UPDATE_USER;
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export type UPDATE_USER_SUCCESS = typeof UPDATE_USER_SUCCESS;
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export type UPDATE_USER_ERROR = typeof UPDATE_USER_ERROR;

export const REMOVE_USER = "REMOVE_USER";
export type REMOVE_USER = typeof REMOVE_USER;
export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";
export type REMOVE_USER_SUCCESS = typeof REMOVE_USER_SUCCESS;
export const REMOVE_USER_ERROR = "REMOVE_USER_ERROR";
export type REMOVE_USER_ERROR = typeof REMOVE_USER_ERROR;