import { ChangeEditState, AdminClientPropertiesAction } from "./actions";
import { ClientPropertyInformation } from "../../../../../../shared/utils/DataAccess";
import {
    ENUMERATE_CLIENT_PROPERTIES_SUCCESS, ENUMERATE_CLIENT_PROPERTIES_ERROR,
    ADD_CLIENT_PROPERTY_SUCCESS, ADD_CLIENT_PROPERTY_ERROR,
    DELETE_CLIENT_PROPERTY_SUCCESS, DELETE_CLIENT_PROPERTY_ERROR, DISPLAY_STATE_CHANGE, UPDATE_CLIENT_PROPERTY_SUCCESS,
} from "./actionDef";
import _ from "lodash";
import { DisplayState } from "../../../../../../shared/FullTable/components/FullTableRow";


export interface ClientPropertyInformationDisplay extends ClientPropertyInformation {
    displayState: DisplayState
}


export interface AdminClientPropertiesState {
    list: {
        byId: {
            [id: number]: ClientPropertyInformationDisplay
        };
        allIds: number[];
    };
    newProperty?: ClientPropertyInformation;
};


export const initialState: AdminClientPropertiesState = {
    list: {
        byId: {},
        allIds: []
    },
    newProperty: undefined
};


export function adminClientPropertiesReducer(state: AdminClientPropertiesState = initialState, action: AdminClientPropertiesAction): AdminClientPropertiesState {
    switch (action.type) {
        case DISPLAY_STATE_CHANGE:
            const stateChange = action.payload as ChangeEditState;

            return {
                ...state,
                list: {
                    ...state.list,
                    byId: {
                        ...state.list.byId,
                        [stateChange.id]: {
                            ...state.list.byId[stateChange.id],
                            displayState: stateChange.displayState
                        } as ClientPropertyInformationDisplay
                    }
                }
            };

        case ENUMERATE_CLIENT_PROPERTIES_SUCCESS:
            const claimsInfo = action.payload as ClientPropertyInformation[];

            var claims: ClientPropertyInformationDisplay[] = [];
            claimsInfo.forEach(claim => {
                claims.push({
                    ...claim,
                    displayState: DisplayState.ReadOnly
                } as ClientPropertyInformationDisplay);
            });

            return {
                ...state,
                list: {
                    byId: {
                        ..._.keyBy(claims, "id")
                    },
                    allIds: _.map(claims, "id")
                }
            };
        case ADD_CLIENT_PROPERTY_SUCCESS:
        case UPDATE_CLIENT_PROPERTY_SUCCESS:
            const newProperty = action.payload as ClientPropertyInformation;

            var newAddUpdateAllIds: number[] = state.list.allIds;
            var found: boolean = false;
            newAddUpdateAllIds.forEach((id: number) => {
                if (id === newProperty.id) {
                    found = true;
                }
            });

            if (!found) {
                newAddUpdateAllIds.push(newProperty.id)
            }

            return {
                ...state,
                list: {
                    byId: {
                        ...state.list.byId,
                        [newProperty.id]: {
                            ...newProperty,
                            displayState: DisplayState.ReadOnly
                        } as ClientPropertyInformationDisplay
                    },
                    allIds: newAddUpdateAllIds
                },
                newProperty: newProperty
            };
        case DELETE_CLIENT_PROPERTY_SUCCESS:
            const newAllIds = [...state.list.allIds];
            const idIndex = newAllIds.findIndex(el => el === action.payload as number);
            newAllIds.splice(idIndex, 1);

            const newByids = { ...state.list.byId };
            delete newByids[action.payload as number];

            return {
                ...state,
                list: {
                    byId: {
                        ...newByids
                    },
                    allIds: newAllIds
                }
            };
        case ENUMERATE_CLIENT_PROPERTIES_ERROR:
        case ADD_CLIENT_PROPERTY_ERROR:
        case DELETE_CLIENT_PROPERTY_ERROR:
        default:
            return state;
    }
}