import { action } from "typesafe-actions";
import { ENUMERATE_ADMINISTRATORS, ADD_ADMINISTRATOR, REMOVE_ADMINISTRATOR } from "./actionDef";
import { AddAdministratorRequest, AdministratorInformation, UserInformation } from "../../../../shared/utils/DataAccess";


export interface AdminAdministratorsAction {
    type: string;
    payload?:
    string | AddAdministratorRequest |
    AdminAdministratorsError | AdministratorInformation |
    AdministratorInformation[] | AddAdministratorRequestWithUserInformation |
    null;
}


export interface AddAdministratorRequestWithUserInformation {
    request: AddAdministratorRequest;
    user: UserInformation;
}


export interface AdminAdministratorsError {
    id: string;
    reason: any;
}


export const enumerateAdministrators = (): AdminAdministratorsAction => action(ENUMERATE_ADMINISTRATORS, null);
export const addAdministrator = (request: AddAdministratorRequest): AdminAdministratorsAction => action(ADD_ADMINISTRATOR, request);
export const removeAdministrator = (id: string): AdminAdministratorsAction => action(REMOVE_ADMINISTRATOR, id);
