import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { TextField, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { updateClient } from '../redux/actions';
import { ConfigUpdateDto, ClientConfigInformation } from '../../../../../../shared/utils/DataAccess';


type Props = {
    clientInformation: ClientConfigInformation
}


const ClientEditComponent: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();


    const [updateClientRequest, setupdateClientRequest] = useState<ClientConfigInformation>(ClientConfigInformation.fromJS({
        id: 0,
        allowAccessTokensViaBrowser: false,
        allowOfflineAccess: false,
        clientId: 0,
        clientName: 0,
        consentLifetime: 0,
        requireClientSecret: false,
        requirePkce: false
    }));


    if (updateClientRequest.id !== props.clientInformation.id) {
        setupdateClientRequest(ClientConfigInformation.fromJS({
            id: props.clientInformation.id,
            allowAccessTokensViaBrowser: props.clientInformation.allowAccessTokensViaBrowser,
            allowOfflineAccess: props.clientInformation.allowOfflineAccess,
            clientId: props.clientInformation.clientId,
            clientName: props.clientInformation.clientName,
            consentLifetime: props.clientInformation.consentLifetime,
            requireClientSecret: props.clientInformation.requireClientSecret,
            requirePkce: props.clientInformation.requirePkce
        }));
    }


    const hasChanges = (): boolean => {
        return updateClientRequest.allowAccessTokensViaBrowser !== props.clientInformation.allowAccessTokensViaBrowser ||
            updateClientRequest.allowOfflineAccess !== props.clientInformation.allowAccessTokensViaBrowser ||
            updateClientRequest.clientId !== props.clientInformation.clientId ||
            updateClientRequest.clientName !== props.clientInformation.clientName ||
            updateClientRequest.consentLifetime !== props.clientInformation.consentLifetime ||
            updateClientRequest.requireClientSecret !== props.clientInformation.requireClientSecret ||
            updateClientRequest.requirePkce !== props.clientInformation.requirePkce;
    }


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAddClientRequest: ClientConfigInformation = ClientConfigInformation.fromJS({
            ...updateClientRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setupdateClientRequest(newAddClientRequest);
    }


    const onCheckFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAddClientRequest: ClientConfigInformation = ClientConfigInformation.fromJS({
            ...updateClientRequest,
            [e.currentTarget.id]: e.currentTarget.checked
        });

        setupdateClientRequest(newAddClientRequest);
    }


    const onSubmit = () => {
        const request: ConfigUpdateDto = ConfigUpdateDto.fromJS({
            clientId: updateClientRequest.clientId,
            clientName: updateClientRequest.clientName,
            requirePkce: updateClientRequest.requirePkce,
            requireClientSecret: updateClientRequest.requireClientSecret,
            allowOfflineAccess: updateClientRequest.allowOfflineAccess,
            allowAccessTokensViaBrowser: updateClientRequest.allowAccessTokensViaBrowser,
        });

        dispatch(updateClient(updateClientRequest.id, request));
    }


    const classes = makeStyles({
        row: {
            maxWidth: "600px"
        }
    })();


    return (
        <>
            <div className={classes.row}>
                <TextField
                    id="clientId"
                    label="Client Id"
                    fullWidth={true}
                    value={updateClientRequest.clientId || ""}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    id="clientName"
                    label="Name"
                    fullWidth={true}
                    value={updateClientRequest.clientName || ""}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="allowAccessTokensViaBrowser"
                            checked={updateClientRequest.allowAccessTokensViaBrowser || false}
                            onChange={onCheckFieldChange}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                            style={{ float: 'right' }}
                        />
                    }
                    label="Allow Access Tokens Via Browser"
                />
            </div>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="allowOfflineAccess"
                            checked={updateClientRequest.allowOfflineAccess || false}
                            onChange={onCheckFieldChange}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                            style={{ float: 'right' }}
                        />
                    }
                    label="Allow Offline Access"
                />
            </div>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="requireClientSecret"
                            checked={updateClientRequest.requireClientSecret || false}
                            onChange={onCheckFieldChange}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                            style={{ float: 'right' }}
                        />
                    }
                    label="Require Client Secret"
                />
            </div>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="requirePkce"
                            checked={updateClientRequest.requirePkce || false}
                            onChange={onCheckFieldChange}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                            style={{ float: 'right' }}
                        />
                    }
                    label="Require Pkce"
                />
            </div>
            <div className={classes.row} style={{ textAlign: 'right' }}>
                <Button variant="outlined" color="primary" onClick={onSubmit} disabled={!hasChanges()}>Save Client</Button>
            </div>
        </>
    );


}


export default ClientEditComponent;