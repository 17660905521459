import React, { FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StoreState } from '../../redux/store';
import { setError } from '../redux/actions';

import { Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';


const ErrorComponent: FunctionComponent = () => {
    const dispatch = useDispatch();


    const errorState: any = useSelector((state: StoreState) => {
        if (state.errorReducer.display === "Unauthorized") {
            return null;
        }
        return state.errorReducer;
    });


    const onDismiss = () => {
        dispatch(setError({ errorDetails: null, title: null, display: null }));
    }


    return (
        errorState && errorState.display ?
            <Dialog open={true}>
                <DialogTitle>{errorState.title}</DialogTitle>
                <DialogContent>
                    {errorState.display}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={onDismiss}>OK</Button>
                </DialogActions>
            </Dialog>
            :
            <></>
    );


};


export default ErrorComponent;