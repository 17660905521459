import { ChangeEditState, AdminResourceSecretsAction } from "./actions";
import { ResourceSecretInformation } from "../../../../../../shared/utils/DataAccess";
import {
    ENUMERATE_RESOURCE_SECRETS_SUCCESS, ENUMERATE_RESOURCE_SECRETS_ERROR,
    ADD_RESOURCE_SECRET_SUCCESS, ADD_RESOURCE_SECRET_ERROR,
    DELETE_RESOURCE_SECRET_SUCCESS, DELETE_RESOURCE_SECRET_ERROR, DISPLAY_STATE_CHANGE, UPDATE_RESOURCE_SECRET_SUCCESS,
} from "./actionDef";
import _ from "lodash";
import { DisplayState } from "../../../../../../shared/FullTable/components/FullTableRow";


export interface ResourceSecretInformationDisplay extends ResourceSecretInformation {
    displayState: DisplayState
}


export interface AdminResourceSecretsState {
    list: {
        byId: {
            [id: number]: ResourceSecretInformationDisplay
        };
        allIds: number[];
    };
    newSecret?: ResourceSecretInformation;
};


export const initialState: AdminResourceSecretsState = {
    list: {
        byId: {},
        allIds: []
    },
    newSecret: undefined
};


export function adminResourceSecretsReducer(state: AdminResourceSecretsState = initialState, action: AdminResourceSecretsAction): AdminResourceSecretsState {
    switch (action.type) {
        case DISPLAY_STATE_CHANGE:
            const stateChange = action.payload as ChangeEditState;

            return {
                ...state,
                list: {
                    ...state.list,
                    byId: {
                        ...state.list.byId,
                        [stateChange.id]: {
                            ...state.list.byId[stateChange.id],
                            displayState: stateChange.displayState
                        } as ResourceSecretInformationDisplay
                    }
                }
            };

        case ENUMERATE_RESOURCE_SECRETS_SUCCESS:
            const claimsInfo = action.payload as ResourceSecretInformation[];

            var claims: ResourceSecretInformationDisplay[] = [];
            claimsInfo.forEach(claim => {
                claims.push({
                    ...claim,
                    displayState: DisplayState.ReadOnly
                } as ResourceSecretInformationDisplay);
            });

            return {
                ...state,
                list: {
                    byId: {
                        ..._.keyBy(claims, "id")
                    },
                    allIds: _.map(claims, "id")
                }
            };
        case ADD_RESOURCE_SECRET_SUCCESS:
        case UPDATE_RESOURCE_SECRET_SUCCESS:
            const newSecret = action.payload as ResourceSecretInformation;

            var newAddUpdateAllIds: number[] = state.list.allIds;
            var found: boolean = false;
            newAddUpdateAllIds.forEach((id: number) => {
                if (id === newSecret.id) {
                    found = true;
                }
            });

            if (!found) {
                newAddUpdateAllIds.push(newSecret.id)
            }

            return {
                ...state,
                list: {
                    byId: {
                        ...state.list.byId,
                        [newSecret.id]: {
                            ...newSecret,
                            displayState: DisplayState.ReadOnly
                        } as ResourceSecretInformationDisplay
                    },
                    allIds: newAddUpdateAllIds
                },
                newSecret: newSecret
            };
        case DELETE_RESOURCE_SECRET_SUCCESS:
            const newAllIds = [...state.list.allIds];
            const idIndex = newAllIds.findIndex(el => el === action.payload as number);
            newAllIds.splice(idIndex, 1);

            const newByids = { ...state.list.byId };
            delete newByids[action.payload as number];

            return {
                ...state,
                list: {
                    byId: {
                        ...newByids
                    },
                    allIds: newAllIds
                }
            };
        case ENUMERATE_RESOURCE_SECRETS_ERROR:
        case ADD_RESOURCE_SECRET_ERROR:
        case DELETE_RESOURCE_SECRET_ERROR:
        default:
            return state;
    }
}