import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { TextField, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { updateResource } from '../redux/actions';
import { UpdateResourceRequest, ResourceInformation } from '../../../../../../shared/utils/DataAccess';


type Props = {
    resourceInformation: ResourceInformation
}


const ResourceEditComponent: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();


    const [updateResourceRequest, setupdateResourceRequest] = useState<ResourceInformation>(ResourceInformation.fromJS({
        id: 0,
        enabled: false,
        name: "",
        displayName: "",
        description: "",
        created: new Date(),
        updated: new Date(),
        lastAccessed: new Date(),
        nonEditable: false
    }));


    if (updateResourceRequest.id !== props.resourceInformation.id) {
        setupdateResourceRequest(ResourceInformation.fromJS({
            id: props.resourceInformation.id,
            enabled: props.resourceInformation.enabled,
            name: props.resourceInformation.name,
            displayName: props.resourceInformation.displayName,
            description: props.resourceInformation.description,
            created: props.resourceInformation.created,
            updated: props.resourceInformation.updated,
            lastAccessed: props.resourceInformation.lastAccessed,
            nonEditable: props.resourceInformation.nonEditable
        }));
    }


    const hasChanges = (): boolean => {
        return updateResourceRequest.enabled !== props.resourceInformation.enabled ||
            updateResourceRequest.name !== props.resourceInformation.name ||
            updateResourceRequest.displayName !== props.resourceInformation.displayName ||
            updateResourceRequest.description !== props.resourceInformation.description ||
            updateResourceRequest.nonEditable !== props.resourceInformation.nonEditable;
    }


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAddResourceRequest: ResourceInformation = ResourceInformation.fromJS({
            ...updateResourceRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setupdateResourceRequest(newAddResourceRequest);
    }


    const onCheckFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAddResourceRequest: ResourceInformation = ResourceInformation.fromJS({
            ...updateResourceRequest,
            [e.currentTarget.id]: e.currentTarget.checked
        });

        setupdateResourceRequest(newAddResourceRequest);
    }


    const onSubmit = () => {
        const request: UpdateResourceRequest = UpdateResourceRequest.fromJS({
            enabled: updateResourceRequest.enabled,
            name: updateResourceRequest.name,
            displayName: updateResourceRequest.displayName,
            description: updateResourceRequest.description,
            nonEditable: updateResourceRequest.nonEditable,
        });

        dispatch(updateResource(updateResourceRequest.id, request));
    }


    const classes = makeStyles({
        row: {
            maxWidth: "600px"
        }
    })();


    return (
        <>
            <div className={classes.row}>
                <TextField
                    id="name"
                    label="Name"
                    fullWidth={true}
                    value={updateResourceRequest.name || ""}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    id="displayName"
                    label="Display Name"
                    fullWidth={true}
                    value={updateResourceRequest.displayName || ""}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    id="description"
                    label="Description"
                    fullWidth={true}
                    value={updateResourceRequest.description || ""}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="nonEditable"
                            checked={updateResourceRequest.nonEditable || false}
                            onChange={onCheckFieldChange}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                            style={{ float: 'right' }}
                        />
                    }
                    label="Non-Editable"
                />
            </div>
            <div className={classes.row} style={{ textAlign: 'right' }}>
                <Button variant="outlined" color="primary" onClick={onSubmit} disabled={!hasChanges()}>Save Resource</Button>
            </div>
        </>
    );


}


export default ResourceEditComponent;