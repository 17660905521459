export const ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES = "ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES";
export type ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES = typeof ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES;
export const ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_SUCCESS = "ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_SUCCESS";
export type ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_SUCCESS = typeof ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_SUCCESS;
export const ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_ERROR = "ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_ERROR";
export type ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_ERROR = typeof ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_ERROR;

export const ADD_CLIENT_ALLOWED_GRANT_TYPE = "ADD_CLIENT_ALLOWED_GRANT_TYPE";
export type ADD_CLIENT_ALLOWED_GRANT_TYPE = typeof ADD_CLIENT_ALLOWED_GRANT_TYPE;
export const ADD_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS = "ADD_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS";
export type ADD_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS = typeof ADD_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS;
export const ADD_CLIENT_ALLOWED_GRANT_TYPE_ERROR = "ADD_CLIENT_ALLOWED_GRANT_TYPE_ERROR";
export type ADD_CLIENT_ALLOWED_GRANT_TYPE_ERROR = typeof ADD_CLIENT_ALLOWED_GRANT_TYPE_ERROR;

export const DELETE_CLIENT_ALLOWED_GRANT_TYPE = "DELETE_CLIENT_ALLOWED_GRANT_TYPE";
export type DELETE_CLIENT_ALLOWED_GRANT_TYPE = typeof DELETE_CLIENT_ALLOWED_GRANT_TYPE;
export const DELETE_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS = "DELETE_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS";
export type DELETE_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS = typeof DELETE_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS;
export const DELETE_CLIENT_ALLOWED_GRANT_TYPE_ERROR = "DELETE_CLIENT_ALLOWED_GRANT_TYPE_ERROR";
export type DELETE_CLIENT_ALLOWED_GRANT_TYPE_ERROR = typeof DELETE_CLIENT_ALLOWED_GRANT_TYPE_ERROR;
