import { Middleware } from "redux";
import {
    LOAD_PROFILE, LOAD_PROFILE_SUCCESS, LOAD_PROFILE_ERROR,
    UPDATE_PROFILE, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_ERROR
} from "./actionDef";
import { ProfileInformation, UpdateProfileRequest } from "../../../shared/utils/DataAccess";
import APIClientFactory from "../../../shared/utils/APIClientFactory";
import { action } from "typesafe-actions";
import { SERVER_ERROR } from "../../../shared/error/redux/actionDefs";
import { ErrorState } from "../../../shared/error/redux/reducer";
import { API_SPINNER_CHANGE } from "../../../shared/spinner/redux/actionDefs";
import { SpinnerDisplayState, SpinnerState } from "../../../shared/spinner/redux/reducer";


const profileDataService: Middleware = store => next => requestedAction => {
    next(requestedAction);

    const client = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case LOAD_PROFILE:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.loadProfile()
                .then((profile: ProfileInformation) => {
                    store.dispatch(action(LOAD_PROFILE_SUCCESS, profile));
                })
                .catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(LOAD_PROFILE_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case UPDATE_PROFILE:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.updateProfile(requestedAction.payload as UpdateProfileRequest)
                .then(() => {
                    store.dispatch(action(UPDATE_PROFILE_SUCCESS, requestedAction.payload));
                })
                .catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(UPDATE_PROFILE_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default profileDataService;