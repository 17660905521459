import React, { FunctionComponent } from 'react';
import { TableCell } from '@material-ui/core';
import FullTableRow from '../../../../../../shared/FullTable/components/FullTableRow';
import { ResourceScopeInformationDisplay } from '../redux/reducer';


type Props = {
    scope: ResourceScopeInformationDisplay,
    onSelect(id: number): void,
    onDelete(id: number): void
}


const ResourceScopeComponent: FunctionComponent<Props> = (props: Props) => {


    const onSelect = () => {
        props.onSelect(props.scope.id);
    }


    const onDelete = () => {
        props.onDelete(props.scope.id);
    }


    const { scope } = props;


    return (
        <FullTableRow
            displayState={scope.displayState}
            onSelect={onSelect}
            onDelete={onDelete}
            canEdit={true}
            canSelect={true}
            canDelete={true}
        >
            <TableCell scope="row">{scope.name}</TableCell>
            <TableCell scope="row">{scope.displayName}</TableCell>
            <TableCell scope="row">{scope.description}</TableCell>
            <TableCell scope="row" style={{ textAlign: "center" }}>{scope.required ? "X" : ""}</TableCell>
            <TableCell scope="row" style={{ textAlign: "center" }}>{scope.emphasize ? "X" : ""}</TableCell>
            <TableCell scope="row" style={{ textAlign: "center" }}>{scope.showInDiscoveryDocument ? "X" : ""}</TableCell>
        </FullTableRow>
    );


}


export default ResourceScopeComponent;