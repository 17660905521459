import { AdminClientScopesAction } from "./actions";
import { ClientsAllowedScope } from "../../../../../../shared/utils/DataAccess";
import {
    ENUMERATE_CLIENT_SCOPES_SUCCESS, ENUMERATE_CLIENT_SCOPES_ERROR,
    ADD_CLIENT_SCOPE_SUCCESS, ADD_CLIENT_SCOPE_ERROR,
    DELETE_CLIENT_SCOPE_SUCCESS, DELETE_CLIENT_SCOPE_ERROR
} from "./actionDef";
import _ from "lodash";


export interface AdminClientAllowedScopesState {
    list: {
        byId: {
            [id: number]: ClientsAllowedScope
        };
        allIds: number[];
    };
    newScope?: ClientsAllowedScope;
};


export const initialState: AdminClientAllowedScopesState = {
    list: {
        byId: {},
        allIds: []
    },
    newScope: undefined
};


export function adminClientAllowedScopesReducer(state: AdminClientAllowedScopesState = initialState, action: AdminClientScopesAction): AdminClientAllowedScopesState {
    switch (action.type) {
        case ENUMERATE_CLIENT_SCOPES_SUCCESS:
            const scopes = action.payload as ClientsAllowedScope[];

            return {
                ...state,
                list: {
                    byId: {
                        ..._.keyBy(scopes, "id")
                    },
                    allIds: _.map(scopes, "id")
                }
            };
        case ADD_CLIENT_SCOPE_SUCCESS:
            const newScope = action.payload as ClientsAllowedScope;

            var newAddUpdateAllIds: number[] = state.list.allIds;
            var found: boolean = false;
            newAddUpdateAllIds.forEach((id: number) => {
                if (id === newScope.id) {
                    found = true;
                }
            });

            if (!found) {
                newAddUpdateAllIds.push(newScope.id)
            }

            return {
                ...state,
                list: {
                    byId: {
                        ...state.list.byId,
                        [newScope.id]: {
                            ...newScope
                        } as ClientsAllowedScope
                    },
                    allIds: newAddUpdateAllIds
                },
                newScope: newScope
            };
        case DELETE_CLIENT_SCOPE_SUCCESS:
            const newAllIds = [...state.list.allIds];
            const idIndex = newAllIds.findIndex(el => el === action.payload as number);
            newAllIds.splice(idIndex, 1);

            const newByids = { ...state.list.byId };
            delete newByids[action.payload as number];

            return {
                ...state,
                list: {
                    byId: {
                        ...newByids
                    },
                    allIds: newAllIds
                }
            };
        case ENUMERATE_CLIENT_SCOPES_ERROR:
        case ADD_CLIENT_SCOPE_ERROR:
        case DELETE_CLIENT_SCOPE_ERROR:
        default:
            return state;
    }
}