import { ProfileAction } from "./actions";
import { ProfileInformation, UpdateProfileRequest } from "../../../shared/utils/DataAccess";
import { LOAD_PROFILE_SUCCESS, LOAD_PROFILE_ERROR, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_ERROR } from "./actionDef";


export interface ProfileState {
  profile: ProfileInformation
};


export const initialState: ProfileState = {
  profile: ProfileInformation.fromJS({})
};


export function profileReducer(state: ProfileState = initialState, action: ProfileAction): ProfileState {
  switch (action.type) {
    case LOAD_PROFILE_SUCCESS:
      const result = action.payload as ProfileInformation;
      return {
        ...state,
        profile: result
      };
    case UPDATE_PROFILE_SUCCESS:
      const request = action.payload as UpdateProfileRequest;
      return {
        ...state,
        profile: ProfileInformation.fromJS({
          ...state.profile,
          loginEmail: request.loginEmail,
          loginPhone: request.loginPhone,
          givenName: request.givenName,
          surname: request.surname,
          requiresTwoFactorAuthentication: request.requiresTwoFactorAuthentication
        })
      };
    case LOAD_PROFILE_ERROR:    //handled in generic error reducer
    case UPDATE_PROFILE_ERROR:
    default:
      return state;
  }
}