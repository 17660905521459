import React, { FunctionComponent, useState } from 'react';
import { UpdateResourceSecretRequest } from '../../../../../../shared/utils/DataAccess';
import { TableCell, TextField } from '@material-ui/core';
import FullTableRow, { DisplayState } from '../../../../../../shared/FullTable/components/FullTableRow';
import { ResourceSecretInformationDisplay } from '../redux/reducer';


type Props = {
    property: ResourceSecretInformationDisplay,
    onUpdateSecret(id: number, request: UpdateResourceSecretRequest): void,
    onEditStart(id: number): void,
    onEditChanges(id: number, hasChanges: boolean): void,
    onEditCancel(id: number): void,
    onDelete(id: number): void
}


const ResourceSecretComponent: FunctionComponent<Props> = (props: Props) => {


    const InitialUpdateSecretRequest = () => {
        return UpdateResourceSecretRequest.fromJS({
            description: props.property.description,
            expiration: props.property.expiration,
            type: props.property.type
        });
    }


    const [updateSecretRequest, setupdateSecretRequest] = useState<UpdateResourceSecretRequest>(InitialUpdateSecretRequest());


    const hasChanges = (newUpdateSecretRequest: UpdateResourceSecretRequest): boolean => {
        if (props.property === undefined) {
            return false;
        }

        return (
            newUpdateSecretRequest.description !== props.property.description ||
            newUpdateSecretRequest.expiration !== props.property.expiration ||
            newUpdateSecretRequest.type !== props.property.type);
    }


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUpdateSecretRequest: UpdateResourceSecretRequest = UpdateResourceSecretRequest.fromJS({
            ...updateSecretRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setupdateSecretRequest(newUpdateSecretRequest);
        props.onEditChanges(props.property.id, hasChanges(newUpdateSecretRequest));
    }


    const onEditStart = () => {
        props.onEditStart(props.property.id);
    }


    const onEditCancel = () => {
        setupdateSecretRequest(InitialUpdateSecretRequest);
        props.onEditCancel(props.property.id);
    }


    const onDelete = () => {
        props.onDelete(props.property.id);
    }


    const onSave = () => {
        props.onUpdateSecret(props.property.id, updateSecretRequest);
    }


    const { property: secret } = props;
    const isEditing: boolean =
        secret.displayState === DisplayState.Edit ||
        secret.displayState === DisplayState.NotSaved ||
        secret.displayState === DisplayState.Error;


    return (
        <FullTableRow
            displayState={secret.displayState}
            onEditStart={onEditStart}
            onEditCancel={onEditCancel}
            onSave={onSave}
            canEdit={true}
            canDelete={true}
            onDelete={onDelete} >
            {isEditing ?
                <>
                    <TableCell component="th" scope="row">
                        <TextField
                            id="type"
                            label="Type"
                            onChange={onTextFieldChange}
                            value={updateSecretRequest.type}
                            style={{ width: '90px' }}
                        >
                        </TextField>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField
                            id="description"
                            label="Description"
                            onChange={onTextFieldChange}
                            value={updateSecretRequest.description}
                            style={{ width: '90px' }}
                        >
                        </TextField>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField
                            id="expirtion"
                            label="Expiration"
                            onChange={onTextFieldChange}
                            disabled={true}
                            value={updateSecretRequest.expiration.toLocaleDateString()}
                            style={{ width: '90px' }}
                        >
                        </TextField>
                    </TableCell>
                </>
                :
                <>
                    <TableCell>{secret.type}</TableCell>
                    <TableCell component="th" scope="row">{secret.description}</TableCell>
                    <TableCell component="th" scope="row">{secret.expiration.toLocaleDateString()}</TableCell>
                </>
            }
        </FullTableRow>
    );


}


export default ResourceSecretComponent;