import React, { FunctionComponent } from 'react';

import { AppPasswordInformation } from '../../../shared/utils/DataAccess';

import { Grid, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';


type Props = {
    appPassword: AppPasswordInformation
    onRevoke(appPassword: AppPasswordInformation): void
}


const AppPasswordComponent: FunctionComponent<Props> = (props: Props) => {

    const classes = makeStyles({
        name: {
            fontWeight: "bold",
            textAlign: "right",
            ['@media (max-width:600px)']: {
                textAlign: "left"
            }
        },
        button: {
            display: 'block',
            clear: 'both',
            textAlign: 'right'
        }
    })();


    const onRevoke = () => {
        props.onRevoke(props.appPassword);
    }


    const { appPassword } = props;


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={2} className={classes.name}>Application:</Grid>
            <Grid item xs={12} sm={10}>{appPassword.appName}</Grid>

            <Grid item xs={12} sm={2} className={classes.name}>Created:</Grid>
            <Grid item xs={12} sm={10}>{appPassword.dateCreated.toString()}</Grid>

            <Grid item xs={12} sm={2} className={classes.name}>Expires:</Grid>
            <Grid item xs={12} sm={10}>{appPassword.dateExpires.toString()}</Grid>

            <Grid item xs={12} className={classes.button}><Button variant="outlined" onClick={onRevoke}>Revoke Password</Button></Grid>
        </Grid>
    );


}


export default AppPasswordComponent;