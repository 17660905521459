import { action } from "typesafe-actions";
import { ENUMERATE_CLIENTS, ADD_CLIENT, REMOVE_CLIENT } from "./actionDef";
import { ConfigCreateDto, ConfigUpdateDto, ClientConfigInformation } from "../../../../shared/utils/DataAccess";


export interface AdminClientsAction {
    type: string;
    payload?: number | ConfigCreateDto | ConfigUpdateDto | ClientConfigInformation[] | null;
}


export const enumerateClients = (): AdminClientsAction => action(ENUMERATE_CLIENTS, null);
export const addClient = (request: ConfigCreateDto): AdminClientsAction => action(ADD_CLIENT, request);
export const removeClient = (id: number): AdminClientsAction => action(REMOVE_CLIENT, id);
