import { AdminResourcesAction } from "./actions";
import { UserInformation, UpdateUserRequest, ResourceInformation } from "../../../../shared/utils/DataAccess";
import {
    ENUMERATE_RESOURCES_SUCCESS, REMOVE_RESOURCE_SUCCESS
} from "./actionDef";
import _ from "lodash";
import { DisplayState } from "../../../../shared/FullTable/components/FullTableRow";


export interface ResourceInformationDisplay extends UserInformation {
    displayState: DisplayState
}


export interface UpdateResourceRequestDisplay extends UpdateUserRequest {
    displayState: DisplayState
}


export interface AdminResourceListState {
    list: {
        byId: {
            [id: number]: ResourceInformation
        };
        allIds: number[];
    }
};


export const initialState: AdminResourceListState = {
    list: {
        byId: {},
        allIds: []
    }
};


export function adminResourcesReducer(state: AdminResourceListState = initialState, action: AdminResourcesAction): AdminResourceListState {
    switch (action.type) {
        /*
                these are fired when an API return success
        */
        case ENUMERATE_RESOURCES_SUCCESS:
            //convert to display object
            const users = action.payload as ResourceInformation[];

            return {
                ...state,
                list: {
                    byId: {
                        ..._.keyBy(users, "id")
                    },
                    allIds: _.map(users, "id")
                }
            };

        case REMOVE_RESOURCE_SUCCESS:
            const newAllIds = [...state.list.allIds];
            const idIndex = newAllIds.findIndex(el => el === action.payload as number);
            newAllIds.splice(idIndex, 1);

            const newByids = { ...state.list.byId };
            delete newByids[action.payload as number];

            return {
                ...state,
                list: {
                    byId: {
                        ...newByids
                    },
                    allIds: newAllIds
                }
            };
        default:
            return state;
    }
}