import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Toolbar, Button, IconButton, Menu, MenuItem, makeStyles } from '@material-ui/core';
import userManager from '../../shared/utils/UserManager';

import peakey from '../../shared/assets/peakey-icon.png';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';

import { ProfileInformation } from '../../shared/utils/DataAccess';

import { loadProfile } from '../redux/actions';
import { push } from 'connected-react-router';
import { StoreState } from '../../shared/redux/store';


interface DisplayProps {
    isSmallDisplay: boolean;
}


export type Props = DisplayProps;


const MenuComponent: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();


    const [adminMenuVisible, setadminMenuVisible] = useState<boolean>(false);
    const [profileMenuVisible, setprofileMenuVisible] = useState<boolean>(false);
    const [profileAnchor, setprofileAnchor] = useState<Element | null>(null);
    const [menuAnchor, setmenuAnchor] = useState<Element | null>(null);


    const [isProfileLoaded, setisProfileLoaded] = useState<boolean>(false);


    const onLogoutButtonClicked = () => {
        userManager.signoutRedirect();
        userManager.removeUser();
    }


    const profile: ProfileInformation = useSelector((state: StoreState) => {
        if (!isProfileLoaded && state.profileReducer.profile.loginEmail !== undefined) {
            setisProfileLoaded(true);
        }
        return state.profileReducer.profile
    });


    useEffect(() => {
        const isOnRedirect = window.location.pathname.match(/redirect/);
        if (!isOnRedirect && !isProfileLoaded) {
            dispatch(loadProfile());
        }
    }, []);


    const handleProfileMenu = (event: React.MouseEvent) => {
        setprofileMenuVisible(!profileMenuVisible);
        setprofileAnchor(event.currentTarget);
    }


    const handleAdminMenu = (event: React.MouseEvent) => {
        setadminMenuVisible(!adminMenuVisible);
        setmenuAnchor(event.currentTarget);
    }


    const handleMenuItemClick = (id: string) => {
        dispatch(push(id));
        setadminMenuVisible(false);
        setprofileMenuVisible(false);
    }


    const hamburgerItems = [
        { display: "Administrators", path: "administration/administrators" },
        { display: "Clients", path: "administration/clients" },
        { display: "Resources", path: "administration/resources" },
        { display: "Users", path: "administration/users" }
    ];


    const classes = makeStyles({
        appBar: { height: "4rem", width: "100%" },
        toolBar: { justifyContent: "space-between" },
        image: { paddingRight: "1rem" },
        menu: { MuiListPadding: { paddingTop: 0, paddingBottom: 0 } }
    })();


    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.toolBar}>
                    <div style={{ alignItems: "center", display: profile && profile.isOAuthAdministrator ? 'flex' : 'none' }}>
                        {!props.isSmallDisplay
                            ?
                            <>
                                <img className={classes.image} src={peakey} alt="peakey-icon" />
                                {hamburgerItems.map((el: { display: string, path: string }) => (
                                    <Button
                                        key={el.display}
                                        color="inherit"
                                        onClick={() => handleMenuItemClick(`/${el.path}`)}
                                    >
                                        {el.display}
                                    </Button>
                                ))}
                            </>
                            :
                            <>
                                <IconButton
                                    id="icon-button"
                                    edge="start"
                                    color="inherit"
                                    onClick={handleAdminMenu}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    className={classes.menu}
                                    anchorEl={menuAnchor}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={adminMenuVisible}
                                    onClose={handleAdminMenu}
                                >
                                    {hamburgerItems.map((el: { display: string, path: string }) => (
                                        <MenuItem
                                            key={el.display}
                                            color="inherit"
                                            onClick={() => handleMenuItemClick(`/${el.path}`)}
                                        >
                                            {el.display}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        }
                    </div>
                    <IconButton
                        id="test"
                        edge="start"
                        color="inherit"
                        onClick={handleProfileMenu}
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        className={classes.menu}
                        getContentAnchorEl={null}
                        anchorEl={profileAnchor}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={profileMenuVisible}
                        onClose={handleProfileMenu}
                    >
                        <MenuItem onClick={() => handleMenuItemClick("/")}>Welcome, {profile ? profile.givenName + " " + profile.surname : ""}</MenuItem>
                        <MenuItem onClick={() => handleMenuItemClick("/")}>Profile</MenuItem>
                        <MenuItem onClick={() => handleMenuItemClick("/grants")}>Grants</MenuItem>
                        <MenuItem onClick={() => handleMenuItemClick("/app-passwords")}>App Passwords</MenuItem>
                        <MenuItem onClick={() => onLogoutButtonClicked()}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </>);


}


export default MenuComponent;