import { AddClientAllowedGrantTypeRequest, ClientAllowedGrantTypeInformation } from "../../../../../../shared/utils/DataAccess";
import { ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES, ADD_CLIENT_ALLOWED_GRANT_TYPE, DELETE_CLIENT_ALLOWED_GRANT_TYPE } from "./actionDef";
import { action } from "typesafe-actions";


export interface AdminClientAllowedGrantTypesAction {
    type: string;
    payload?:
    number |
    AddAllowedGrantTypeRequestWithClientId | DeleteAllowedGrantTypeRequest | AddClientAllowedGrantTypeRequest |
    ClientAllowedGrantTypeInformation | ClientAllowedGrantTypeInformation[];
}


export interface AddAllowedGrantTypeRequestWithClientId {
    clientId: number,
    request: AddClientAllowedGrantTypeRequest
}


export interface DeleteAllowedGrantTypeRequest {
    clientId: number,
    AllowedGrantTypeId: number
}


export const loadClientAllowedGrantTypes = (clientId: number): AdminClientAllowedGrantTypesAction => action(ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES, clientId);
export const addClientAllowedGrantTypes = (clientId: number, AllowedGrantType: string): AdminClientAllowedGrantTypesAction =>
    action(
        ADD_CLIENT_ALLOWED_GRANT_TYPE,
        { clientId: clientId, request: { grantType: AllowedGrantType } as AddClientAllowedGrantTypeRequest } as AddAllowedGrantTypeRequestWithClientId);
export const deleteClientAllowedGrantTypes = (clientId: number, AllowedGrantTypeId: number): AdminClientAllowedGrantTypesAction =>
    action(DELETE_CLIENT_ALLOWED_GRANT_TYPE,
        { clientId: clientId, AllowedGrantTypeId: AllowedGrantTypeId });

