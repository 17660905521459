import { Middleware } from 'redux';

import {
    ResourcesScopesClaimInformation
} from '../../../../../../../shared/utils/DataAccess';
import {
    ENUMERATE_RESOURCE_SCOPE_CLAIMS, ENUMERATE_RESOURCE_SCOPE_CLAIMS_SUCCESS, ENUMERATE_RESOURCE_SCOPE_CLAIMS_ERROR,
    ADD_RESOURCE_SCOPE_CLAIM, ADD_RESOURCE_SCOPE_CLAIM_SUCCESS, ADD_RESOURCE_SCOPE_CLAIM_ERROR,
    DELETE_RESOURCE_SCOPE_CLAIM, DELETE_RESOURCE_SCOPE_CLAIM_SUCCESS, DELETE_RESOURCE_SCOPE_CLAIM_ERROR
} from './actionDef';
import { AdminResourcesScopesClaimsAction, AddResourcesScopesClaimRequestWithResourceId, DeleteResourcesScopesClaimRequest, EnumerateResourcesScopesClaimsRequest } from './actions';
import APIClientFactory from '../../../../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../../../../shared/spinner/redux/reducer';



const adminResourceScopesClaimsDataService: Middleware = store => next => (requestedAction: AdminResourcesScopesClaimsAction) => {
    next(requestedAction);

    const resource = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ENUMERATE_RESOURCE_SCOPE_CLAIMS:
            const enumerateWithID: EnumerateResourcesScopesClaimsRequest = (requestedAction.payload as EnumerateResourcesScopesClaimsRequest);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            resource.enumerateResourcesScopesClaims(enumerateWithID.resourceId, enumerateWithID.scopeId)
                .then((Claims: ResourcesScopesClaimInformation[]) => {
                    store.dispatch(action(ENUMERATE_RESOURCE_SCOPE_CLAIMS_SUCCESS, Claims));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_RESOURCE_SCOPE_CLAIMS_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ADD_RESOURCE_SCOPE_CLAIM:
            const addWithID: AddResourcesScopesClaimRequestWithResourceId = (requestedAction.payload as AddResourcesScopesClaimRequestWithResourceId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            resource.addResourcesScopesClaim(addWithID.resourceId, addWithID.scopeId, addWithID.request)
                .then((newGrantTypeInformation: ResourcesScopesClaimInformation) => {
                    store.dispatch(action(ADD_RESOURCE_SCOPE_CLAIM_SUCCESS, newGrantTypeInformation));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_RESOURCE_SCOPE_CLAIM_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;

        case DELETE_RESOURCE_SCOPE_CLAIM:
            const deleteWithID: DeleteResourcesScopesClaimRequest = (requestedAction.payload as DeleteResourcesScopesClaimRequest);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            resource.removeResourcesScopesClaim(deleteWithID.resourceId, deleteWithID.scopeId, deleteWithID.claimId)
                .then(() => {
                    store.dispatch(action(DELETE_RESOURCE_SCOPE_CLAIM_SUCCESS, deleteWithID.scopeId));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(DELETE_RESOURCE_SCOPE_CLAIM_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminResourceScopesClaimsDataService;