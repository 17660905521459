export const DISPLAY_STATE_CHANGE = "DISPLAY_STATE_CHANGE";
export type DISPLAY_STATE_CHANGE = typeof DISPLAY_STATE_CHANGE;

export const ENUMERATE_RESOURCE_SCOPES = "ENUMERATE_RESOURCE_SCOPES";
export type ENUMERATE_RESOURCE_SCOPES = typeof ENUMERATE_RESOURCE_SCOPES;
export const ENUMERATE_RESOURCE_SCOPES_SUCCESS = "ENUMERATE_RESOURCE_SCOPES_SUCCESS";
export type ENUMERATE_RESOURCE_SCOPES_SUCCESS = typeof ENUMERATE_RESOURCE_SCOPES_SUCCESS;
export const ENUMERATE_RESOURCE_SCOPES_ERROR = "ENUMERATE_RESOURCE_SCOPES_ERROR";
export type ENUMERATE_RESOURCE_SCOPES_ERROR = typeof ENUMERATE_RESOURCE_SCOPES_ERROR;

export const ADD_RESOURCE_SCOPE = "ADD_RESOURCE_SCOPE";
export type ADD_RESOURCE_SCOPE = typeof ADD_RESOURCE_SCOPE;
export const ADD_RESOURCE_SCOPE_SUCCESS = "ADD_RESOURCE_SCOPE_SUCCESS";
export type ADD_RESOURCE_SCOPE_SUCCESS = typeof ADD_RESOURCE_SCOPE_SUCCESS;
export const ADD_RESOURCE_SCOPE_ERROR = "ADD_RESOURCE_SCOPE_ERROR";
export type ADD_RESOURCE_SCOPE_ERROR = typeof ADD_RESOURCE_SCOPE_ERROR;

export const DELETE_RESOURCE_SCOPE = "DELETE_RESOURCE_SCOPE";
export type DELETE_RESOURCE_SCOPE = typeof DELETE_RESOURCE_SCOPE;
export const DELETE_RESOURCE_SCOPE_SUCCESS = "DELETE_RESOURCE_SCOPE_SUCCESS";
export type DELETE_RESOURCE_SCOPE_SUCCESS = typeof DELETE_RESOURCE_SCOPE_SUCCESS;
export const DELETE_RESOURCE_SCOPE_ERROR = "DELETE_RESOURCE_SCOPE_ERROR";
export type DELETE_RESOURCE_SCOPE_ERROR = typeof DELETE_RESOURCE_SCOPE_ERROR;
