import { Middleware } from 'redux';

import {
    ClientConfigInformation
} from '../../../../../../shared/utils/DataAccess';
import {
    LOAD_CLIENT, LOAD_CLIENT_SUCCESS, LOAD_CLIENT_ERROR,
    UPDATE_CLIENT, UPDATE_CLIENT_SUCCESS, UPDATE_CLIENT_ERROR,
} from './actionDef';
import { AdminClientAction, ConfigUpdateDtoWithId } from './actions';
import APIClientFactory from '../../../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../../../shared/spinner/redux/reducer';



const adminClientDataService: Middleware = store => next => (requestedAction: AdminClientAction) => {
    next(requestedAction);

    const client = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case LOAD_CLIENT:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.getClientConfig(requestedAction.payload as number)
                .then((newClient: ClientConfigInformation) => {
                    store.dispatch(action(LOAD_CLIENT_SUCCESS, newClient));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(LOAD_CLIENT_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case UPDATE_CLIENT:
            const updateWithID: ConfigUpdateDtoWithId = (requestedAction.payload as ConfigUpdateDtoWithId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.updateClientConfig(updateWithID.id, updateWithID.request)
                .then(() => {
                    store.dispatch(action(UPDATE_CLIENT_SUCCESS, updateWithID));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(UPDATE_CLIENT_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminClientDataService;