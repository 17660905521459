import { ChangeEditState, AdminResourceScopesAction } from "./actions";
import { ResourceScopeInformation } from "../../../../../../shared/utils/DataAccess";
import {
    ENUMERATE_RESOURCE_SCOPES_SUCCESS, ENUMERATE_RESOURCE_SCOPES_ERROR,
    ADD_RESOURCE_SCOPE_SUCCESS, ADD_RESOURCE_SCOPE_ERROR,
    DELETE_RESOURCE_SCOPE_SUCCESS, DELETE_RESOURCE_SCOPE_ERROR, DISPLAY_STATE_CHANGE,
} from "./actionDef";
import _ from "lodash";
import { DisplayState } from "../../../../../../shared/FullTable/components/FullTableRow";


export interface ResourceScopeInformationDisplay extends ResourceScopeInformation {
    displayState: DisplayState
}


export interface AdminResourceScopesState {
    list: {
        byId: {
            [id: number]: ResourceScopeInformationDisplay
        };
        allIds: number[];
    };
    scope: ResourceScopeInformation,
    newScope?: ResourceScopeInformation;
};


export const initialState: AdminResourceScopesState = {
    list: {
        byId: {},
        allIds: []
    },
    scope: ResourceScopeInformation.fromJS({}),
    newScope: undefined
};


export function adminResourceScopesReducer(state: AdminResourceScopesState = initialState, action: AdminResourceScopesAction): AdminResourceScopesState {
    switch (action.type) {
        case DISPLAY_STATE_CHANGE:
            const stateChange = action.payload as ChangeEditState;

            return {
                ...state,
                list: {
                    ...state.list,
                    byId: {
                        ...state.list.byId,
                        [stateChange.id]: {
                            ...state.list.byId[stateChange.id],
                            displayState: stateChange.displayState
                        } as ResourceScopeInformationDisplay
                    }
                }
            };

        case ENUMERATE_RESOURCE_SCOPES_SUCCESS:
            const claimsInfo = action.payload as ResourceScopeInformation[];

            var claims: ResourceScopeInformationDisplay[] = [];
            claimsInfo.forEach(claim => {
                claims.push({
                    ...claim,
                    displayState: DisplayState.ReadOnly
                } as ResourceScopeInformationDisplay);
            });

            return {
                ...state,
                list: {
                    byId: {
                        ..._.keyBy(claims, "id")
                    },
                    allIds: _.map(claims, "id")
                }
            };
        case ADD_RESOURCE_SCOPE_SUCCESS:
            const newScope = action.payload as ResourceScopeInformation;

            var newAddUpdateAllIds: number[] = state.list.allIds;
            var found: boolean = false;
            newAddUpdateAllIds.forEach((id: number) => {
                if (id === newScope.id) {
                    found = true;
                }
            });

            if (!found) {
                newAddUpdateAllIds.push(newScope.id)
            }

            return {
                ...state,
                list: {
                    byId: {
                        ...state.list.byId,
                        [newScope.id]: {
                            ...newScope,
                            displayState: DisplayState.ReadOnly
                        } as ResourceScopeInformationDisplay
                    },
                    allIds: newAddUpdateAllIds
                },
                newScope: newScope
            };
        case DELETE_RESOURCE_SCOPE_SUCCESS:
            const newAllIds = [...state.list.allIds];
            const idIndex = newAllIds.findIndex(el => el === action.payload as number);
            newAllIds.splice(idIndex, 1);

            const newByids = { ...state.list.byId };
            delete newByids[action.payload as number];

            return {
                ...state,
                list: {
                    byId: {
                        ...newByids
                    },
                    allIds: newAllIds
                }
            };
        case ENUMERATE_RESOURCE_SCOPES_ERROR:
        case ADD_RESOURCE_SCOPE_ERROR:
        case DELETE_RESOURCE_SCOPE_ERROR:
        default:
            return state;
    }
}