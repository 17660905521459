import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { TextField, Button } from '@material-ui/core';
import { ClientConfigInformation, ClientPropertyInformation, AddClientPropertyRequest, UpdateClientPropertyRequest } from '../../../../../../shared/utils/DataAccess';
import { StoreState } from '../../../../../../shared/redux/store';
import FullTableComponent, { DisplayType, Column } from '../../../../../../shared/FullTable/components/FullTableComponent';
import ClientPropertyComponent from './ClientPropertyComponent';
import { AdminClientPropertiesState } from '../redux/reducer';
import { loadClientProperties, deleteClientProperty, addClientProperty, editStart, editChanges, editCancel, updateClientProperty } from '../redux/actions';


type Props = {
    clientInformation: ClientConfigInformation
}


const ClientPropertiesComponent: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();


    const [addClientPropertyRequest, setClientPropertyRequest] = useState<AddClientPropertyRequest>(AddClientPropertyRequest.fromJS({
        key: "",
        value: ""
    }));


    const clientPropertiesState: AdminClientPropertiesState = useSelector((state: StoreState) => {
        return state.adminClientPropertiesReducer;
    });


    const apiEnumerate = () => {
        if (props.clientInformation.id) {
            dispatch(loadClientProperties(props.clientInformation.id));
        }
    };


    const onEditStart = (id: number) => {
        dispatch(editStart(id));
    }


    const onEditChanges = (id: number, hasChanges: boolean) => {
        dispatch(editChanges(id, hasChanges));
    }


    const onEditCancel = (id: number) => {
        dispatch(editCancel(id));
    }


    const onUpdateProperty = (id: number, request: UpdateClientPropertyRequest) => {
        dispatch(updateClientProperty(props.clientInformation.id, id, request));
    }


    const onDelete = (request: number) => {
        dispatch(deleteClientProperty(props.clientInformation.id, request));
    };


    useEffect(() => {
        apiEnumerate();
    }, [props.clientInformation]);


    const hasChanges = (): boolean => {
        return (addClientPropertyRequest.key.length > 0 && addClientPropertyRequest.value.length > 0);
    }


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newRequest: ClientPropertyInformation = ClientPropertyInformation.fromJS({
            ...addClientPropertyRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setClientPropertyRequest(newRequest);
    }


    const onSubmit = () => {
        dispatch(addClientProperty(props.clientInformation.id, addClientPropertyRequest));

        setClientPropertyRequest(AddClientPropertyRequest.fromJS({
            key: "",
            value: ""
        }));
    }


    const Columns: Column[] = [
        { name: "Edit", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Key", displayName: "Key", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Value", displayName: "Value", sortable: false, displayType: DisplayType.Always, textAlign: "center" },
        { name: "Delete", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
    ];


    const classes = makeStyles({
        row: {
            maxWidth: "500px"
        }
    })();


    return (
        <>
            <div className={classes.row}>
                <FullTableComponent Columns={Columns} apiEnumerate={apiEnumerate}>
                    {clientPropertiesState ? clientPropertiesState.list.allIds.map(id => (
                        <ClientPropertyComponent
                            key={id}
                            property={clientPropertiesState.list.byId[id]}
                            onUpdateProperty={onUpdateProperty}
                            onEditStart={onEditStart}
                            onEditChanges={onEditChanges}
                            onEditCancel={onEditCancel}
                            onDelete={onDelete}
                        ></ClientPropertyComponent>
                    )) : ""}
                </FullTableComponent>
            </div>
            <div className={classes.row} style={{ marginTop: '50px' }}>
                <TextField
                    id="key"
                    label="New Property Key"
                    fullWidth={true}
                    value={addClientPropertyRequest.key}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    id="value"
                    label="New Property Value"
                    fullWidth={true}
                    value={addClientPropertyRequest.value}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row} style={{ textAlign: 'right' }}>
                <Button variant="outlined" color="primary" onClick={onSubmit} disabled={!hasChanges()}>Add Property</Button>
            </div>
        </>
    );


}


export default ClientPropertiesComponent;