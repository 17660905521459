import { Middleware } from 'redux';

import {
    ResourceScopeInformation
} from '../../../../../../shared/utils/DataAccess';
import {
    ENUMERATE_RESOURCE_SCOPES, ENUMERATE_RESOURCE_SCOPES_SUCCESS, ENUMERATE_RESOURCE_SCOPES_ERROR,
    ADD_RESOURCE_SCOPE, ADD_RESOURCE_SCOPE_SUCCESS, ADD_RESOURCE_SCOPE_ERROR,
    DELETE_RESOURCE_SCOPE, DELETE_RESOURCE_SCOPE_SUCCESS, DELETE_RESOURCE_SCOPE_ERROR
} from './actionDef';
import { AdminResourceScopesAction, AddScopeRequestWithResourceId, DeleteScopeRequest } from './actions';
import APIClientFactory from '../../../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../../../shared/spinner/redux/reducer';



const adminResourceScopesDataService: Middleware = store => next => (requestedAction: AdminResourceScopesAction) => {
    next(requestedAction);

    const resource = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ENUMERATE_RESOURCE_SCOPES:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));
            resource.enumerateResourceScopes(requestedAction.payload as number)
                .then((Claims: ResourceScopeInformation[]) => {
                    store.dispatch(action(ENUMERATE_RESOURCE_SCOPES_SUCCESS, Claims));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_RESOURCE_SCOPES_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ADD_RESOURCE_SCOPE:
            const addWithID: AddScopeRequestWithResourceId = (requestedAction.payload as AddScopeRequestWithResourceId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            resource.addResourceScope(addWithID.resourceId, addWithID.request)
                .then((newGrantTypeInformation: ResourceScopeInformation) => {
                    store.dispatch(action(ADD_RESOURCE_SCOPE_SUCCESS, newGrantTypeInformation));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_RESOURCE_SCOPE_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;

        case DELETE_RESOURCE_SCOPE:
            const deleteWithID: DeleteScopeRequest = (requestedAction.payload as DeleteScopeRequest);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            resource.removeResourceScope(deleteWithID.resourceId, deleteWithID.scopeId)
                .then(() => {
                    store.dispatch(action(DELETE_RESOURCE_SCOPE_SUCCESS, deleteWithID.scopeId));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(DELETE_RESOURCE_SCOPE_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminResourceScopesDataService;