import { Middleware } from 'redux';

import {
    ResourceScopeInformation
} from '../../../../../../../../shared/utils/DataAccess';
import {
    UPDATE_RESOURCE_SCOPE_SUCCESS, UPDATE_RESOURCE_SCOPE_ERROR, UPDATE_RESOURCE_SCOPE,
    LOAD_RESOURCE_SCOPE, LOAD_RESOURCE_SCOPE_SUCCESS, LOAD_RESOURCE_SCOPE_ERROR
} from './actionDef';
import { AdminResourcesScopeAction, UpdateResourceScopeRequestWithResourceId, LoadResourceScopeRequest } from './actions';
import APIClientFactory from '../../../../../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../../../../../shared/spinner/redux/reducer';


const adminResourceScopeDataService: Middleware = store => next => (requestedAction: AdminResourcesScopeAction) => {
    next(requestedAction);

    const resource = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case LOAD_RESOURCE_SCOPE:
            const loadRequest: LoadResourceScopeRequest = (requestedAction.payload as LoadResourceScopeRequest);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            resource.loadResourceScope(loadRequest.resourceId, loadRequest.scopeId)
                .then((scopeInformation: ResourceScopeInformation) => {
                    store.dispatch(action(LOAD_RESOURCE_SCOPE_SUCCESS, scopeInformation));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(LOAD_RESOURCE_SCOPE_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;

        case UPDATE_RESOURCE_SCOPE:
            const updateWithId: UpdateResourceScopeRequestWithResourceId = (requestedAction.payload as UpdateResourceScopeRequestWithResourceId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            resource.updateResourceScope(updateWithId.resourceId, updateWithId.id, updateWithId.request)
                .then(() => {
                    //we want to update our state, so make new UserInformation from UpdateUserRequest
                    const updatedRecord: ResourceScopeInformation = ResourceScopeInformation.fromJS({
                        id: updateWithId.id,
                        name: updateWithId.request.name,
                        displayName: updateWithId.request.displayName,
                        description: updateWithId.request.description,
                        required: updateWithId.request.required,
                        emphasize: updateWithId.request.emphasize,
                        showInDiscoveryDocument: updateWithId.request.showInDiscoveryDocument,
                    });
                    store.dispatch(action(UPDATE_RESOURCE_SCOPE_SUCCESS, updatedRecord));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(UPDATE_RESOURCE_SCOPE_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;

        default:
            break;
    }
}

export default adminResourceScopeDataService;