export const ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS = "ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS";
export type ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS = typeof ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS;
export const ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_SUCCESS = "ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_SUCCESS";
export type ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_SUCCESS = typeof ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_SUCCESS;
export const ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_ERROR = "ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_ERROR";
export type ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_ERROR = typeof ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_ERROR;

export const ADD_CLIENT_ALLOWED_CORS_ORIGIN = "ADD_CLIENT_ALLOWED_CORS_ORIGIN";
export type ADD_CLIENT_ALLOWED_CORS_ORIGIN = typeof ADD_CLIENT_ALLOWED_CORS_ORIGIN;
export const ADD_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS = "ADD_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS";
export type ADD_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS = typeof ADD_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS;
export const ADD_CLIENT_ALLOWED_CORS_ORIGIN_ERROR = "ADD_CLIENT_ALLOWED_CORS_ORIGIN_ERROR";
export type ADD_CLIENT_ALLOWED_CORS_ORIGIN_ERROR = typeof ADD_CLIENT_ALLOWED_CORS_ORIGIN_ERROR;

export const DELETE_CLIENT_ALLOWED_CORS_ORIGIN = "DELETE_CLIENT_ALLOWED_CORS_ORIGIN";
export type DELETE_CLIENT_ALLOWED_CORS_ORIGIN = typeof DELETE_CLIENT_ALLOWED_CORS_ORIGIN;
export const DELETE_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS = "DELETE_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS";
export type DELETE_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS = typeof DELETE_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS;
export const DELETE_CLIENT_ALLOWED_CORS_ORIGIN_ERROR = "DELETE_CLIENT_ALLOWED_CORS_ORIGIN_ERROR";
export type DELETE_CLIENT_ALLOWED_CORS_ORIGIN_ERROR = typeof DELETE_CLIENT_ALLOWED_CORS_ORIGIN_ERROR;
