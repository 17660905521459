import { action } from "typesafe-actions";
import { ENUMERATE_RESOURCES, ADD_RESOURCE, REMOVE_RESOURCE } from "./actionDef";
import { AddResourceRequest, ResourceInformation } from "../../../../shared/utils/DataAccess";


export interface AdminResourcesAction {
    type: string;
    payload?: number | AddResourceRequest | ResourceInformation[] | null;
}


export const enumerateResources = (): AdminResourcesAction => action(ENUMERATE_RESOURCES, null);
export const addResource = (request: AddResourceRequest): AdminResourcesAction => action(ADD_RESOURCE, request);
export const removeResource = (id: number): AdminResourcesAction => action(REMOVE_RESOURCE, id);
