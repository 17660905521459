import { Middleware } from 'redux';

import {
    RedirectUri
} from '../../../../../../shared/utils/DataAccess';
import {
    ENUMERATE_CLIENT_REDIRECT_URIS, ENUMERATE_CLIENT_REDIRECT_URIS_SUCCESS, ENUMERATE_CLIENT_REDIRECT_URIS_ERROR,
    ADD_CLIENT_REDIRECT_URI, ADD_CLIENT_REDIRECT_URI_SUCCESS, ADD_CLIENT_REDIRECT_URI_ERROR,
    DELETE_CLIENT_REDIRECT_URI, DELETE_CLIENT_REDIRECT_URI_SUCCESS, DELETE_CLIENT_REDIRECT_URI_ERROR
} from './actionDef';
import { AdminClientRedirectUrisAction, AddRedirectUriRequestWithClientId, DeleteRedirectUriRequest } from './actions';
import APIClientFactory from '../../../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../../../shared/spinner/redux/reducer';



const adminClientRedirectUrisDataService: Middleware = store => next => (requestedAction: AdminClientRedirectUrisAction) => {
    next(requestedAction);

    const client = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ENUMERATE_CLIENT_REDIRECT_URIS:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.enumerateRedirectUris(requestedAction.payload as number)
                .then((RedirectUris: RedirectUri[]) => {
                    store.dispatch(action(ENUMERATE_CLIENT_REDIRECT_URIS_SUCCESS, RedirectUris));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_CLIENT_REDIRECT_URIS_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ADD_CLIENT_REDIRECT_URI:
            const addWithID: AddRedirectUriRequestWithClientId = (requestedAction.payload as AddRedirectUriRequestWithClientId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.addClientRedirectUri(addWithID.clientId, addWithID.request)
                .then((newAllowedOrigin: RedirectUri) => {
                    store.dispatch(action(ADD_CLIENT_REDIRECT_URI_SUCCESS, newAllowedOrigin));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_CLIENT_REDIRECT_URI_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case DELETE_CLIENT_REDIRECT_URI:
            const deleteWithID: DeleteRedirectUriRequest = (requestedAction.payload as DeleteRedirectUriRequest);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.removeClientRedirectUri(deleteWithID.clientId, deleteWithID.RedirectUriId)
                .then(() => {
                    store.dispatch(action(DELETE_CLIENT_REDIRECT_URI_SUCCESS, deleteWithID.RedirectUriId));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(DELETE_CLIENT_REDIRECT_URI_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminClientRedirectUrisDataService;