import { AdminClientAction, ConfigUpdateDtoWithId } from "./actions";
import { ClientConfigInformation } from "../../../../../../shared/utils/DataAccess";
import {
    LOAD_CLIENT_SUCCESS, UPDATE_CLIENT_SUCCESS,
} from "./actionDef";


export interface AdminClientState {
    clientConfiguration: ClientConfigInformation
};


export const initialState: AdminClientState = {
    clientConfiguration: ClientConfigInformation.fromJS({})
};


export function adminClientReducer(state: AdminClientState = initialState, action: AdminClientAction): AdminClientState {
    switch (action.type) {
        case LOAD_CLIENT_SUCCESS:
            //convert to display object
            const clientConfiguration: ClientConfigInformation = action.payload as ClientConfigInformation;

            return {
                ...state,
                clientConfiguration: clientConfiguration
            };
        case UPDATE_CLIENT_SUCCESS:
            //convert to display object
            const updateRequest: ConfigUpdateDtoWithId = action.payload as ConfigUpdateDtoWithId;

            return {
                ...state,
                clientConfiguration: {
                    ...state.clientConfiguration,
                    allowAccessTokensViaBrowser: updateRequest.request.allowAccessTokensViaBrowser,
                    allowOfflineAccess: updateRequest.request.allowOfflineAccess,
                    clientId: updateRequest.request.clientId,
                    clientName: updateRequest.request.clientName,
                    requireClientSecret: updateRequest.request.requireClientSecret,
                    requirePkce: updateRequest.request.requirePkce
                } as ClientConfigInformation
            };
        default:
            return state;
    }
}