import { AddResourcesScopesClaimRequest, ResourcesScopesClaimInformation } from "../../../../../../../../shared/utils/DataAccess";
import { ENUMERATE_RESOURCE_SCOPE_CLAIMS, ADD_RESOURCE_SCOPE_CLAIM, DELETE_RESOURCE_SCOPE_CLAIM } from "./actionDef";
import { action } from "typesafe-actions";
import { EnumerateResourcesScopesClaimsRequest } from "../../redux/actions";


export interface AdminResourcesScopesClaimsAction {
    type: string;
    payload?:
    number |
    EnumerateResourcesScopesClaimsRequest | DeleteResourcesScopesClaimRequest | AddResourcesScopesClaimRequest |
    ResourcesScopesClaimInformation | ResourcesScopesClaimInformation[];
}


export interface AddResourcesScopesClaimRequestWithIds {
    resourceId: number,
    scopeId: number,
    request: AddResourcesScopesClaimRequest
}


export interface DeleteResourcesScopesClaimRequest {
    resourceId: number,
    scopeId: number,
    claimId: number
}


export const loadResourceScopesClaims = (resourceId: number, scopeId: number): AdminResourcesScopesClaimsAction =>
    action(
        ENUMERATE_RESOURCE_SCOPE_CLAIMS,
        { resourceId: resourceId, scopeId: scopeId } as EnumerateResourcesScopesClaimsRequest);
export const addResourceScopesClaims = (resourceId: number, scopeId: number, ClaimType: string): AdminResourcesScopesClaimsAction =>
    action(
        ADD_RESOURCE_SCOPE_CLAIM,
        { resourceId: resourceId, scopeId: scopeId, request: { type: ClaimType } as AddResourcesScopesClaimRequest } as AddResourcesScopesClaimRequestWithIds);
export const deleteResourceScopesClaims = (resourceId: number, scopeId: number, claimId: number): AdminResourcesScopesClaimsAction =>
    action(DELETE_RESOURCE_SCOPE_CLAIM,
        { resourceId: resourceId, scopeId: scopeId, claimId: claimId } as DeleteResourcesScopesClaimRequest);

