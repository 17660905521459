import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { TextField, Button } from '@material-ui/core';
import { ResourceInformation, ResourcePropertyInformation, AddResourcePropertyRequest, UpdateResourcePropertyRequest } from '../../../../../../shared/utils/DataAccess';
import { StoreState } from '../../../../../../shared/redux/store';
import FullTableComponent, { DisplayType, Column } from '../../../../../../shared/FullTable/components/FullTableComponent';
import ResourcePropertyComponent from './ResourcePropertyComponent';
import { AdminResourcePropertiesState } from '../redux/reducer';
import { loadResourceProperties, deleteResourceProperty, addResourceProperty, editStart, editChanges, editCancel, updateResourceProperty } from '../redux/actions';


type Props = {
    resourceInformation: ResourceInformation
}


const ResourcePropertiesComponent: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();


    const [addResourcePropertyRequest, setResourcePropertyRequest] = useState<AddResourcePropertyRequest>(AddResourcePropertyRequest.fromJS({
        key: "",
        value: ""
    }));


    const clientPropertiesState: AdminResourcePropertiesState = useSelector((state: StoreState) => {
        return state.adminResourcePropertiesReducer;
    });


    const apiEnumerate = () => {
        if (props.resourceInformation.id) {
            dispatch(loadResourceProperties(props.resourceInformation.id));
        }
    };


    const onEditStart = (id: number) => {
        dispatch(editStart(id));
    }


    const onEditChanges = (id: number, hasChanges: boolean) => {
        dispatch(editChanges(id, hasChanges));
    }


    const onEditCancel = (id: number) => {
        dispatch(editCancel(id));
    }


    const onUpdateProperty = (id: number, request: UpdateResourcePropertyRequest) => {
        dispatch(updateResourceProperty(props.resourceInformation.id, id, request));
    }


    const onDelete = (request: number) => {
        dispatch(deleteResourceProperty(props.resourceInformation.id, request));
    };


    useEffect(() => {
        apiEnumerate();
    }, [props.resourceInformation]);


    const hasChanges = (): boolean => {
        return (addResourcePropertyRequest.key.length > 0 && addResourcePropertyRequest.value.length > 0);
    }


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newRequest: ResourcePropertyInformation = ResourcePropertyInformation.fromJS({
            ...addResourcePropertyRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setResourcePropertyRequest(newRequest);
    }


    const onSubmit = () => {
        dispatch(addResourceProperty(props.resourceInformation.id, addResourcePropertyRequest));

        setResourcePropertyRequest(AddResourcePropertyRequest.fromJS({
            key: "",
            value: ""
        }));
    }


    const Columns: Column[] = [
        { name: "Edit", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Key", displayName: "Key", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Value", displayName: "Value", sortable: false, displayType: DisplayType.Always, textAlign: "center" },
        { name: "Delete", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
    ];


    const classes = makeStyles({
        row: {
            maxWidth: "500px"
        }
    })();


    return (
        <>
            <div className={classes.row}>
                <FullTableComponent Columns={Columns} apiEnumerate={apiEnumerate}>
                    {clientPropertiesState ? clientPropertiesState.list.allIds.map(id => (
                        <ResourcePropertyComponent
                            key={id}
                            property={clientPropertiesState.list.byId[id]}
                            onUpdateProperty={onUpdateProperty}
                            onEditStart={onEditStart}
                            onEditChanges={onEditChanges}
                            onEditCancel={onEditCancel}
                            onDelete={onDelete}
                        ></ResourcePropertyComponent>
                    )) : ""}
                </FullTableComponent>
            </div>
            <div className={classes.row} style={{ marginTop: '50px' }}>
                <TextField
                    id="key"
                    label="New Property Key"
                    fullWidth={true}
                    value={addResourcePropertyRequest.key}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    id="value"
                    label="New Property Value"
                    fullWidth={true}
                    value={addResourcePropertyRequest.value}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row} style={{ textAlign: 'right' }}>
                <Button variant="outlined" color="primary" onClick={onSubmit} disabled={!hasChanges()}>Add Property</Button>
            </div>
        </>
    );


}


export default ResourcePropertiesComponent;