import { Middleware } from 'redux';

import {
    ResourcePropertyInformation
} from '../../../../../../shared/utils/DataAccess';
import {
    ENUMERATE_RESOURCE_PROPERTIES, ENUMERATE_RESOURCE_PROPERTIES_SUCCESS, ENUMERATE_RESOURCE_PROPERTIES_ERROR,
    ADD_RESOURCE_PROPERTY, ADD_RESOURCE_PROPERTY_SUCCESS, ADD_RESOURCE_PROPERTY_ERROR,
    DELETE_RESOURCE_PROPERTY, DELETE_RESOURCE_PROPERTY_SUCCESS, DELETE_RESOURCE_PROPERTY_ERROR, UPDATE_RESOURCE_PROPERTY_SUCCESS, UPDATE_RESOURCE_PROPERTY
} from './actionDef';
import { AdminResourcePropertiesAction, AddPropertyRequestWithResourceId, DeletePropertyRequest, UpdateResourcePropertyRequestWithResourceId } from './actions';
import APIClientFactory from '../../../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../../../shared/spinner/redux/reducer';



const adminResourcePropertiesDataService: Middleware = store => next => (requestedAction: AdminResourcePropertiesAction) => {
    next(requestedAction);

    const resource = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ENUMERATE_RESOURCE_PROPERTIES:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));
            resource.enumerateResourceProperties(requestedAction.payload as number)
                .then((Claims: ResourcePropertyInformation[]) => {
                    store.dispatch(action(ENUMERATE_RESOURCE_PROPERTIES_SUCCESS, Claims));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_RESOURCE_PROPERTIES_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ADD_RESOURCE_PROPERTY:
            const addWithID: AddPropertyRequestWithResourceId = (requestedAction.payload as AddPropertyRequestWithResourceId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            resource.addResourceProperty(addWithID.resourceId, addWithID.request)
                .then((newGrantTypeInformation: ResourcePropertyInformation) => {
                    store.dispatch(action(ADD_RESOURCE_PROPERTY_SUCCESS, newGrantTypeInformation));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_RESOURCE_PROPERTY_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case UPDATE_RESOURCE_PROPERTY:
            const updateWithId: UpdateResourcePropertyRequestWithResourceId = (requestedAction.payload as UpdateResourcePropertyRequestWithResourceId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            resource.updateResourceProperty(updateWithId.resourceId, updateWithId.id, updateWithId.request)
                .then(() => {
                    //we want to update our state, so make new UserInformation from UpdateUserRequest
                    const updatedRecord: ResourcePropertyInformation = ResourcePropertyInformation.fromJS({
                        id: updateWithId.id,
                        key: updateWithId.request.key,
                        value: updateWithId.request.value,
                    });
                    store.dispatch(action(UPDATE_RESOURCE_PROPERTY_SUCCESS, updatedRecord));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_RESOURCE_PROPERTY_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;

        case DELETE_RESOURCE_PROPERTY:
            const deleteWithID: DeletePropertyRequest = (requestedAction.payload as DeletePropertyRequest);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            resource.removeResourceProperty(deleteWithID.resourceId, deleteWithID.propertyId)
                .then(() => {
                    store.dispatch(action(DELETE_RESOURCE_PROPERTY_SUCCESS, deleteWithID.propertyId));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(DELETE_RESOURCE_PROPERTY_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminResourcePropertiesDataService;