import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { TextField, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { updateResourceScope } from '../redux/actions';
import { UpdateResourceScopeRequest, ResourceScopeInformation } from '../../../../../../../../shared/utils/DataAccess';


type Props = {
    resourceId: number,
    scopeInformation: ResourceScopeInformation
}


const ResourcesScopeEditComponent: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();


    const [updateResourceScopeRequest, setupdateResourceScopeRequest] = useState<ResourceScopeInformation>(ResourceScopeInformation.fromJS({
        id: 0,
        name: "",
        displayName: "",
        description: "",
        required: false,
        emphasize: false,
        showInDiscoveryDocument: false,
    }));


    useEffect(() => {
        if (updateResourceScopeRequest.id !== props.scopeInformation.id) {
            setupdateResourceScopeRequest(ResourceScopeInformation.fromJS({
                id: props.scopeInformation.id,
                name: props.scopeInformation.name,
                displayName: props.scopeInformation.displayName,
                description: props.scopeInformation.description,
                required: props.scopeInformation.required,
                emphasize: props.scopeInformation.emphasize,
                showInDiscoveryDocument: props.scopeInformation.showInDiscoveryDocument,
            }));
        }
    }, [props.scopeInformation]);


    const hasChanges = (): boolean => {
        return updateResourceScopeRequest.name !== props.scopeInformation.name ||
            updateResourceScopeRequest.displayName !== props.scopeInformation.displayName ||
            updateResourceScopeRequest.description !== props.scopeInformation.description ||
            updateResourceScopeRequest.required !== props.scopeInformation.required ||
            updateResourceScopeRequest.emphasize !== props.scopeInformation.emphasize ||
            updateResourceScopeRequest.showInDiscoveryDocument !== props.scopeInformation.showInDiscoveryDocument;
    }


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAddResourceScopeRequest: ResourceScopeInformation = ResourceScopeInformation.fromJS({
            ...updateResourceScopeRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setupdateResourceScopeRequest(newAddResourceScopeRequest);
    }


    const onCheckFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAddResourceScopeRequest: ResourceScopeInformation = ResourceScopeInformation.fromJS({
            ...updateResourceScopeRequest,
            [e.currentTarget.id]: e.currentTarget.checked
        });

        setupdateResourceScopeRequest(newAddResourceScopeRequest);
    }


    const onSubmit = () => {
        const request: UpdateResourceScopeRequest = UpdateResourceScopeRequest.fromJS({
            name: updateResourceScopeRequest.name,
            displayName: updateResourceScopeRequest.displayName,
            description: updateResourceScopeRequest.description,
            required: updateResourceScopeRequest.required,
            emphasize: updateResourceScopeRequest.emphasize,
            showInDiscoveryDocument: updateResourceScopeRequest.showInDiscoveryDocument,
        });

        dispatch(updateResourceScope(props.resourceId, updateResourceScopeRequest.id, request));
    }


    const classes = makeStyles({
        row: {
            maxWidth: "600px"
        }
    })();


    return (
        <>
            <div className={classes.row}>
                <TextField
                    id="name"
                    label="Name"
                    fullWidth={true}
                    value={updateResourceScopeRequest.name}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    id="displayName"
                    label="Display Name"
                    fullWidth={true}
                    value={updateResourceScopeRequest.displayName}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    id="description"
                    label="Description"
                    fullWidth={true}
                    value={updateResourceScopeRequest.description}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="required"
                            checked={updateResourceScopeRequest.required}
                            onChange={onCheckFieldChange}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                            style={{ float: 'right' }}
                        />
                    }
                    label="Required"
                />
            </div>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="emphasize"
                            checked={updateResourceScopeRequest.emphasize}
                            onChange={onCheckFieldChange}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                            style={{ float: 'right' }}
                        />
                    }
                    label="Emphasize"
                />
            </div>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="showInDiscoveryDocument"
                            checked={updateResourceScopeRequest.showInDiscoveryDocument}
                            onChange={onCheckFieldChange}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                            style={{ float: 'right' }}
                        />
                    }
                    label="Show in Discovery Document"
                />
            </div>
            <div className={classes.row} style={{ textAlign: 'right' }}>
                <Button variant="outlined" color="primary" onClick={onSubmit} disabled={!hasChanges()}>Save Scope</Button>
            </div>
        </>
    );


}


export default ResourcesScopeEditComponent;