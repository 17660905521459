import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { SpinnerDisplayState, SpinnerState } from '../redux/reducer';
import { StoreState } from '../../redux/store';
import { connect } from 'react-redux';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { updateSpinner } from '../redux/actions';
import working from '../../assets/working.gif';


//don't display spinner if server returns a result within 1 second
const API_SPINNER_DELAY_MS = 1 * 1000;


interface StateProps {
    spinnerState: SpinnerState
}


interface State {
    timerID: number | null,
    spinnerState: SpinnerState
}


const mapStateToProps = (state: StoreState): StateProps => {
    return {
        spinnerState: state.spinnerReducer,
    };
};


interface DispatchProps {
    updateSpinner: (displayState: SpinnerState) => void;
}


const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        updateSpinner: (displayState: SpinnerState) => { dispatch(updateSpinner(displayState)) }
    };
};


export type Props = StateProps & DispatchProps;


class SpinnerComponent extends Component<Props, State>{
    constructor(props: Props) {
        super(props);

        this.state = {
            timerID: null,
            spinnerState: {
                displayState: SpinnerDisplayState.Visible
            }
        }
    };


    componentDidUpdate(prevProps: Props, prevState: State) {
        const { displayState: newDisplayState } = this.props.spinnerState;
        if (newDisplayState === this.state.spinnerState.displayState) {
            return;
        }

        this.setState({
            spinnerState: {
                displayState: newDisplayState
            }
        });

        if (newDisplayState === SpinnerDisplayState.Pending) {
            if (this.state.timerID === null) {
                this.setState({
                    timerID: setTimeout(this.onDisplaySpinner, API_SPINNER_DELAY_MS)
                });
            }
        } else {
            this.onCancelSpinner();
        }
    }


    onDisplaySpinner = () => {
        if (this.state.timerID !== null) {
            this.onCancelSpinner();
            this.props.updateSpinner({ displayState: SpinnerDisplayState.Visible } as SpinnerState);
        }
    }


    onCancelSpinner = () => {
        if (this.state.timerID !== null) {
            clearTimeout(this.state.timerID);

            this.setState({
                timerID: null
            });
        }
    }


    render() {
        const { displayState } = this.state.spinnerState;

        if (displayState === SpinnerDisplayState.Visible) {
            return (
                <Dialog open={true}>
                    <DialogTitle>Please wait...</DialogTitle>
                    <DialogContent>
                        <img style={{ display: "block", width: "64px", margin: "0 auto 10px auto" }} src={working} alt="working-icon" />
                    </DialogContent>
                </Dialog>);
        } else {
            return "";
        }
    }


};


export default connect(mapStateToProps, mapDispatchToProps)(SpinnerComponent);