import { AddResourceClaimRequest, ResourceClaimInformation } from "../../../../../../shared/utils/DataAccess";
import { ENUMERATE_RESOURCE_CLAIMS, ADD_RESOURCE_CLAIM, DELETE_RESOURCE_CLAIM, DISPLAY_STATE_CHANGE } from "./actionDef";
import { action } from "typesafe-actions";
import { DisplayState } from "../../../../../../shared/FullTable/components/FullTableRow";


export interface AdminResourceClaimsAction {
    type: string;
    payload?:
    number | ChangeEditState |
    AddClaimRequestWithResourceId | DeleteClaimRequest | AddResourceClaimRequest |
    ResourceClaimInformation | ResourceClaimInformation[];
}


export interface AddClaimRequestWithResourceId {
    resourceId: number,
    request: AddResourceClaimRequest
}


export interface DeleteClaimRequest {
    resourceId: number,
    claimId: number
}


export interface ChangeEditState {
    id: number;
    displayState: DisplayState;
}


export const loadResourceClaims = (resourceId: number): AdminResourceClaimsAction => action(ENUMERATE_RESOURCE_CLAIMS, resourceId);
export const addResourceClaim = (resourceId: number, request: AddResourceClaimRequest): AdminResourceClaimsAction =>
    action(
        ADD_RESOURCE_CLAIM,
        { resourceId: resourceId, request: request } as AddClaimRequestWithResourceId);
export const deleteResourceClaim = (resourceId: number, claimId: number): AdminResourceClaimsAction =>
    action(DELETE_RESOURCE_CLAIM,
        { resourceId: resourceId, claimId: claimId });

export const editStart = (id: number): AdminResourceClaimsAction => action(DISPLAY_STATE_CHANGE, { id: id, displayState: DisplayState.Edit });
export const editChanges = (id: number, hasChanges: boolean) => action(DISPLAY_STATE_CHANGE, { id: id, displayState: hasChanges ? DisplayState.NotSaved : DisplayState.Edit });
export const editCancel = (id: number): AdminResourceClaimsAction => action(DISPLAY_STATE_CHANGE, { id: id, displayState: DisplayState.ReadOnly });