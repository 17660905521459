import { Middleware } from 'redux';

import {
    ClientAllowedGrantTypeInformation
} from '../../../../../../shared/utils/DataAccess';
import {
    ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES, ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_SUCCESS, ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_ERROR,
    ADD_CLIENT_ALLOWED_GRANT_TYPE, ADD_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS, ADD_CLIENT_ALLOWED_GRANT_TYPE_ERROR,
    DELETE_CLIENT_ALLOWED_GRANT_TYPE, DELETE_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS, DELETE_CLIENT_ALLOWED_GRANT_TYPE_ERROR
} from './actionDef';
import { AdminClientAllowedGrantTypesAction, AddAllowedGrantTypeRequestWithClientId, DeleteAllowedGrantTypeRequest } from './actions';
import APIClientFactory from '../../../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../../../shared/spinner/redux/reducer';



const adminClientAllowedGrantTypesDataService: Middleware = store => next => (requestedAction: AdminClientAllowedGrantTypesAction) => {
    next(requestedAction);

    const client = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));
            client.enumerateAllowedGrantTypes(requestedAction.payload as number)
                .then((allowedGrantTypes: ClientAllowedGrantTypeInformation[]) => {
                    store.dispatch(action(ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_SUCCESS, allowedGrantTypes));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ADD_CLIENT_ALLOWED_GRANT_TYPE:
            const addWithID: AddAllowedGrantTypeRequestWithClientId = (requestedAction.payload as AddAllowedGrantTypeRequestWithClientId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.addAllowedGrantType(addWithID.clientId, addWithID.request)
                .then((newGrantTypeInformation: ClientAllowedGrantTypeInformation) => {
                    store.dispatch(action(ADD_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS, newGrantTypeInformation));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_CLIENT_ALLOWED_GRANT_TYPE_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case DELETE_CLIENT_ALLOWED_GRANT_TYPE:
            const deleteWithID: DeleteAllowedGrantTypeRequest = (requestedAction.payload as DeleteAllowedGrantTypeRequest);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.removeAllowedGrantType(deleteWithID.clientId, deleteWithID.AllowedGrantTypeId)
                .then(() => {
                    store.dispatch(action(DELETE_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS, deleteWithID.AllowedGrantTypeId));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(DELETE_CLIENT_ALLOWED_GRANT_TYPE_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminClientAllowedGrantTypesDataService;