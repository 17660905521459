import React, { FunctionComponent } from 'react';
import { RedirectUri } from '../../../../../../shared/utils/DataAccess';
import { TableCell } from '@material-ui/core';
import FullTableRow, { DisplayState } from '../../../../../../shared/FullTable/components/FullTableRow';


type Props = {
    RedirectUri: RedirectUri,
    onDelete(id: number): void
}


const ClientRedirectUriComponent: FunctionComponent<Props> = (props: Props) => {


    const { RedirectUri } = props;


    const onDelete = () => {
        props.onDelete(RedirectUri.id);
    }


    return (
        <FullTableRow displayState={DisplayState.ReadOnly} canDelete={true} onDelete={onDelete} >
            <TableCell component="th" scope="row">{RedirectUri.uri}</TableCell>
        </FullTableRow>
    );


}


export default ClientRedirectUriComponent;