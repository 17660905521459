import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import { applyMiddleware, combineReducers, createStore, Reducer } from 'redux';
import { reducer as userReducerFromPackge, UserState } from 'redux-oidc';

import appPasswordDataService from '../../pages/app-passwords/redux/dataService';
import { appPasswordsReducer, AppPasswordsState } from '../../pages/app-passwords/redux/reducer';
import grantManagementDataService from '../../pages/grants/redux/dataService';
import { grantsReducer, GrantsState } from '../../pages/grants/redux/reducer';
import { signinReducer } from '../../pages/redirect/redux/reducer';
import signinDataService from '../../pages/redirect/redux/dataService';
import { ProfileState } from '../../pages/profile/redux/reducer';
import { profileReducer } from '../../pages/profile/redux/reducer';
import profileDataService from '../../pages/profile/redux/dataService';
import { AdminUserListState, adminUsersReducer } from '../../pages/admin/users/redux/reducer';
import adminUsersDataService from '../../pages/admin/users/redux/dataService';
import { errorReducer, ErrorState } from '../error/redux/reducer';
import { spinnerReducer, SpinnerState } from '../spinner/redux/reducer';
import { adminAdministratorsReducer, AdminAdministratorsListState } from '../../pages/admin/administrators/redux/reducer';
import adminAdministratorsDataService from '../../pages/admin/administrators/redux/dataService';
import adminClientsDataService from '../../pages/admin/clients/redux/dataService';
import { adminClientsReducer, AdminClientListState } from '../../pages/admin/clients/redux/reducer';
import { adminClientReducer, AdminClientState } from '../../pages/admin/clients/client/edit/redux/reducer';
import adminClientDataService from '../../pages/admin/clients/client/edit/redux/dataService';
import adminClientAllowedCorsOriginsDataService from '../../pages/admin/clients/client/allowedCorsOrigins/redux/dataService';
import { adminClientAllowedCorsOriginsReducer, ClientAllowedCorsOriginsState } from '../../pages/admin/clients/client/allowedCorsOrigins/redux/reducer';
import { adminClientAllowedGrantTypesReducer, AdminClientAllowedGrantTypesState } from '../../pages/admin/clients/client/allowedGrantTypes/redux/reducer';
import adminClientAllowedGrantTypesDataService from '../../pages/admin/clients/client/allowedGrantTypes/redux/dataService';
import { adminClientClaimsReducer, AdminClientClaimsState } from '../../pages/admin/clients/client/claims/redux/reducer';
import adminClientClaimsDataService from '../../pages/admin/clients/client/claims/redux/dataService';
import { adminClientPropertiesReducer, AdminClientPropertiesState } from '../../pages/admin/clients/client/properties/redux/reducer';
import adminClientPropertiesDataService from '../../pages/admin/clients/client/properties/redux/dataService';
import { adminClientPostLogoutRedirectUrisReducer, ClientPostLogoutRedirectUrisState } from '../../pages/admin/clients/client/postLogoutRedirectUris/redux/reducer';
import adminClientPostLogoutRedirectUrisDataService from '../../pages/admin/clients/client/postLogoutRedirectUris/redux/dataService';
import { adminClientRedirectUrisReducer, ClientRedirectUrisState } from '../../pages/admin/clients/client/RedirectUris/redux/reducer';
import adminClientRedirectUrisDataService from '../../pages/admin/clients/client/RedirectUris/redux/dataService';
import { adminResourcesReducer, AdminResourceListState } from '../../pages/admin/resources/redux/reducer';
import adminResourcesDataService from '../../pages/admin/resources/redux/dataService';
import { adminResourceReducer, AdminResourceState } from '../../pages/admin/resources/resource/edit/redux/reducer';
import adminResourceDataService from '../../pages/admin/resources/resource/edit/redux/dataService';
import { adminResourceClaimsReducer, AdminResourceClaimsState } from '../../pages/admin/resources/resource/claims/redux/reducer';
import adminResourceClaimsDataService from '../../pages/admin/resources/resource/claims/redux/dataService';
import { adminResourcePropertiesReducer, AdminResourcePropertiesState } from '../../pages/admin/resources/resource/properties/redux/reducer';
import adminResourcePropertiesDataService from '../../pages/admin/resources/resource/properties/redux/dataService';
import { adminResourceSecretsReducer, AdminResourceSecretsState } from '../../pages/admin/resources/resource/secrets/redux/reducer';
import adminResourceSecretsDataService from '../../pages/admin/resources/resource/secrets/redux/dataService';
import { adminResourceScopesReducer, AdminResourceScopesState } from '../../pages/admin/resources/resource/scopes/redux/reducer';
import adminResourceScopesDataService from '../../pages/admin/resources/resource/scopes/redux/dataService';
import { adminResourcesScopesClaimsReducer, AdminResourcesScopesClaimsState } from '../../pages/admin/resources/resource/scopes/scope/redux/reducer';
import adminResourceScopesClaimsDataService from '../../pages/admin/resources/resource/scopes/scope/redux/dataService';
import { AdminResourcesScopeState, adminResourcesScopeReducer } from '../../pages/admin/resources/resource/scopes/scope/edit/redux/reducer';
import adminResourceScopeDataService from '../../pages/admin/resources/resource/scopes/scope/edit/redux/dataService';
import { adminClientAllowedScopesReducer, AdminClientAllowedScopesState } from '../../pages/admin/clients/client/allowedScopes/redux/reducer';
import adminClientAllowedScopesDataService from '../../pages/admin/clients/client/allowedScopes/redux/dataService';


export const history = createBrowserHistory();


const rootReducer = (history: History): Reducer => combineReducers({
  router: connectRouter(history),
  grantsReducer: grantsReducer,
  appPasswordsReducer: appPasswordsReducer,
  signinReducer: signinReducer,
  userReducer: userReducerFromPackge,
  profileReducer: profileReducer,
  adminUsersReducer: adminUsersReducer,
  spinnerReducer: spinnerReducer,
  adminAdministratorsReducer: adminAdministratorsReducer,
  adminClientsReducer: adminClientsReducer,
  adminClientReducer: adminClientReducer,
  adminClientAllowedCorsOriginsReducer: adminClientAllowedCorsOriginsReducer,
  adminClientAllowedGrantTypesReducer: adminClientAllowedGrantTypesReducer,
  adminClientAllowedScopesReducer: adminClientAllowedScopesReducer,
  adminClientClaimsReducer: adminClientClaimsReducer,
  adminClientPropertiesReducer: adminClientPropertiesReducer,
  adminClientPostLogoutRedirectUrisReducer: adminClientPostLogoutRedirectUrisReducer,
  adminClientRedirectUrisReducer: adminClientRedirectUrisReducer,
  adminResourcesReducer: adminResourcesReducer,
  adminResourceReducer: adminResourceReducer,
  adminResourceClaimsReducer: adminResourceClaimsReducer,
  adminResourcePropertiesReducer: adminResourcePropertiesReducer,
  adminResourceSecretsReducer: adminResourceSecretsReducer,
  adminResourceScopesReducer: adminResourceScopesReducer,
  adminResourcesScopeReducer: adminResourcesScopeReducer,
  adminResourcesScopesClaimsReducer: adminResourcesScopesClaimsReducer,
  errorReducer: errorReducer,
});


var sessionState = sessionStorage.getItem('sessionState') ? JSON.parse(sessionStorage.getItem('sessionState')!) : {};

if (sessionState.userReducer && sessionState.userReducer.user === null) {
  sessionState = {};  //this works, but gets rid of ALL session state
}


export const store = createStore(
  rootReducer(history),
  sessionState,
  applyMiddleware(
    routerMiddleware(history),
    grantManagementDataService,
    appPasswordDataService,
    profileDataService,
    signinDataService,
    adminUsersDataService,
    adminAdministratorsDataService,
    adminClientsDataService,
    adminClientDataService,
    adminClientAllowedCorsOriginsDataService,
    adminClientAllowedGrantTypesDataService,
    adminClientAllowedScopesDataService,
    adminClientClaimsDataService,
    adminClientPropertiesDataService,
    adminClientPostLogoutRedirectUrisDataService,
    adminClientRedirectUrisDataService,
    adminResourcesDataService,
    adminResourceDataService,
    adminResourceClaimsDataService,
    adminResourcePropertiesDataService,
    adminResourceSecretsDataService,
    adminResourceScopesDataService,
    adminResourceScopeDataService,
    adminResourceScopesClaimsDataService,
  )
);


store.subscribe(() => {
  sessionStorage.setItem('sessionState', JSON.stringify(store.getState()));
});


export interface StoreState {
  router: RouterState,
  grantsReducer: GrantsState,
  appPasswordsReducer: AppPasswordsState,
  userReducer: UserState,
  profileReducer: ProfileState,
  errorReducer: ErrorState,
  spinnerReducer: SpinnerState,
  adminUsersReducer: AdminUserListState,
  adminAdministratorsReducer: AdminAdministratorsListState,
  adminClientsReducer: AdminClientListState,
  adminClientReducer: AdminClientState,
  adminClientAllowedCorsOriginsReducer: ClientAllowedCorsOriginsState,
  adminClientAllowedGrantTypesReducer: AdminClientAllowedGrantTypesState,
  adminClientAllowedScopesReducer: AdminClientAllowedScopesState,
  adminClientClaimsReducer: AdminClientClaimsState,
  adminClientPropertiesReducer: AdminClientPropertiesState,
  adminClientPostLogoutRedirectUrisReducer: ClientPostLogoutRedirectUrisState,
  adminClientRedirectUrisReducer: ClientRedirectUrisState,
  adminResourcesReducer: AdminResourceListState,
  adminResourceReducer: AdminResourceState,
  adminResourceClaimsReducer: AdminResourceClaimsState,
  adminResourcePropertiesReducer: AdminResourcePropertiesState,
  adminResourceSecretsReducer: AdminResourceSecretsState,
  adminResourceScopesReducer: AdminResourceScopesState,
  adminResourcesScopeReducer: AdminResourcesScopeState,
  adminResourcesScopesClaimsReducer: AdminResourcesScopesClaimsState,
}