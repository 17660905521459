import React, { FunctionComponent, useState } from 'react';

import { AddUserRequest } from '../../../../shared/utils/DataAccess';

import TextField from '@material-ui/core/TextField';
import { Button, Checkbox, Dialog, DialogTitle, FormControlLabel, DialogContent, DialogActions, makeStyles } from '@material-ui/core';


interface StateProps {
    visible: boolean,
    onAddUser(addUserRequest: AddUserRequest): void,
    onClose(): void
}


export type Props = StateProps;


const AdminAddUserComponent: FunctionComponent<Props> = (props: Props) => {


    const CreateNewAddUserRequest = () => {
        return AddUserRequest.fromJS({
            enabled: false,
            loginEmail: "",
            loginPhone: "",
            externalId: "",
            givenName: "",
            surname: "",
            emailVerified: false
        });
    }


    const [addUserRequest, setaddUserRequest] = useState<AddUserRequest>(CreateNewAddUserRequest());


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAddUserRequest: AddUserRequest = AddUserRequest.fromJS({
            ...addUserRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setaddUserRequest(newAddUserRequest);
    }


    const onCheckFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAddUserRequest: AddUserRequest = AddUserRequest.fromJS({
            ...addUserRequest,
            [e.currentTarget.id]: e.currentTarget.checked
        });

        setaddUserRequest(newAddUserRequest);
    }


    const onCancel = () => {
        props.onClose();
    }


    const onSubmit = () => {
        props.onAddUser(addUserRequest);

        setaddUserRequest(CreateNewAddUserRequest());
    }


    const classes = makeStyles({
        row: {
            flex: 1,
            flexDirection: "row",
            clear: 'both',
            marginBottom: '5px'
        },
    })();


    return (
        <Dialog open={props.visible}>
            <DialogTitle>Add New User</DialogTitle>
            <DialogContent>
                <div className={classes.row}>
                    <TextField
                        id="givenName"
                        label="Given Name"
                        value={addUserRequest.givenName}
                        onChange={onTextFieldChange}
                    />
                </div>
                <div className={classes.row}>
                    <TextField
                        id="surname"
                        label="Surname"
                        value={addUserRequest.surname}
                        onChange={onTextFieldChange}
                    />
                </div>
                <div className={classes.row}>
                    <TextField
                        id="loginPhone"
                        label="Login Phone"
                        value={addUserRequest.loginPhone}
                        onChange={onTextFieldChange}
                    />
                </div>
                <div className={classes.row}>
                    <TextField
                        id="loginEmail"
                        label="Login Email"
                        value={addUserRequest.loginEmail}
                        onChange={onTextFieldChange}
                    />
                </div>
                <div className={classes.row}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="emailVerified"
                                checked={addUserRequest.emailVerified}
                                onChange={onCheckFieldChange}
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                                style={{ float: 'right' }}
                            />
                        }
                        label="Email Verified"
                    />
                </div>
                <div className={classes.row}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="enabled"
                                checked={addUserRequest.enabled}
                                onChange={onCheckFieldChange}
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                                style={{ float: 'right' }}
                            />
                        }
                        label="Enabled"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onCancel}>Cancel</Button>
                <Button color="primary" onClick={onSubmit}>Create User</Button>
            </DialogActions>
        </Dialog>
    );


};


export default AdminAddUserComponent;