import { AddResourceScopeRequest, ResourceScopeInformation } from "../../../../../../shared/utils/DataAccess";
import { ENUMERATE_RESOURCE_SCOPES, ADD_RESOURCE_SCOPE, DELETE_RESOURCE_SCOPE, DISPLAY_STATE_CHANGE } from "./actionDef";
import { action } from "typesafe-actions";
import { DisplayState } from "../../../../../../shared/FullTable/components/FullTableRow";


export interface AdminResourceScopesAction {
    type: string;
    payload?:
    number | ChangeEditState |
    AddScopeRequestWithResourceId | DeleteScopeRequest | AddResourceScopeRequest |
    ResourceScopeInformation | ResourceScopeInformation[];
}


export interface AddScopeRequestWithResourceId {
    resourceId: number,
    request: AddResourceScopeRequest
}


export interface DeleteScopeRequest {
    resourceId: number,
    scopeId: number
}


export interface ChangeEditState {
    id: number;
    displayState: DisplayState;
}


export const loadResourceScopes = (resourceId: number): AdminResourceScopesAction => action(ENUMERATE_RESOURCE_SCOPES, resourceId);
export const addResourceScope = (resourceId: number, request: AddResourceScopeRequest): AdminResourceScopesAction =>
    action(
        ADD_RESOURCE_SCOPE,
        { resourceId: resourceId, request: request } as AddScopeRequestWithResourceId);
export const deleteResourceScope = (resourceId: number, scopeId: number): AdminResourceScopesAction =>
    action(DELETE_RESOURCE_SCOPE,
        { resourceId: resourceId, scopeId: scopeId });

export const editStart = (id: number): AdminResourceScopesAction => action(DISPLAY_STATE_CHANGE, { id: id, displayState: DisplayState.Edit });
export const editChanges = (id: number, hasChanges: boolean) => action(DISPLAY_STATE_CHANGE, { id: id, displayState: hasChanges ? DisplayState.NotSaved : DisplayState.Edit });
export const editCancel = (id: number): AdminResourceScopesAction => action(DISPLAY_STATE_CHANGE, { id: id, displayState: DisplayState.ReadOnly });