import { AddResourcePropertyRequest, ResourcePropertyInformation, UpdateResourcePropertyRequest } from "../../../../../../shared/utils/DataAccess";
import { ENUMERATE_RESOURCE_PROPERTIES, ADD_RESOURCE_PROPERTY, DELETE_RESOURCE_PROPERTY, DISPLAY_STATE_CHANGE, UPDATE_RESOURCE_PROPERTY } from "./actionDef";
import { action } from "typesafe-actions";
import { DisplayState } from "../../../../../../shared/FullTable/components/FullTableRow";


export interface AdminResourcePropertiesAction {
    type: string;
    payload?:
    number | ChangeEditState |
    AddPropertyRequestWithResourceId | DeletePropertyRequest | AddResourcePropertyRequest | UpdateResourcePropertyRequestWithResourceId |
    ResourcePropertyInformation | ResourcePropertyInformation[];
}


export interface AddPropertyRequestWithResourceId {
    resourceId: number,
    request: AddResourcePropertyRequest
}


export interface UpdateResourcePropertyRequestWithResourceId {
    resourceId: number,
    id: number,
    request: UpdateResourcePropertyRequest
}


export interface DeletePropertyRequest {
    resourceId: number,
    propertyId: number
}


export interface ChangeEditState {
    id: number;
    displayState: DisplayState;
}


export const loadResourceProperties = (resourceId: number): AdminResourcePropertiesAction => action(ENUMERATE_RESOURCE_PROPERTIES, resourceId);
export const addResourceProperty = (resourceId: number, request: AddResourcePropertyRequest): AdminResourcePropertiesAction =>
    action(
        ADD_RESOURCE_PROPERTY,
        { resourceId: resourceId, request: request } as AddPropertyRequestWithResourceId);
export const deleteResourceProperty = (resourceId: number, propertyId: number): AdminResourcePropertiesAction =>
    action(DELETE_RESOURCE_PROPERTY,
        { resourceId: resourceId, propertyId: propertyId });

export const updateResourceProperty = (resourceId: number, id: number, request: UpdateResourcePropertyRequest): AdminResourcePropertiesAction =>
    action(
        UPDATE_RESOURCE_PROPERTY,
        { resourceId: resourceId, id: id, request: request } as UpdateResourcePropertyRequestWithResourceId);

export const editStart = (id: number): AdminResourcePropertiesAction => action(DISPLAY_STATE_CHANGE, { id: id, displayState: DisplayState.Edit });
export const editChanges = (id: number, hasChanges: boolean) => action(DISPLAY_STATE_CHANGE, { id: id, displayState: hasChanges ? DisplayState.NotSaved : DisplayState.Edit });
export const editCancel = (id: number): AdminResourcePropertiesAction => action(DISPLAY_STATE_CHANGE, { id: id, displayState: DisplayState.ReadOnly });