import { Middleware } from 'redux';

import {
    AddUserRequest, UserInformation, UserList
} from '../../../../shared/utils/DataAccess';
import {
    ENUMERATE_USERS, ENUMERATE_USERS_ERROR, ENUMERATE_USERS_SUCCESS,
    ADD_USER, ADD_USER_ERROR, ADD_USER_SUCCESS,
    UPDATE_USER, UPDATE_USER_ERROR, UPDATE_USER_SUCCESS,
    REMOVE_USER, REMOVE_USER_ERROR, REMOVE_USER_SUCCESS
} from './actionDef';
import { AdminUsersAction, UpdateUserRequestWithId, EnumerateUsersRequest, AdminUsersError } from './actions';
import APIClientFactory from '../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../shared/error/redux/reducer';
import { SpinnerDisplayState, SpinnerState } from '../../../../shared/spinner/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../shared/spinner/redux/actionDefs';



const adminUsersDataService: Middleware = store => next => (requestedAction: AdminUsersAction) => {
    next(requestedAction);

    const client = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ADD_USER:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.addUser(requestedAction.payload as AddUserRequest)
                .then((newUser: UserInformation) => {
                    store.dispatch(action(ADD_USER_SUCCESS, newUser));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_USER_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ENUMERATE_USERS:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            const enumerateRequest: EnumerateUsersRequest = requestedAction.payload as EnumerateUsersRequest;

            client.enumerateUsers(enumerateRequest.search, enumerateRequest.pageSize, enumerateRequest.page, enumerateRequest.sort, enumerateRequest.order)
                .then((users: UserList) => {
                    store.dispatch(action(ENUMERATE_USERS_SUCCESS, users));
                })
                .catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_USERS_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case UPDATE_USER:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            const updateUserRequestWithId: UpdateUserRequestWithId = requestedAction.payload as UpdateUserRequestWithId;

            client.updateUser(updateUserRequestWithId.id, updateUserRequestWithId.updateUserRequest)
                .then(() => {
                    //we want to update our state, so make new UserInformation from UpdateUserRequest
                    const updatedUser: UserInformation = UserInformation.fromJS({
                        id: updateUserRequestWithId.id,
                        enabled: updateUserRequestWithId.updateUserRequest.enabled,
                        loginEmail: updateUserRequestWithId.updateUserRequest.loginEmail,
                        loginPhone: updateUserRequestWithId.updateUserRequest.loginPhone,
                        externalId: updateUserRequestWithId.updateUserRequest.externalId,
                        givenName: updateUserRequestWithId.updateUserRequest.givenName,
                        surname: updateUserRequestWithId.updateUserRequest.surname,
                        emailVerified: updateUserRequestWithId.updateUserRequest.emailVerified,
                        dateCreated: null,
                        requiresTwoFactorAuthentication: null
                    });
                    store.dispatch(action(UPDATE_USER_SUCCESS, updatedUser));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(UPDATE_USER_ERROR, { id: updateUserRequestWithId.id, reason: reason } as AdminUsersError));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case REMOVE_USER:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.removeUser(requestedAction.payload as string)
                .then(() => {
                    store.dispatch(action(REMOVE_USER_SUCCESS, requestedAction.payload));
                })
                .catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(REMOVE_USER_ERROR, { id: requestedAction.payload, reason: reason } as AdminUsersError));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminUsersDataService;