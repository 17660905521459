import { Middleware } from 'redux';

import {
    ResourceSecretInformation
} from '../../../../../../shared/utils/DataAccess';
import {
    ENUMERATE_RESOURCE_SECRETS, ENUMERATE_RESOURCE_SECRETS_SUCCESS, ENUMERATE_RESOURCE_SECRETS_ERROR,
    ADD_RESOURCE_SECRET, ADD_RESOURCE_SECRET_SUCCESS, ADD_RESOURCE_SECRET_ERROR,
    DELETE_RESOURCE_SECRET, DELETE_RESOURCE_SECRET_SUCCESS, DELETE_RESOURCE_SECRET_ERROR, UPDATE_RESOURCE_SECRET_SUCCESS, UPDATE_RESOURCE_SECRET
} from './actionDef';
import { AdminResourceSecretsAction, AddSecretRequestWithResourceId, DeleteSecretRequest, UpdateResourceSecretRequestWithResourceId } from './actions';
import APIClientFactory from '../../../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../../../shared/spinner/redux/reducer';



const adminResourceSecretsDataService: Middleware = store => next => (requestedAction: AdminResourceSecretsAction) => {
    next(requestedAction);

    const resource = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ENUMERATE_RESOURCE_SECRETS:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));
            resource.enumerateResourceSecrets(requestedAction.payload as number)
                .then((Claims: ResourceSecretInformation[]) => {
                    store.dispatch(action(ENUMERATE_RESOURCE_SECRETS_SUCCESS, Claims));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_RESOURCE_SECRETS_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ADD_RESOURCE_SECRET:
            const addWithID: AddSecretRequestWithResourceId = (requestedAction.payload as AddSecretRequestWithResourceId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            resource.addResourceSecret(addWithID.resourceId, addWithID.request)
                .then((newGrantTypeInformation: ResourceSecretInformation) => {
                    store.dispatch(action(ADD_RESOURCE_SECRET_SUCCESS, newGrantTypeInformation));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_RESOURCE_SECRET_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case UPDATE_RESOURCE_SECRET:
            const updateWithId: UpdateResourceSecretRequestWithResourceId = (requestedAction.payload as UpdateResourceSecretRequestWithResourceId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            resource.updateResourceSecret(updateWithId.resourceId, updateWithId.id, updateWithId.request)
                .then(() => {
                    //we want to update our state, so make new UserInformation from UpdateUserRequest
                    const updatedRecord: ResourceSecretInformation = ResourceSecretInformation.fromJS({
                        id: updateWithId.id,
                        description: updateWithId.request.description,
                        expiration: updateWithId.request.expiration,
                        type: updateWithId.request.type,
                    });
                    store.dispatch(action(UPDATE_RESOURCE_SECRET_SUCCESS, updatedRecord));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_RESOURCE_SECRET_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;

        case DELETE_RESOURCE_SECRET:
            const deleteWithID: DeleteSecretRequest = (requestedAction.payload as DeleteSecretRequest);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            resource.removeResourceSecret(deleteWithID.resourceId, deleteWithID.propertyId)
                .then(() => {
                    store.dispatch(action(DELETE_RESOURCE_SECRET_SUCCESS, deleteWithID.propertyId));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(DELETE_RESOURCE_SECRET_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminResourceSecretsDataService;