import { AdminUsersAction, EnumerateUsersRequest, UpdateUserRequestWithId, ChangeEditState, AdminUsersError } from "./actions";
import { UserList, UserInformation, Sort, Order, UpdateUserRequest } from "../../../../shared/utils/DataAccess";
import {
    ENUMERATE_USERS_ERROR, ENUMERATE_USERS_SUCCESS,
    ADD_USER, ADD_USER_ERROR, ADD_USER_SUCCESS,
    UPDATE_USER, UPDATE_USER_ERROR, UPDATE_USER_SUCCESS,
    REMOVE_USER, REMOVE_USER_ERROR, REMOVE_USER_SUCCESS,
    DISPLAY_STATE_CHANGE
} from "./actionDef";
import _ from "lodash";
import { DisplayState } from "../../../../shared/FullTable/components/FullTableRow";
import { store } from "../../../../shared/redux/store";
import { action as newAction } from 'typesafe-actions';


export interface PaginationState {
    totalRecords: number,
    enumerateUsersRequest: EnumerateUsersRequest
}


export interface UserInformationDisplay extends UserInformation {
    displayState: DisplayState
}


export interface UpdateUserRequestDisplay extends UpdateUserRequest {
    displayState: DisplayState
}


export interface AdminUserListState {
    list: {
        byId: {
            [id: string]: UserInformationDisplay
        };
        allIds: string[];
    };
    paginationState: PaginationState
};


export const initialState: AdminUserListState = {
    list: {
        byId: {},
        allIds: []
    },
    paginationState: {
        totalRecords: 0,
        enumerateUsersRequest: {
            search: undefined,
            page: 0,
            pageSize: 10,
            sort: Sort.GivenName,
            order: Order.Asc
        }
    }
};


export function adminUsersReducer(state: AdminUserListState = initialState, action: AdminUsersAction): AdminUserListState {
    switch (action.type) {
        case DISPLAY_STATE_CHANGE:
            const stateChange = action.payload as ChangeEditState;

            return {
                ...state,
                list: {
                    ...state.list,
                    byId: {
                        ...state.list.byId,
                        [stateChange.id]: {
                            ...state.list.byId[stateChange.id],
                            displayState: stateChange.displayState
                        } as UserInformationDisplay
                    }
                }
            };

        /*
            these are fired when an API begins a request
        */
        case ADD_USER:
        case UPDATE_USER:
            const userAddUpdate = action.payload as UpdateUserRequestWithId;

            return {
                ...state,
                list: {
                    ...state.list,
                    byId: {
                        ...state.list.byId,
                        [userAddUpdate.id]: {
                            ...state.list.byId[userAddUpdate.id],
                            displayState: DisplayState.Saving
                        } as UserInformationDisplay
                    }
                }
            };
        case REMOVE_USER:
            return {
                ...state,
                list: {
                    ...state.list,
                    byId: {
                        ...state.list.byId,
                        [action.payload as string]: {
                            ...state.list.byId[action.payload as string],
                            displayState: DisplayState.Saving
                        } as UserInformationDisplay
                    }
                }
            };
        /*
                these are fired when an API return success
        */
        case ENUMERATE_USERS_SUCCESS:
            const userList = action.payload as UserList;
            //convert to display object
            var users: UserInformationDisplay[] = [];
            userList.users.forEach(user => {
                users.push({
                    ...user,
                    displayState: DisplayState.ReadOnly
                } as UserInformationDisplay);
            });

            return {
                ...state,
                list: {
                    byId: {
                        ..._.keyBy(users, "id")
                    },
                    allIds: _.map(users, "id")
                },
                paginationState: {
                    ...state.paginationState,
                    totalRecords: userList.meta.paging.totalRecords,
                    enumerateUsersRequest: {
                        ...state.paginationState.enumerateUsersRequest,
                        search: userList.meta.search === "" ? undefined : userList.meta.search,
                        sort: userList.meta.sort as unknown as Sort,
                        order: userList.meta.order as unknown as Order,
                        page: userList.meta.paging.page,
                        pageSize: userList.meta.paging.pageSize
                    }
                }
            };
        case ADD_USER_SUCCESS:
        case UPDATE_USER_SUCCESS:
            const user: UserInformationDisplay = {
                ...(action.payload as UserInformation),
                displayState: DisplayState.Saved
            } as UserInformationDisplay;

            var addUpdateAllIds = [...state.list.allIds];
            const idIndex2 = addUpdateAllIds.findIndex(el => el === user.id);

            if (idIndex2 === -1) {
                addUpdateAllIds = addUpdateAllIds.concat(user.id);
            } else {
                //here we have to 'hack' the information back in.  update user doesn't return any information
                const oldUser = state.list.byId[user.id] as UserInformation;
                user.dateCreated = oldUser.dateCreated;
                user.requiresTwoFactorAuthentication = oldUser.requiresTwoFactorAuthentication;
            }

            setTimeout(function () {
                store.dispatch(newAction(DISPLAY_STATE_CHANGE, { id: user.id, displayState: DisplayState.ReadOnly } as ChangeEditState));
            }, 2000);

            return {
                ...state,
                list: {
                    byId: {
                        ...state.list.byId,
                        [user.id]: user
                    },
                    allIds: addUpdateAllIds
                }
            };
        case REMOVE_USER_SUCCESS:
            const removeAllIds = [...state.list.allIds];

            const newByids = { ...state.list.byId };
            newByids[action.payload as string].enabled = false;

            return {
                ...state,
                list: {
                    byId: {
                        ...newByids
                    },
                    allIds: removeAllIds
                }
            };

        /*
                these are fired when an API return failure
        */
        case ENUMERATE_USERS_ERROR:
        case ADD_USER_ERROR:
            return { ...state };
        case UPDATE_USER_ERROR:
        case REMOVE_USER_ERROR:
            var apiError: AdminUsersError = action.payload as AdminUsersError;

            return {
                ...state,
                list: {
                    ...state.list,
                    byId: {
                        ...state.list.byId,
                        [apiError.id]: {
                            ...state.list.byId[apiError.id],
                            displayState: DisplayState.Error
                        } as UserInformationDisplay
                    }
                }
            };
        default:
            return state;
    }
}