import { Middleware } from 'redux';

import {
    AppPasswordInformation, NewAppPasswordInformation
} from '../../../shared/utils/DataAccess';
import {
    ADD_APP_PASSWORD, ADD_APP_PASSWORD_SUCCESS, ADD_APP_PASSWORD_ERROR,
    ENUMERATE_APP_PASSWORDS, ENUMERATE_APP_PASSWORDS_SUCCESS, ENUMERATE_APP_PASSWORDS_ERROR,
    REMOVE_APP_PASSWORD, REMOVE_APP_PASSWORD_SUCCESS, REMOVE_APP_PASSWORD_ERROR
} from './actionDef';
import { AppPasswordsAction } from './actions';
import APIClientFactory from '../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../shared/spinner/redux/reducer';



const appPasswordDataService: Middleware = store => next => (requestedAction: AppPasswordsAction) => {
    next(requestedAction);

    const client = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ADD_APP_PASSWORD:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.addAppPassword(requestedAction.payload as NewAppPasswordInformation)
                .then((newAppPassword: NewAppPasswordInformation) => {
                    store.dispatch(action(ADD_APP_PASSWORD_SUCCESS, newAppPassword));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_APP_PASSWORD_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ENUMERATE_APP_PASSWORDS:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.enumerateAppPasswords()
                .then((appPasswords: AppPasswordInformation[]) => {
                    store.dispatch(action(ENUMERATE_APP_PASSWORDS_SUCCESS, appPasswords));
                })
                .catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_APP_PASSWORDS_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case REMOVE_APP_PASSWORD:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.removeAppPassword(requestedAction.payload as string)
                .then(() => {
                    store.dispatch(action(REMOVE_APP_PASSWORD_SUCCESS, requestedAction.payload));
                })
                .catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(REMOVE_APP_PASSWORD_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default appPasswordDataService;