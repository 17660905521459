import React, { FunctionComponent, useState } from 'react';
import { store, StoreState } from '../../redux/store';
import userManager from '../../utils/UserManager';


const REDIRECT_PATH: RegExp = /redirect/;


interface StateProps {
    children: React.ReactNode
}


export type Props = StateProps;


const AuthenticatedComponent: FunctionComponent<Props> = (props: Props) => {


    const [isSigningIn, setisSigningIn] = useState<boolean>(false);


    const IsUserAuthenticated = (): boolean => {
        const state: StoreState = store.getState() as StoreState;
        const isOnRedirect = (state.router.location.pathname.match(REDIRECT_PATH) !== null);

        if (!isOnRedirect && !state.userReducer.user && !isSigningIn) {
            setisSigningIn(true);

            userManager.signinRedirect({ state: { redirectPath: window.location.pathname } })
            return false;
        }

        return true;
    }


    const isOnRedirect = window.location.pathname.match(REDIRECT_PATH);
    const isAuthenticated: boolean = IsUserAuthenticated();

    return <>{(isOnRedirect || isAuthenticated) && !isSigningIn ? props.children : null}</>;


}


export default AuthenticatedComponent