import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddAdministratorRequest, Sort, UserInformation } from '../../../../shared/utils/DataAccess';
import { StoreState } from '../../../../shared/redux/store';

import { enumerateAdministrators, addAdministrator, removeAdministrator } from '../redux/actions';
import { AdminAdministratorsListState } from '../redux/reducer';
import AdminAdministratorComponent from './AdminAdministratorComponent';
import AdminAddAdministratorComponent from './AdminAddAdministratorComponent';


import Button from '@material-ui/core/Button';
import FullTableComponent, { DisplayType, Column, EnumerationRequest } from '../../../../shared/FullTable/components/FullTableComponent';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';


const AdminAdministratorsPage: FunctionComponent = () => {
    const dispatch = useDispatch();


    const [isDialogOpen, setisDialogOpen] = useState<boolean>(false);


    const administratorsListState: AdminAdministratorsListState = useSelector((state: StoreState) => {
        return state.adminAdministratorsReducer
    });


    const Columns: Column[] = [
        { name: Sort.GivenName, displayName: "Name", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: Sort.LoginEmail, displayName: "Login Email", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Edit", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
    ];


    useEffect(() => {
        dispatch(enumerateAdministrators());
    }, []);



    const apiEnumerate = (request: EnumerationRequest) => {
        dispatch(enumerateAdministrators());
    };


    const onOpenAddAdministratorDialog = () => {
        setisDialogOpen(true);
    }

    const onCloseAddAdministratorDialog = () => {
        setisDialogOpen(false);
    }


    const onAddAdministrator = (user: UserInformation) => {
        const request: AddAdministratorRequest = AddAdministratorRequest.fromJS({
            userId: user.id
        });

        dispatch(addAdministrator(request));

        setisDialogOpen(false);
    }


    const onDeleteAdministrator = (id: string) => {
        dispatch(removeAdministrator(id));

        setisDialogOpen(false);

        dispatch(enumerateAdministrators());
    }


    const classes = makeStyles({
        page: {
            width: '90%',
            margin: '80px auto 0px',
            padding: '20px'
        },
    })();


    return (
        <Paper className={classes.page}>

            <h1>Administrators</h1>
            Below is a list of all auth administrators.

            <FullTableComponent Columns={Columns} apiEnumerate={apiEnumerate}>
                {administratorsListState ? administratorsListState.list.allIds.map(id => (
                    <AdminAdministratorComponent
                        key={id}
                        administratorInformation={administratorsListState.list.byId[id]}
                        onDelete={onDeleteAdministrator}
                    ></AdminAdministratorComponent>
                )) : ""}
            </FullTableComponent>
            <div style={{ textAlign: 'center', marginTop: '20px' }}><Button variant="outlined" onClick={onOpenAddAdministratorDialog}>Add Administrator</Button></div>
            <AdminAddAdministratorComponent visible={isDialogOpen} onAddAdministrator={onAddAdministrator} onClose={onCloseAddAdministratorDialog}></AdminAddAdministratorComponent>
        </Paper>
    );


}


export default AdminAdministratorsPage;