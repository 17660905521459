import { store } from "../redux/store";


class BaseClient {


    protected transformOptions(options: RequestInit) {
        const state = store.getState() as any;
        const token = (state.userReducer && state.userReducer.user && state.userReducer.user.access_token) || "";

        options.headers = {
            ...options.headers,
            ["Authorization"]: `Bearer ${token}`,
            ["Access-Control-Max-Age"]: '1728000'
        }
        return Promise.resolve(options);
    }


}


export default BaseClient;