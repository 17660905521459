import { push } from 'connected-react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { CallbackComponent } from 'redux-oidc';

import userManager from '../../../shared/utils/UserManager';
import { User } from 'oidc-client';
import { loadProfile } from '../../../Menu/redux/actions';


interface OwnProps { }
interface StateProps { }
interface State { }


interface DispatchProps {
  navigateTo: (path: string, state?: any) => void;
}


const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    navigateTo: (path: string, state?: any) => {
      dispatch(loadProfile());
      dispatch(push(path));
    }
  }
}


export type Props = OwnProps & StateProps & DispatchProps;


class RedirectPage extends Component<Props, State>{


  success = (user: User) => {
    this.props.navigateTo(user.state.redirectPath as string);
  }


  render() {
    return (
      <>
        <CallbackComponent
          userManager={userManager}
          successCallback={this.success}
          errorCallback={error => { console.error(error) }}
        >
          <div>Redirecting...</div>
        </CallbackComponent>
      </>
    );
  }


}


export default connect(null, mapDispatchToProps)(RedirectPage);