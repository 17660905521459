import { AdminClientAllowedCorsOriginsAction } from "./actions";
import { AllowedCorsOrigin } from "../../../../../../shared/utils/DataAccess";
import {
    ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_SUCCESS, ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_ERROR,
    ADD_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS, ADD_CLIENT_ALLOWED_CORS_ORIGIN_ERROR,
    DELETE_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS, DELETE_CLIENT_ALLOWED_CORS_ORIGIN_ERROR,
} from "./actionDef";
import _ from "lodash";


export interface ClientAllowedCorsOriginsState {
    list: {
        byId: {
            [id: number]: AllowedCorsOrigin
        };
        allIds: number[];
    };
    newAllowedCorsOrigin?: AllowedCorsOrigin;
};


export const initialState: ClientAllowedCorsOriginsState = {
    list: {
        byId: {},
        allIds: []
    },
    newAllowedCorsOrigin: undefined
};


export function adminClientAllowedCorsOriginsReducer(state: ClientAllowedCorsOriginsState = initialState, action: AdminClientAllowedCorsOriginsAction): ClientAllowedCorsOriginsState {
    switch (action.type) {
        case ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_SUCCESS:
            const corsOrigins = action.payload as AllowedCorsOrigin[];
            return {
                ...state,
                list: {
                    byId: {
                        ..._.keyBy(corsOrigins, "id")
                    },
                    allIds: _.map(corsOrigins, "id")
                }
            };
        case ADD_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS:
            const newCorsOrigin = action.payload as AllowedCorsOrigin;
            return {
                ...state,
                list: {
                    byId: {
                        ...state.list.byId,
                        [newCorsOrigin.id]: newCorsOrigin
                    },
                    allIds: state.list.allIds.concat(newCorsOrigin.id)
                },
                newAllowedCorsOrigin: newCorsOrigin
            };
        case DELETE_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS:
            const newAllIds = [...state.list.allIds];
            const idIndex = newAllIds.findIndex(el => el === action.payload as number);
            newAllIds.splice(idIndex, 1);

            const newByids = { ...state.list.byId };
            delete newByids[action.payload as number];

            return {
                ...state,
                list: {
                    byId: {
                        ...newByids
                    },
                    allIds: newAllIds
                }
            };
        case ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_ERROR:
        case ADD_CLIENT_ALLOWED_CORS_ORIGIN_ERROR:
        case DELETE_CLIENT_ALLOWED_CORS_ORIGIN_ERROR:
        default:
            return state;
    }
}