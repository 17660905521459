import { Middleware } from 'redux';

import {
    ResourceClaimInformation
} from '../../../../../../shared/utils/DataAccess';
import {
    ENUMERATE_RESOURCE_CLAIMS, ENUMERATE_RESOURCE_CLAIMS_SUCCESS, ENUMERATE_RESOURCE_CLAIMS_ERROR,
    ADD_RESOURCE_CLAIM, ADD_RESOURCE_CLAIM_SUCCESS, ADD_RESOURCE_CLAIM_ERROR,
    DELETE_RESOURCE_CLAIM, DELETE_RESOURCE_CLAIM_SUCCESS, DELETE_RESOURCE_CLAIM_ERROR
} from './actionDef';
import { AdminResourceClaimsAction, AddClaimRequestWithResourceId, DeleteClaimRequest } from './actions';
import APIClientFactory from '../../../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../../../shared/spinner/redux/reducer';



const adminResourceClaimsDataService: Middleware = store => next => (requestedAction: AdminResourceClaimsAction) => {
    next(requestedAction);

    const client = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ENUMERATE_RESOURCE_CLAIMS:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));
            client.enumerateResourceClaims(requestedAction.payload as number)
                .then((Claims: ResourceClaimInformation[]) => {
                    store.dispatch(action(ENUMERATE_RESOURCE_CLAIMS_SUCCESS, Claims));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_RESOURCE_CLAIMS_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ADD_RESOURCE_CLAIM:
            const addWithID: AddClaimRequestWithResourceId = (requestedAction.payload as AddClaimRequestWithResourceId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.addResourceClaim(addWithID.resourceId, addWithID.request)
                .then((newGrantTypeInformation: ResourceClaimInformation) => {
                    store.dispatch(action(ADD_RESOURCE_CLAIM_SUCCESS, newGrantTypeInformation));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_RESOURCE_CLAIM_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;

        case DELETE_RESOURCE_CLAIM:
            const deleteWithID: DeleteClaimRequest = (requestedAction.payload as DeleteClaimRequest);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.removeResourceClaim(deleteWithID.resourceId, deleteWithID.claimId)
                .then(() => {
                    store.dispatch(action(DELETE_RESOURCE_CLAIM_SUCCESS, deleteWithID.claimId));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(DELETE_RESOURCE_CLAIM_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminResourceClaimsDataService;