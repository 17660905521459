import { Middleware } from 'redux';

import {
    ClientConfigInformation, ConfigCreateDto
} from '../../../../shared/utils/DataAccess';
import {
    ENUMERATE_CLIENTS, ENUMERATE_CLIENTS_SUCCESS, ENUMERATE_CLIENTS_ERROR,
    ADD_CLIENT, ADD_CLIENT_SUCCESS, ADD_CLIENT_ERROR,
    REMOVE_CLIENT, REMOVE_CLIENT_SUCCESS, REMOVE_CLIENT_ERROR
} from './actionDef';
import { AdminClientsAction } from './actions';
import APIClientFactory from '../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../shared/spinner/redux/reducer';



const adminClientsDataService: Middleware = store => next => (requestedAction: AdminClientsAction) => {
    next(requestedAction);

    const client = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ENUMERATE_CLIENTS:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.enumerateConfigs()
                .then((clients: ClientConfigInformation[]) => {
                    store.dispatch(action(ENUMERATE_CLIENTS_SUCCESS, clients));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_CLIENTS_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ADD_CLIENT:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.addClientConfig(requestedAction.payload as ConfigCreateDto)
                .then((newClient: ClientConfigInformation) => {
                    store.dispatch(action(ADD_CLIENT_SUCCESS, newClient));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_CLIENT_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case REMOVE_CLIENT:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.removeClientConfig(requestedAction.payload as number)
                .then(() => {
                    store.dispatch(action(REMOVE_CLIENT_SUCCESS, requestedAction.payload));
                })
                .catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(REMOVE_CLIENT_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminClientsDataService;