export const ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS = "ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS";
export type ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS = typeof ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS;
export const ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS_SUCCESS = "ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS_SUCCESS";
export type ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS_SUCCESS = typeof ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS_SUCCESS;
export const ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS_ERROR = "ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS_ERROR";
export type ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS_ERROR = typeof ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS_ERROR;

export const ADD_CLIENT_POST_LOGOUT_REDIRECT_URI = "ADD_CLIENT_POST_LOGOUT_REDIRECT_URI";
export type ADD_CLIENT_POST_LOGOUT_REDIRECT_URI = typeof ADD_CLIENT_POST_LOGOUT_REDIRECT_URI;
export const ADD_CLIENT_POST_LOGOUT_REDIRECT_URI_SUCCESS = "ADD_CLIENT_POST_LOGOUT_REDIRECT_URI_SUCCESS";
export type ADD_CLIENT_POST_LOGOUT_REDIRECT_URI_SUCCESS = typeof ADD_CLIENT_POST_LOGOUT_REDIRECT_URI_SUCCESS;
export const ADD_CLIENT_POST_LOGOUT_REDIRECT_URI_ERROR = "ADD_CLIENT_POST_LOGOUT_REDIRECT_URI_ERROR";
export type ADD_CLIENT_POST_LOGOUT_REDIRECT_URI_ERROR = typeof ADD_CLIENT_POST_LOGOUT_REDIRECT_URI_ERROR;

export const DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI = "DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI";
export type DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI = typeof DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI;
export const DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI_SUCCESS = "DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI_SUCCESS";
export type DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI_SUCCESS = typeof DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI_SUCCESS;
export const DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI_ERROR = "DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI_ERROR";
export type DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI_ERROR = typeof DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI_ERROR;
