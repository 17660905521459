import { AdminAdministratorsAction } from "./actions";
import { AdministratorInformation } from "../../../../shared/utils/DataAccess";
import {
    ENUMERATE_ADMINISTRATORS_SUCCESS, ENUMERATE_ADMINISTRATORS_ERROR,
    ADD_ADMINISTRATOR_SUCCESS, ADD_ADMINISTRATOR_ERROR,
    REMOVE_ADMINISTRATOR_SUCCESS, REMOVE_ADMINISTRATOR_ERROR
} from "./actionDef";
import _ from "lodash";


export interface AdminAdministratorsListState {
    list: {
        byId: {
            [id: string]: AdministratorInformation
        };
        allIds: string[];
    }
};


export const initialState: AdminAdministratorsListState = {
    list: {
        byId: {},
        allIds: []
    }
};


export function adminAdministratorsReducer(state: AdminAdministratorsListState = initialState, action: AdminAdministratorsAction): AdminAdministratorsListState {
    switch (action.type) {
        // case ADD_ADMINISTRATOR:
        //     const administratorAdd = action.payload as AdministratorInformation;

        //     return {
        //         ...state,
        //         list: {
        //             ...state.list,
        //             byId: {
        //                 ...state.list.byId,
        //                 [administratorAdd.id]: {
        //                     ...state.list.byId[administratorAdd.id]
        //                 } as AdministratorInformation
        //             }
        //         }
        //     };
        // case REMOVE_ADMINISTRATOR:
        //     return {
        //         ...state,
        //         list: {
        //             ...state.list,
        //             byId: {
        //                 ...state.list.byId,
        //                 [action.payload as string]: {
        //                     ...state.list.byId[action.payload as string]
        //                 } as AdministratorInformation
        //             }
        //         }
        //     };
        /*
                these are fired when an API return success
        */
        case ENUMERATE_ADMINISTRATORS_SUCCESS:
            const users = action.payload as AdministratorInformation[];

            return {
                ...state,
                list: {
                    byId: {
                        ..._.keyBy(users, "id")
                    },
                    allIds: _.map(users, "id")
                }
            };
        case ADD_ADMINISTRATOR_SUCCESS:
            const result = action.payload as AdministratorInformation;

            var addUpdateAllIds = [...state.list.allIds];
            const idIndex2 = addUpdateAllIds.findIndex(el => el === result.id);

            if (idIndex2 === -1) {
                addUpdateAllIds = addUpdateAllIds.concat(result.id);
            }

            return {
                ...state,
                list: {
                    byId: {
                        ...state.list.byId,
                        [result.id]: result
                    },
                    allIds: addUpdateAllIds
                }
            };
        case REMOVE_ADMINISTRATOR_SUCCESS:
            const newAllIds = [...state.list.allIds];
            const idIndex = newAllIds.findIndex(el => el === action.payload as string);
            newAllIds.splice(idIndex, 1);

            const newByids = { ...state.list.byId };
            delete newByids[action.payload as string];

            return {
                ...state,
                list: {
                    byId: {
                        ...newByids
                    },
                    allIds: newAllIds
                }
            };

        /*
                these are fired when an API return failure
        */
        case ENUMERATE_ADMINISTRATORS_ERROR:
        case ADD_ADMINISTRATOR_ERROR:
        case REMOVE_ADMINISTRATOR_ERROR:
            return { ...state };
        default:
            return state;
    }
}