import React, { FunctionComponent } from 'react';
import { TableCell } from '@material-ui/core';
import FullTableRow from '../../../../../../shared/FullTable/components/FullTableRow';
import { ResourceClaimInformationDisplay } from '../redux/reducer';


type Props = {
    claim: ResourceClaimInformationDisplay,
    onDelete(id: number): void
}


const ResourceClaimComponent: FunctionComponent<Props> = (props: Props) => {


    const { claim } = props;


    const onDelete = () => {
        props.onDelete(props.claim.id);
    }


    return (
        <FullTableRow
            displayState={claim.displayState}
            canDelete={true}
            onDelete={onDelete} >
            <TableCell component="th" scope="row">{claim.type}</TableCell>
        </FullTableRow>
    );


}


export default ResourceClaimComponent;