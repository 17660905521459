import { AdminClientsAction } from "./actions";
import { UserInformation, UpdateUserRequest, ClientConfigInformation } from "../../../../shared/utils/DataAccess";
import {
    ENUMERATE_CLIENTS_SUCCESS, REMOVE_CLIENT_SUCCESS
} from "./actionDef";
import _ from "lodash";
import { DisplayState } from "../../../../shared/FullTable/components/FullTableRow";


export interface ClientInformationDisplay extends UserInformation {
    displayState: DisplayState
}


export interface UpdateClientRequestDisplay extends UpdateUserRequest {
    displayState: DisplayState
}


export interface AdminClientListState {
    list: {
        byId: {
            [id: number]: ClientConfigInformation
        };
        allIds: number[];
    }
};


export const initialState: AdminClientListState = {
    list: {
        byId: {},
        allIds: []
    }
};


export function adminClientsReducer(state: AdminClientListState = initialState, action: AdminClientsAction): AdminClientListState {
    switch (action.type) {
        /*
                these are fired when an API return success
        */
        case ENUMERATE_CLIENTS_SUCCESS:
            //convert to display object
            const users = action.payload as ClientConfigInformation[];

            return {
                ...state,
                list: {
                    byId: {
                        ..._.keyBy(users, "id")
                    },
                    allIds: _.map(users, "id")
                }
            };
        // case ADD_USER_SUCCESS:
        //     const user: UserInformationDisplay = {
        //         ...(action.payload as UserInformation),
        //         displayState: DisplayState.Saved
        //     } as UserInformationDisplay;

        //     var addUpdateAllIds = [...state.list.allIds];
        //     const idIndex2 = addUpdateAllIds.findIndex(el => el === user.id);

        //     if (idIndex2 === -1) {
        //         addUpdateAllIds = addUpdateAllIds.concat(user.id);
        //     } else {
        //         //here we have to 'hack' the information back in.  update user doesn't return any information
        //         const oldUser = state.list.byId[user.id] as UserInformation;
        //         user.dateCreated = oldUser.dateCreated;
        //         user.requiresTwoFactorAuthentication = oldUser.requiresTwoFactorAuthentication;
        //     }
        //     return {
        //         ...state,
        //         list: {
        //             byId: {
        //                 ...state.list.byId,
        //                 [user.id]: user
        //             },
        //             allIds: addUpdateAllIds
        //         }
        //     };
        case REMOVE_CLIENT_SUCCESS:
            const newAllIds = [...state.list.allIds];
            const idIndex = newAllIds.findIndex(el => el === action.payload as number);
            newAllIds.splice(idIndex, 1);

            const newByids = { ...state.list.byId };
            delete newByids[action.payload as number];

            return {
                ...state,
                list: {
                    byId: {
                        ...newByids
                    },
                    allIds: newAllIds
                }
            };
        default:
            return state;
    }
}