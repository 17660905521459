import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { TextField, Button } from '@material-ui/core';
import { ResourceInformation, ResourceSecretInformation, AddResourceSecretRequest, UpdateResourceSecretRequest } from '../../../../../../shared/utils/DataAccess';
import { StoreState } from '../../../../../../shared/redux/store';
import FullTableComponent, { DisplayType, Column } from '../../../../../../shared/FullTable/components/FullTableComponent';
import ResourceSecretComponent from './ResourceSecretComponent';
import { AdminResourceSecretsState } from '../redux/reducer';
import { loadResourceSecrets, deleteResourceSecret, addResourceSecret, editStart, editChanges, editCancel, updateResourceSecret } from '../redux/actions';


type Props = {
    resourceInformation: ResourceInformation
}


const ResourceSecretsComponent: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();
    const today: Date = new Date();
    const defaultExpiration: Date = new Date((new Date()).setDate(today.getDate() + 90));

    const [addResourceSecretRequest, setResourceSecretRequest] = useState<AddResourceSecretRequest>(AddResourceSecretRequest.fromJS({
        description: "",
        type: "",
        expiration: defaultExpiration
    }));


    const clientSecretsState: AdminResourceSecretsState = useSelector((state: StoreState) => {
        return state.adminResourceSecretsReducer;
    });


    const apiEnumerate = () => {
        if (props.resourceInformation.id) {
            dispatch(loadResourceSecrets(props.resourceInformation.id));
        }
    };


    const onEditStart = (id: number) => {
        dispatch(editStart(id));
    }


    const onEditChanges = (id: number, hasChanges: boolean) => {
        dispatch(editChanges(id, hasChanges));
    }


    const onEditCancel = (id: number) => {
        dispatch(editCancel(id));
    }


    const onUpdateSecret = (id: number, request: UpdateResourceSecretRequest) => {
        dispatch(updateResourceSecret(props.resourceInformation.id, id, request));
    }


    const onDelete = (request: number) => {
        dispatch(deleteResourceSecret(props.resourceInformation.id, request));
    };


    useEffect(() => {
        apiEnumerate();
    }, [props.resourceInformation]);


    const hasChanges = (): boolean => {
        // && addResourceSecretRequest.expiration > 0
        return (addResourceSecretRequest.description.length > 0 && addResourceSecretRequest.type.length > 0);
    }


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newRequest: ResourceSecretInformation = ResourceSecretInformation.fromJS({
            ...addResourceSecretRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setResourceSecretRequest(newRequest);
    }


    const onSubmit = () => {
        dispatch(addResourceSecret(props.resourceInformation.id, addResourceSecretRequest));

        setResourceSecretRequest(AddResourceSecretRequest.fromJS({
            description: "",
            type: "",
            expiration: defaultExpiration
        }));
    }


    const Columns: Column[] = [
        { name: "Edit", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Type", displayName: "Type", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Description", displayName: "Description", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Expiration", displayName: "Expiration", sortable: false, displayType: DisplayType.Always, textAlign: "right" },
        { name: "Delete", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
    ];


    const classes = makeStyles({
        row: {
            maxWidth: "500px"
        }
    })();


    return (
        <>
            <div className={classes.row}>
                <FullTableComponent Columns={Columns} apiEnumerate={apiEnumerate}>
                    {clientSecretsState ? clientSecretsState.list.allIds.map(id => (
                        <ResourceSecretComponent
                            key={id}
                            property={clientSecretsState.list.byId[id]}
                            onUpdateSecret={onUpdateSecret}
                            onEditStart={onEditStart}
                            onEditChanges={onEditChanges}
                            onEditCancel={onEditCancel}
                            onDelete={onDelete}
                        ></ResourceSecretComponent>
                    )) : ""}
                </FullTableComponent>
            </div>
            <div className={classes.row} style={{ marginTop: '50px' }}>
                <TextField
                    id="type"
                    label="New Secret Type"
                    fullWidth={true}
                    value={addResourceSecretRequest.type}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    id="description"
                    label="Description"
                    fullWidth={true}
                    value={addResourceSecretRequest.description}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    id="expiration"
                    label="Expiration"
                    fullWidth={true}
                    disabled={true}
                    value={addResourceSecretRequest.expiration.toLocaleDateString()}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row} style={{ textAlign: 'right' }}>
                <Button variant="outlined" color="primary" onClick={onSubmit} disabled={!hasChanges()}>Add Secret</Button>
            </div>
        </>
    );


}


export default ResourceSecretsComponent;