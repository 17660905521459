import React, { FunctionComponent } from 'react';
import { TablePagination } from '@material-ui/core';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';


export interface PagingInformation {
    pageSizes: number[];
    pageSize: number | undefined;
    page: number | undefined;
    totalRecords: number;
}


interface StateProps {
    pagingInformation: PagingInformation,
}


interface DispatchProps {
    onPagingChanged: (request: PagingInformation) => void;
}


export type Props = StateProps & DispatchProps;


const TablePaginationComponent: FunctionComponent<Props> = (props: Props) => {


    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number,
    ) => {
        var { pagingInformation } = props;
        pagingInformation.page = newPage;

        props.onPagingChanged(pagingInformation);
    }


    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        var { pagingInformation } = props;
        pagingInformation.page = 0;
        pagingInformation.pageSize = parseInt(event.target.value, 10);

        props.onPagingChanged(pagingInformation);
    }


    const { pagingInformation: pagination } = props;

    return (
        <TablePagination
            rowsPerPageOptions={pagination.pageSizes}
            count={pagination.totalRecords}
            rowsPerPage={pagination.pageSize ? pagination.pageSize : 10}
            page={pagination.page ? pagination.page : 0}
            SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
        />
    );


}


export default TablePaginationComponent;