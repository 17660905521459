import { AdminResourceAction, UpdateResourceRequestWithId } from "./actions";
import { ResourceInformation } from "../../../../../../shared/utils/DataAccess";
import {
    LOAD_RESOURCE_SUCCESS, UPDATE_RESOURCE_SUCCESS,
} from "./actionDef";


export interface AdminResourceState {
    resourceInformation: ResourceInformation
};


export const initialState: AdminResourceState = {
    resourceInformation: ResourceInformation.fromJS({})
};


export function adminResourceReducer(state: AdminResourceState = initialState, action: AdminResourceAction): AdminResourceState {
    switch (action.type) {
        case LOAD_RESOURCE_SUCCESS:
            //convert to display object
            const resourceInformation: ResourceInformation = action.payload as ResourceInformation;

            return {
                ...state,
                resourceInformation: resourceInformation
            };
        case UPDATE_RESOURCE_SUCCESS:
            //convert to display object
            const updateRequest: UpdateResourceRequestWithId = action.payload as UpdateResourceRequestWithId;

            return {
                ...state,
                resourceInformation: {
                    ...state.resourceInformation,
                    description: updateRequest.request.description,
                    displayName: updateRequest.request.displayName,
                    enabled: updateRequest.request.enabled,
                    name: updateRequest.request.name,
                    nonEditable: updateRequest.request.nonEditable
                } as ResourceInformation
            };
        default:
            return state;
    }
}