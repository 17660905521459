export const ENUMERATE_RESOURCE_SCOPE_CLAIMS = "ENUMERATE_RESOURCE_SCOPE_CLAIMS";
export type ENUMERATE_RESOURCE_SCOPE_CLAIMS = typeof ENUMERATE_RESOURCE_SCOPE_CLAIMS;
export const ENUMERATE_RESOURCE_SCOPE_CLAIMS_SUCCESS = "ENUMERATE_RESOURCE_SCOPE_CLAIMS_SUCCESS";
export type ENUMERATE_RESOURCE_SCOPE_CLAIMS_SUCCESS = typeof ENUMERATE_RESOURCE_SCOPE_CLAIMS_SUCCESS;
export const ENUMERATE_RESOURCE_SCOPE_CLAIMS_ERROR = "ENUMERATE_RESOURCE_SCOPE_CLAIMS_ERROR";
export type ENUMERATE_RESOURCE_SCOPE_CLAIMS_ERROR = typeof ENUMERATE_RESOURCE_SCOPE_CLAIMS_ERROR;

export const ADD_RESOURCE_SCOPE_CLAIM = "ADD_RESOURCE_SCOPE_CLAIM";
export type ADD_RESOURCE_SCOPE_CLAIM = typeof ADD_RESOURCE_SCOPE_CLAIM;
export const ADD_RESOURCE_SCOPE_CLAIM_SUCCESS = "ADD_RESOURCE_SCOPE_CLAIM_SUCCESS";
export type ADD_RESOURCE_SCOPE_CLAIM_SUCCESS = typeof ADD_RESOURCE_SCOPE_CLAIM_SUCCESS;
export const ADD_RESOURCE_SCOPE_CLAIM_ERROR = "ADD_RESOURCE_SCOPE_CLAIM_ERROR";
export type ADD_RESOURCE_SCOPE_CLAIM_ERROR = typeof ADD_RESOURCE_SCOPE_CLAIM_ERROR;

export const DELETE_RESOURCE_SCOPE_CLAIM = "DELETE_RESOURCE_SCOPE_CLAIM";
export type DELETE_RESOURCE_SCOPE_CLAIM = typeof DELETE_RESOURCE_SCOPE_CLAIM;
export const DELETE_RESOURCE_SCOPE_CLAIM_SUCCESS = "DELETE_RESOURCE_SCOPE_CLAIM_SUCCESS";
export type DELETE_RESOURCE_SCOPE_CLAIM_SUCCESS = typeof DELETE_RESOURCE_SCOPE_CLAIM_SUCCESS;
export const DELETE_RESOURCE_SCOPE_CLAIM_ERROR = "DELETE_RESOURCE_SCOPE_CLAIM_ERROR";
export type DELETE_RESOURCE_SCOPE_CLAIM_ERROR = typeof DELETE_RESOURCE_SCOPE_CLAIM_ERROR;
