import { UpdateResourceRequest, ResourceInformation } from "../../../../../../shared/utils/DataAccess";
import { UPDATE_RESOURCE, LOAD_RESOURCE } from "./actionDef";
import { action } from "typesafe-actions";


export interface AdminResourceAction {
    type: string;
    payload?: number | UpdateResourceRequestWithId | ResourceInformation | null;
}


export interface UpdateResourceRequestWithId {
    id: number,
    request: UpdateResourceRequest;
}

export const loadResource = (id: number): AdminResourceAction => action(LOAD_RESOURCE, id);
export const updateResource = (id: number, request: UpdateResourceRequest): AdminResourceAction => action(UPDATE_RESOURCE, { id: id, request: request } as UpdateResourceRequestWithId);
