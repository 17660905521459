export const ENUMERATE_GRANTS = "ENUMERATE_GRANTS";
export type ENUMERATE_GRANTS = typeof ENUMERATE_GRANTS;
export const ENUMERATE_GRANTS_SUCCESS = "ENUMERATE_GRANTS_SUCCESS";
export type ENUMERATE_GRANTS_SUCCESS = typeof ENUMERATE_GRANTS_SUCCESS;
export const ENUMERATE_GRANTS_ERROR = "ENUMERATE_GRANTS_ERROR";
export type ENUMERATE_GRANTS_ERROR = typeof ENUMERATE_GRANTS_ERROR;

export const REVOKE_GRANT = "REVOKE_GRANT";
export type REVOKE_GRANT = typeof REVOKE_GRANT;
export const REVOKE_GRANT_SUCCESS = "REVOKE_GRANT_SUCCESS";
export type REVOKE_GRANT_SUCCESS = typeof REVOKE_GRANT_SUCCESS;
export const REVOKE_GRANT_ERROR = "REVOKE_GRANT_ERROR";
export type REVOKE_GRANT_ERROR = typeof REVOKE_GRANT_ERROR;