import React, { FunctionComponent, useState } from 'react';
import { UpdateClientPropertyRequest } from '../../../../../../shared/utils/DataAccess';
import { TableCell, TextField } from '@material-ui/core';
import FullTableRow, { DisplayState } from '../../../../../../shared/FullTable/components/FullTableRow';
import { ClientPropertyInformationDisplay } from '../redux/reducer';


type Props = {
    property: ClientPropertyInformationDisplay,
    onUpdateProperty(id: number, request: UpdateClientPropertyRequest): void,
    onEditStart(id: number): void,
    onEditChanges(id: number, hasChanges: boolean): void,
    onEditCancel(id: number): void,
    onDelete(id: number): void
}


const ClientPropertyComponent: FunctionComponent<Props> = (props: Props) => {


    const InitialUpdatePropertyRequest = () => {
        return UpdateClientPropertyRequest.fromJS({
            key: props.property.key,
            value: props.property.value
        });
    }


    const [updatePropertyRequest, setupdatePropertyRequest] = useState<UpdateClientPropertyRequest>(InitialUpdatePropertyRequest());


    const hasChanges = (newUpdatePropertyRequest: UpdateClientPropertyRequest): boolean => {
        if (props.property === undefined) {
            return false;
        }

        return (
            newUpdatePropertyRequest.key !== props.property.key ||
            newUpdatePropertyRequest.value !== props.property.value);
    }


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUpdatePropertyRequest: UpdateClientPropertyRequest = UpdateClientPropertyRequest.fromJS({
            ...updatePropertyRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setupdatePropertyRequest(newUpdatePropertyRequest);
        props.onEditChanges(props.property.id, hasChanges(newUpdatePropertyRequest));
    }


    const onEditStart = () => {
        props.onEditStart(props.property.id);
    }


    const onEditCancel = () => {
        setupdatePropertyRequest(InitialUpdatePropertyRequest);
        props.onEditCancel(props.property.id);
    }


    const onDelete = () => {
        props.onDelete(props.property.id);
    }


    const onSave = () => {
        props.onUpdateProperty(props.property.id, updatePropertyRequest);
    }


    const { property: Property } = props;
    const isEditing: boolean =
        Property.displayState === DisplayState.Edit ||
        Property.displayState === DisplayState.NotSaved ||
        Property.displayState === DisplayState.Error;


    return (
        <FullTableRow
            displayState={Property.displayState}
            onEditStart={onEditStart}
            onEditCancel={onEditCancel}
            onSave={onSave}
            canEdit={true}
            canDelete={true}
            onDelete={onDelete} >
            {isEditing ?
                <>
                    <TableCell component="th" scope="row">
                        <TextField
                            id="key"
                            onChange={onTextFieldChange}
                            value={updatePropertyRequest.key}
                            style={{ width: '90px' }}
                        >
                        </TextField>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField
                            id="value"
                            onChange={onTextFieldChange}
                            value={updatePropertyRequest.value}
                            style={{ width: '90px' }}
                        >
                        </TextField>
                    </TableCell>
                </>
                :
                <>
                    <TableCell component="th" scope="row">{Property.key}</TableCell>
                    <TableCell>{Property.value}</TableCell>
                </>
            }
        </FullTableRow>
    );


}


export default ClientPropertyComponent;