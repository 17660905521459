import { action } from "typesafe-actions"
import { LOAD_PROFILE, UPDATE_PROFILE } from "./actionDef";
import { UpdateProfileRequest } from "../../../shared/utils/DataAccess";


export interface ProfileAction {
  type: string;
  payload?: UpdateProfileRequest | string
}


export const loadProfile = (): ProfileAction => action(LOAD_PROFILE);
export const updateProfile = (data: UpdateProfileRequest): ProfileAction => action(UPDATE_PROFILE, data);