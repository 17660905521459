export const ENUMERATE_APP_PASSWORDS = "ENUMERATE_APP_PASSWORDS";
export type ENUMERATE_APP_PASSWORDS = typeof ENUMERATE_APP_PASSWORDS;
export const ENUMERATE_APP_PASSWORDS_SUCCESS = "ENUMERATE_APP_PASSWORDS_SUCCESS";
export type ENUMERATE_APP_PASSWORDS_SUCCESS = typeof ENUMERATE_APP_PASSWORDS_SUCCESS;
export const ENUMERATE_APP_PASSWORDS_ERROR = "ENUMERATE_APP_PASSWORDS_ERROR";
export type ENUMERATE_APP_PASSWORDS_ERROR = typeof ENUMERATE_APP_PASSWORDS_ERROR;

export const ADD_APP_PASSWORD = "ADD_APP_PASSWORD";
export type ADD_APP_PASSWORD = typeof ADD_APP_PASSWORD;
export const ADD_APP_PASSWORD_SUCCESS = "ADD_APP_PASSWORD_SUCCESS";
export type ADD_APP_PASSWORD_SUCCESS = typeof ADD_APP_PASSWORD_SUCCESS;
export const ADD_APP_PASSWORD_ERROR = "ADD_APP_PASSWORD_ERROR";
export type ADD_APP_PASSWORD_ERROR = typeof ADD_APP_PASSWORD_ERROR;

export const REMOVE_APP_PASSWORD = "REMOVE_APP_PASSWORD";
export type REMOVE_APP_PASSWORD = typeof REMOVE_APP_PASSWORD;
export const REMOVE_APP_PASSWORD_SUCCESS = "REMOVE_APP_PASSWORD_SUCCESS";
export type REMOVE_APP_PASSWORD_SUCCESS = typeof REMOVE_APP_PASSWORD_SUCCESS;
export const REMOVE_APP_PASSWORD_ERROR = "REMOVE_APP_PASSWORD_ERROR";
export type REMOVE_APP_PASSWORD_ERROR = typeof REMOVE_APP_PASSWORD_ERROR;