import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { StoreState } from '../../../../shared/redux/store';

import FullTableComponent, { DisplayType, Column, EnumerationRequest } from '../../../../shared/FullTable/components/FullTableComponent';
import { ResourceInformation, AddResourceRequest } from '../../../../shared/utils/DataAccess';
import { AdminResourceListState } from '../redux/reducer';
import { enumerateResources, removeResource, addResource } from '../redux/actions';
import ResourceComponent from './ResourceComponent';
import AddResourceComponent from './AddResourceComponent';

import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';


const ResourcesPage: FunctionComponent = () => {
    const dispatch = useDispatch();


    const [isDialogOpen, setisDialogOpen] = useState<boolean>(false);


    const adminResourceListState: AdminResourceListState = useSelector((state: StoreState) => {
        return state.adminResourcesReducer
    });


    const Columns: Column[] = [
        { name: "Edit", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Name", displayName: "Name", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Created", displayName: "Created", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Updated", displayName: "Updated", sortable: false, displayType: DisplayType.Always, textAlign: "center" },
        { name: "LastAccessed", displayName: "Last Accessed", sortable: false, displayType: DisplayType.Always, textAlign: "center" },
        { name: "Delete", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
    ];


    useEffect(() => {
        dispatch(enumerateResources());
    }, []);


    const apiEnumerate = (request: EnumerationRequest) => {
        dispatch(enumerateResources());
    };


    const onOpenAddResourceDialog = () => {
        setisDialogOpen(true);
    }


    const onCloseAddResourceDialog = () => {
        setisDialogOpen(false);
    }


    const onAddResource = (resource: ResourceInformation) => {
        const request: AddResourceRequest = AddResourceRequest.fromJS({
            enabled: resource.enabled,
            name: resource.name,
            displayName: resource.displayName,
            description: resource.description,
            nonEditable: resource.nonEditable,
        });

        dispatch(addResource(request));

        setisDialogOpen(false);
    }


    const onSelectResource = (id: number) => {
        dispatch(push("/administration/resources/" + id.toString()));
    }


    const onDeleteResource = (id: number) => {
        dispatch(removeResource(id));

        setisDialogOpen(false);
    }


    const classes = makeStyles({
        page: {
            width: '90%',
            margin: '80px auto 0px',
            padding: '20px'
        },
    })();


    return (
        <Paper className={classes.page}>

            <h1>Resources</h1>
            Below is a list of all Resources.

            <FullTableComponent Columns={Columns} apiEnumerate={apiEnumerate}>
                {adminResourceListState ? adminResourceListState.list.allIds.map(id => (
                    <ResourceComponent
                        key={id}
                        resourceInformation={adminResourceListState.list.byId[id]}
                        onSelect={onSelectResource}
                        onDelete={onDeleteResource}
                    ></ResourceComponent>
                )) : ""}
            </FullTableComponent>
            <div style={{ textAlign: 'center', marginTop: '20px' }}><Button variant="outlined" onClick={onOpenAddResourceDialog}>Add Resource</Button></div>
            <AddResourceComponent visible={isDialogOpen} onAddResource={onAddResource} onClose={onCloseAddResourceDialog}></AddResourceComponent>
        </Paper>
    );


}


export default ResourcesPage;