import React, { FunctionComponent, useState } from 'react';
import { UpdateClientClaimRequest } from '../../../../../../shared/utils/DataAccess';
import { TableCell, TextField } from '@material-ui/core';
import FullTableRow, { DisplayState } from '../../../../../../shared/FullTable/components/FullTableRow';
import { ClientClaimInformationDisplay } from '../redux/reducer';


type Props = {
    claim: ClientClaimInformationDisplay,
    onUpdateClaim(id: number, request: UpdateClientClaimRequest): void,
    onEditStart(id: number): void,
    onEditChanges(id: number, hasChanges: boolean): void,
    onEditCancel(id: number): void,
    onDelete(id: number): void
}


const ClientClaimComponent: FunctionComponent<Props> = (props: Props) => {


    const InitialUpdateClaimRequest = () => {
        return UpdateClientClaimRequest.fromJS({
            type: props.claim.type,
            value: props.claim.value
        });
    }


    const [updateClaimRequest, setupdateClaimRequest] = useState<UpdateClientClaimRequest>(InitialUpdateClaimRequest());


    const hasChanges = (newUpdateClaimRequest: UpdateClientClaimRequest): boolean => {
        if (props.claim === undefined) {
            return false;
        }

        return (
            newUpdateClaimRequest.type !== props.claim.type ||
            newUpdateClaimRequest.value !== props.claim.value);
    }


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUpdateClaimRequest: UpdateClientClaimRequest = UpdateClientClaimRequest.fromJS({
            ...updateClaimRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setupdateClaimRequest(newUpdateClaimRequest);
        props.onEditChanges(props.claim.id, hasChanges(newUpdateClaimRequest));
    }


    const onEditStart = () => {
        props.onEditStart(props.claim.id);
    }


    const onEditCancel = () => {
        setupdateClaimRequest(InitialUpdateClaimRequest);
        props.onEditCancel(props.claim.id);
    }


    const onDelete = () => {
        props.onDelete(props.claim.id);
    }


    const onSave = () => {
        props.onUpdateClaim(props.claim.id, updateClaimRequest);
    }


    const { claim } = props;
    const isEditing: boolean =
        claim.displayState === DisplayState.Edit ||
        claim.displayState === DisplayState.NotSaved ||
        claim.displayState === DisplayState.Error;


    return (
        <FullTableRow
            displayState={claim.displayState}
            onEditStart={onEditStart}
            onEditCancel={onEditCancel}
            onSave={onSave}
            canEdit={true}
            canDelete={true}
            onDelete={onDelete} >
            {isEditing ?
                <>
                    <TableCell component="th" scope="row">
                        <TextField
                            id="type"
                            onChange={onTextFieldChange}
                            value={updateClaimRequest.type}
                            style={{ width: '90px' }}
                        >
                        </TextField>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField
                            id="value"
                            onChange={onTextFieldChange}
                            value={updateClaimRequest.value}
                            style={{ width: '90px' }}
                        >
                        </TextField>
                    </TableCell>
                </>
                :
                <>
                    <TableCell component="th" scope="row">{claim.type}</TableCell>
                    <TableCell>{claim.value}</TableCell>
                </>
            }
        </FullTableRow>
    );


}


export default ClientClaimComponent;