import React, { FunctionComponent, useEffect, useContext } from 'react';
import { __RouterContext as RouterContext } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import { ResourceInformation } from '../../../../../shared/utils/DataAccess';
import { loadResource } from '../edit/redux/actions';
import { StoreState } from '../../../../../shared/redux/store';
import ResourceEditComponent from '../edit/components/ResourceEditComponent';
import ResourceClaimsComponent from '../claims/components/ResourceClaims';
import ResponstiveTabsComponent from '../../../../../shared/ResponsiveTabs/ResponsiveTabsComponent';
import ResponsiveTabComponent from '../../../../../shared/ResponsiveTabs/ResponstiveTabPanelComponent';
import ResourcePropertiesComponent from '../properties/components/ResourceProperties';
import ResourceSecretsComponent from '../secrets/components/ResourceSecrets';
import ResourceScopesComponent from '../scopes/components/ResourceScopes';


const ResourcePage: FunctionComponent = () => {
    const dispatch = useDispatch();

    let { id } = (useContext(RouterContext).match.params) as any;


    const resourceInformation: ResourceInformation = useSelector((state: StoreState) => {
        return state.adminResourceReducer.resourceInformation
    });


    useEffect(() => {
        dispatch(loadResource(id));
    }, []);


    const classes = makeStyles({
        page: {
            width: '90%',
            margin: '80px auto 0px',
            padding: '20px'
        },
        row: {
            maxWidth: "500px"
        },
        useDropdown: {
            display: "none",
            ['@media (max-width:600px)']: {
                display: "inherit"
            }
        },
        useTabs: {
            display: "inherit",
            ['@media (max-width:600px)']: {
                display: "none"
            }
        },
    })();


    const tabs = [
        { id: 0, title: "Resource" },
        { id: 1, title: "Claims" },
        { id: 2, title: "Properties" },
        { id: 3, title: "Secrets" },
        { id: 4, title: "Scopes" },
    ];


    return (
        <Paper className={classes.page}>
            <h3>{resourceInformation.displayName}</h3>
            <ResponstiveTabsComponent tabs={tabs}>
                <ResponsiveTabComponent id={0}>
                    <ResourceEditComponent resourceInformation={resourceInformation}></ResourceEditComponent>
                </ResponsiveTabComponent>
                <ResponsiveTabComponent id={1}>
                    <ResourceClaimsComponent resourceInformation={resourceInformation}></ResourceClaimsComponent>
                </ResponsiveTabComponent>
                <ResponsiveTabComponent id={2}>
                    <ResourcePropertiesComponent resourceInformation={resourceInformation}></ResourcePropertiesComponent>
                </ResponsiveTabComponent>
                <ResponsiveTabComponent id={3}>
                    <ResourceSecretsComponent resourceInformation={resourceInformation}></ResourceSecretsComponent>
                </ResponsiveTabComponent>
                <ResponsiveTabComponent id={4}>
                    <ResourceScopesComponent resourceInformation={resourceInformation}></ResourceScopesComponent>
                </ResponsiveTabComponent>
            </ResponstiveTabsComponent>
        </Paper>
    );


}


export default ResourcePage;