import { Middleware } from 'redux';

import {
    ResourceInformation, AddResourceRequest
} from '../../../../shared/utils/DataAccess';
import {
    ENUMERATE_RESOURCES, ENUMERATE_RESOURCES_SUCCESS, ENUMERATE_RESOURCES_ERROR,
    ADD_RESOURCE, ADD_RESOURCE_SUCCESS, ADD_RESOURCE_ERROR,
    REMOVE_RESOURCE, REMOVE_RESOURCE_SUCCESS, REMOVE_RESOURCE_ERROR
} from './actionDef';
import { AdminResourcesAction } from './actions';
import APIClientFactory from '../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../shared/spinner/redux/reducer';



const adminResourcesDataService: Middleware = store => next => (requestedAction: AdminResourcesAction) => {
    next(requestedAction);

    const Resource = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ENUMERATE_RESOURCES:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            Resource.enumerateResources()
                .then((Resources: ResourceInformation[]) => {
                    store.dispatch(action(ENUMERATE_RESOURCES_SUCCESS, Resources));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_RESOURCES_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ADD_RESOURCE:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            Resource.addResource(requestedAction.payload as AddResourceRequest)
                .then((newResource: ResourceInformation) => {
                    store.dispatch(action(ADD_RESOURCE_SUCCESS, newResource));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_RESOURCE_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case REMOVE_RESOURCE:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            Resource.removeResource(requestedAction.payload as number)
                .then(() => {
                    store.dispatch(action(REMOVE_RESOURCE_SUCCESS, requestedAction.payload));
                })
                .catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(REMOVE_RESOURCE_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminResourcesDataService;