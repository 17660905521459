import React, { FunctionComponent } from 'react';
import { TableRow, TableCell, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'


const COLOR_ROW_EDIT = "#868686";
const COLOR_ROW_CHANGES = "#ff9800";
const COLOR_ROW_SAVING = "#aaffaa";
const COLOR_ROW_SAVED = "#33691e";
const COLOR_ROW_ERROR = "#ff0000";
const COLOR_ROW_SELECTED = "#868686";
const COLOR_ROW_LIGHTTEXT = "#ffffff";


export enum DisplayState {
    ReadOnly,
    Edit,
    NotSaved,
    Saving,
    Saved,
    Error
}


interface DispatchProps {
    displayState: DisplayState,
    children: React.ReactNode,
    canEdit?: boolean,
    canDelete?: boolean,
    canSelect?: boolean,
    onEditStart?: () => void;
    onEditCancel?: () => void;
    onSave?: () => void;
    onDelete?: () => void;
    onSelect?: () => void;
}


export type Props = DispatchProps;


const FullTableRow: FunctionComponent<Props> = (props: Props) => {


    const onRowClicked = () => {
        if (props.onSelect) {
            props.onSelect();
        }
    }


    const onStartEditing = () => {
        if (props.onEditStart) {
            props.onEditStart();
        }
    }


    const onCancelEditing = () => {
        if (props.onEditCancel) {
            props.onEditCancel();
        }
    }


    const onSave = () => {
        if (props.onSave) {
            props.onSave();
        }
    }


    const onDelete = () => {
        if (props.onDelete) {
            props.onDelete();
        }
    }


    const { displayState } = props;
    const isEditing: boolean =
        displayState === DisplayState.Edit ||
        displayState === DisplayState.NotSaved ||
        displayState === DisplayState.Error;

    const styles = makeStyles({
        row: {
            color: displayState === DisplayState.Error ? "#ffffff" : "#000000",
            cursor: props.canSelect ? "pointer" : "",
            backgroundColor:
                displayState === DisplayState.Edit ? COLOR_ROW_EDIT :
                    displayState === DisplayState.NotSaved ? COLOR_ROW_CHANGES :
                        displayState === DisplayState.Saving ? COLOR_ROW_SAVING :
                            displayState === DisplayState.Saved ? COLOR_ROW_SAVED :
                                displayState === DisplayState.Error ? COLOR_ROW_ERROR :
                                    "",
            "&:hover": {
                backgroundColor: props.canSelect ? COLOR_ROW_SELECTED : "",
                "& td": {
                    color: props.canSelect ? COLOR_ROW_LIGHTTEXT : ""
                }
            }
        },
    });


    const classes = styles();

    return (
        <TableRow className={classes.row} onClick={onRowClicked}>
            {isEditing ?
                <TableCell style={{ width: '50px' }}>
                    <CancelIcon style={{ cursor: 'pointer' }} onClick={onCancelEditing} titleAccess="Cancel Changes"></CancelIcon>
                    <SaveIcon style={{ cursor: 'pointer', display: displayState === DisplayState.NotSaved || displayState === DisplayState.Error ? "" : "none" }} onClick={onSave} titleAccess="Save Changes"></SaveIcon>
                </TableCell>
                :
                props.canEdit ?
                    <TableCell>
                        <EditIcon style={{ cursor: 'pointer' }} onClick={onStartEditing} titleAccess="Edit"></EditIcon>
                    </TableCell>
                    :
                    <></>
            }
            {props.children}
            {props.canDelete ?
                <TableCell style={{ textAlign: 'right' }}>
                    <DeleteIcon style={{ cursor: 'pointer' }} onClick={onDelete} titleAccess="Delete"></DeleteIcon>
                </TableCell>
                :
                <></>
            }
        </TableRow>
    );

}

export default FullTableRow;