export const DISPLAY_STATE_CHANGE = "DISPLAY_STATE_CHANGE";
export type DISPLAY_STATE_CHANGE = typeof DISPLAY_STATE_CHANGE;

export const ENUMERATE_CLIENT_PROPERTIES = "ENUMERATE_CLIENT_PROPERTIES";
export type ENUMERATE_CLIENT_PROPERTIES = typeof ENUMERATE_CLIENT_PROPERTIES;
export const ENUMERATE_CLIENT_PROPERTIES_SUCCESS = "ENUMERATE_CLIENT_PROPERTIES_SUCCESS";
export type ENUMERATE_CLIENT_PROPERTIES_SUCCESS = typeof ENUMERATE_CLIENT_PROPERTIES_SUCCESS;
export const ENUMERATE_CLIENT_PROPERTIES_ERROR = "ENUMERATE_CLIENT_PROPERTIES_ERROR";
export type ENUMERATE_CLIENT_PROPERTIES_ERROR = typeof ENUMERATE_CLIENT_PROPERTIES_ERROR;

export const ADD_CLIENT_PROPERTY = "ADD_CLIENT_PROPERTY";
export type ADD_CLIENT_PROPERTY = typeof ADD_CLIENT_PROPERTY;
export const ADD_CLIENT_PROPERTY_SUCCESS = "ADD_CLIENT_PROPERTY_SUCCESS";
export type ADD_CLIENT_PROPERTY_SUCCESS = typeof ADD_CLIENT_PROPERTY_SUCCESS;
export const ADD_CLIENT_PROPERTY_ERROR = "ADD_CLIENT_PROPERTY_ERROR";
export type ADD_CLIENT_PROPERTY_ERROR = typeof ADD_CLIENT_PROPERTY_ERROR;

export const UPDATE_CLIENT_PROPERTY = "UPDATE_CLIENT_PROPERTY";
export type UPDATE_CLIENT_PROPERTY = typeof UPDATE_CLIENT_PROPERTY;
export const UPDATE_CLIENT_PROPERTY_SUCCESS = "UPDATE_CLIENT_PROPERTY_SUCCESS";
export type UPDATE_CLIENT_PROPERTY_SUCCESS = typeof UPDATE_CLIENT_PROPERTY_SUCCESS;
export const UPDATE_CLIENT_PROPERTY_ERROR = "UPDATE_CLIENT_PROPERTY_ERROR";
export type UPDATE_CLIENT_PROPERTY_ERROR = typeof UPDATE_CLIENT_PROPERTY_ERROR;

export const DELETE_CLIENT_PROPERTY = "DELETE_CLIENT_PROPERTY";
export type DELETE_CLIENT_PROPERTY = typeof DELETE_CLIENT_PROPERTY;
export const DELETE_CLIENT_PROPERTY_SUCCESS = "DELETE_CLIENT_PROPERTY_SUCCESS";
export type DELETE_CLIENT_PROPERTY_SUCCESS = typeof DELETE_CLIENT_PROPERTY_SUCCESS;
export const DELETE_CLIENT_PROPERTY_ERROR = "DELETE_CLIENT_PROPERTY_ERROR";
export type DELETE_CLIENT_PROPERTY_ERROR = typeof DELETE_CLIENT_PROPERTY_ERROR;
