import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ProfileInformation, UpdateProfileRequest } from '../../../shared/utils/DataAccess';
import { loadProfile, updateProfile } from '../redux/actions';
import { StoreState } from '../../../shared/redux/store';

import { Button, Checkbox, Grid, Paper, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';


const ProfilePage: FunctionComponent = () => {
    const dispatch = useDispatch();


    const [updateProfileRequest, setupdateProfileRequest] = useState<UpdateProfileRequest>(ProfileInformation.fromJS({
        loginEmail: "",
        loginPhone: "",
        givenName: "",
        surname: "",
        requiresTwoFactorAuthentication: false
    }));


    const profile: ProfileInformation = useSelector((state: StoreState) => {
        if (updateProfileRequest.loginEmail !== state.profileReducer.profile.loginEmail) {
            setupdateProfileRequest(ProfileInformation.fromJS({
                loginEmail: state.profileReducer.profile.loginEmail,
                loginPhone: state.profileReducer.profile.loginPhone,
                givenName: state.profileReducer.profile.givenName,
                surname: state.profileReducer.profile.surname,
                requiresTwoFactorAuthentication: state.profileReducer.profile.requiresTwoFactorAuthentication
            }));
        }
        return state.profileReducer.profile
    });


    useEffect(() => {
        dispatch(loadProfile());
    }, []);


    const hasChanges = (): boolean => {
        if (profile === undefined) {
            return false;
        }

        return (
            updateProfileRequest.loginEmail !== profile.loginEmail ||
            updateProfileRequest.loginPhone !== profile.loginPhone ||
            updateProfileRequest.givenName !== profile.givenName ||
            updateProfileRequest.surname !== profile.surname ||
            updateProfileRequest.requiresTwoFactorAuthentication !== profile.requiresTwoFactorAuthentication);
    }


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUpdateProfileRequest: UpdateProfileRequest = UpdateProfileRequest.fromJS({
            ...updateProfileRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setupdateProfileRequest(newUpdateProfileRequest);
    }


    const onCheckFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUpdateProfileRequest: UpdateProfileRequest = UpdateProfileRequest.fromJS({
            ...updateProfileRequest,
            [e.currentTarget.id]: e.currentTarget.checked
        });

        setupdateProfileRequest(newUpdateProfileRequest);
    }


    const onSubmit = () => {
        dispatch(updateProfile(updateProfileRequest));
    }


    const classes = makeStyles({
        title: {
            textAlign: "center",
            fontSize: "1.5em",
            fontWeight: "bold"
        },
        btn: {
            textAlign: "right"
        }
    })();


    return (
        <Paper style={{ maxWidth: "350px", padding: "20px", margin: "80px auto 0px" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}><div className={classes.title}>Profile</div></Grid>

                <Grid item xs={12} sm={12}>
                    <TextField
                        id="givenName"
                        label="Given Name"
                        fullWidth={true}
                        value={updateProfileRequest.givenName || ""}
                        onChange={onTextFieldChange}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <TextField
                        id="surname"
                        label="Surname"
                        fullWidth={true}
                        value={updateProfileRequest.surname || ""}
                        onChange={onTextFieldChange}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <TextField
                        id="loginEmail"
                        label="Login Email"
                        fullWidth={true}
                        value={updateProfileRequest.loginEmail || ""}
                        onChange={onTextFieldChange}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <TextField
                        id="loginPhone"
                        label="Login Phone"
                        fullWidth={true}
                        value={updateProfileRequest.loginPhone || ""}
                        onChange={onTextFieldChange}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="requiresTwoFactorAuthentication"
                                checked={updateProfileRequest.requiresTwoFactorAuthentication || false}
                                onChange={onCheckFieldChange}
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                            />
                        }
                        label="Two Factor Authentication"
                    />

                </Grid>

                <Grid item xs={12} sm={12}><div className={classes.btn}>
                    <Button variant="outlined" onClick={onSubmit} disabled={!hasChanges()}>Save</Button></div>
                </Grid>

            </Grid>
        </Paper>
    );


};


export default ProfilePage;