export const DISPLAY_STATE_CHANGE = "DISPLAY_STATE_CHANGE";
export type DISPLAY_STATE_CHANGE = typeof DISPLAY_STATE_CHANGE;

export const ENUMERATE_RESOURCE_SECRETS = "ENUMERATE_RESOURCE_SECRETS";
export type ENUMERATE_RESOURCE_SECRETS = typeof ENUMERATE_RESOURCE_SECRETS;
export const ENUMERATE_RESOURCE_SECRETS_SUCCESS = "ENUMERATE_RESOURCE_SECRETS_SUCCESS";
export type ENUMERATE_RESOURCE_SECRETS_SUCCESS = typeof ENUMERATE_RESOURCE_SECRETS_SUCCESS;
export const ENUMERATE_RESOURCE_SECRETS_ERROR = "ENUMERATE_RESOURCE_SECRETS_ERROR";
export type ENUMERATE_RESOURCE_SECRETS_ERROR = typeof ENUMERATE_RESOURCE_SECRETS_ERROR;

export const ADD_RESOURCE_SECRET = "ADD_RESOURCE_SECRET";
export type ADD_RESOURCE_SECRET = typeof ADD_RESOURCE_SECRET;
export const ADD_RESOURCE_SECRET_SUCCESS = "ADD_RESOURCE_SECRET_SUCCESS";
export type ADD_RESOURCE_SECRET_SUCCESS = typeof ADD_RESOURCE_SECRET_SUCCESS;
export const ADD_RESOURCE_SECRET_ERROR = "ADD_RESOURCE_SECRET_ERROR";
export type ADD_RESOURCE_SECRET_ERROR = typeof ADD_RESOURCE_SECRET_ERROR;

export const UPDATE_RESOURCE_SECRET = "UPDATE_RESOURCE_SECRET";
export type UPDATE_RESOURCE_SECRET = typeof UPDATE_RESOURCE_SECRET;
export const UPDATE_RESOURCE_SECRET_SUCCESS = "UPDATE_RESOURCE_SECRET_SUCCESS";
export type UPDATE_RESOURCE_SECRET_SUCCESS = typeof UPDATE_RESOURCE_SECRET_SUCCESS;
export const UPDATE_RESOURCE_SECRET_ERROR = "UPDATE_RESOURCE_SECRET_ERROR";
export type UPDATE_RESOURCE_SECRET_ERROR = typeof UPDATE_RESOURCE_SECRET_ERROR;

export const DELETE_RESOURCE_SECRET = "DELETE_RESOURCE_SECRET";
export type DELETE_RESOURCE_SECRET = typeof DELETE_RESOURCE_SECRET;
export const DELETE_RESOURCE_SECRET_SUCCESS = "DELETE_RESOURCE_SECRET_SUCCESS";
export type DELETE_RESOURCE_SECRET_SUCCESS = typeof DELETE_RESOURCE_SECRET_SUCCESS;
export const DELETE_RESOURCE_SECRET_ERROR = "DELETE_RESOURCE_SECRET_ERROR";
export type DELETE_RESOURCE_SECRET_ERROR = typeof DELETE_RESOURCE_SECRET_ERROR;
