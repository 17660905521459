import { SERVER_ERROR } from "./actionDefs";


export interface ErrorState {
    title: string | null,
    display: string | null,
    errorDetails: any
};


export const initialState: ErrorState = {
    title: null,
    display: null,
    errorDetails: null
};


export interface ErrorAction {
    type: string;
    payload: any
}


export function errorReducer(state: ErrorState = initialState, action: ErrorAction): ErrorState {
    switch (action.type) {
        case SERVER_ERROR:
            const serverError: ErrorState = action.payload as ErrorState;
            var display: string | null = null;
            var title: string | null = null;
            var details: any;

            details = serverError.errorDetails;
            if (typeof details === "string") {
                display = details;
            } else if (details !== null) {
                if (details.response) {
                    try {
                        const serverSideError: any = JSON.parse(details.response);
                        title = serverSideError.Message;
                        display = serverSideError.Debug.replace(/"/g, "");
                    } catch (e) {
                        title = details.message;
                        display = details.response.replace(/"/g, "");
                    }
                } else {
                    title = "Error";
                    display = details.message;
                }
            }

            return {
                ...state,
                errorDetails: details,
                title: title,
                display: display
            };
        default:
            return state;
    }
}