import React, { FunctionComponent, useEffect } from 'react';
import { GrantInformation } from '../../../shared/utils/DataAccess';
import { enumerateGrants, revokeGrant, RevokeGrantInformation } from '../../grants/redux/actions';
import { StoreState } from '../../../shared/redux/store';
import { GrantsState } from '../redux/reducer';
import GrantComponent from './GrantComponent'
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';


interface StateProps {
    grantState: GrantsState
}


export type Props = StateProps;


const GrantsPage: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();


    const grants: GrantsState = useSelector((state: StoreState) => {
        return state.grantsReducer
    });


    useEffect(() => {
        dispatch(enumerateGrants());
    }, []);


    const onRevoke = (grant: GrantInformation) => {
        dispatch(revokeGrant({ clientId: grant.clientId, id: grant.created.getTime() } as RevokeGrantInformation));
    }


    const classes = makeStyles({
        page: {
            width: '90%',
            margin: '80px auto 0px',
            padding: '10px'
        },
        container: {
            borderBottom: 'solid 1px #000000',
            padding: '20px',
            marginBottom: '10px'
        }
    })();


    return (
        <Paper className={classes.page}>

            <h1>Grants</h1>
            Below is the list of grants you have allowed.  You may revoke any of these grants at any time.

            <div className={classes.container}>
                {grants.list.allIds.map(id => (
                    <GrantComponent key={id} grant={grants.list.byId[id]} onRevoke={onRevoke}></GrantComponent>
                ))}
            </div>
        </Paper>
    );


};

export default GrantsPage;