import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { Button, Select, InputLabel, MenuItem } from '@material-ui/core';
import { ClientConfigInformation, ClientAllowedGrantTypeInformationGrantType } from '../../../../../../shared/utils/DataAccess';
import { StoreState } from '../../../../../../shared/redux/store';
import FullTableComponent, { DisplayType, Column } from '../../../../../../shared/FullTable/components/FullTableComponent';
import ClientAllowedGrantTypeComponent from './ClientAllowedGrantTypeComponent';
import { AdminClientAllowedGrantTypesState } from '../redux/reducer';
import { loadClientAllowedGrantTypes, deleteClientAllowedGrantTypes, addClientAllowedGrantTypes } from '../redux/actions';


type Props = {
    clientInformation: ClientConfigInformation
}


const ClientAllowedGrantTypesComponent: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();


    const [addGrantType, setaddGrantType] = useState<string>("");


    const clientAllowedGrantTypes: AdminClientAllowedGrantTypesState = useSelector((state: StoreState) => {
        return state.adminClientAllowedGrantTypesReducer;
    });


    const apiEnumerate = () => {
        if (props.clientInformation.id) {
            dispatch(loadClientAllowedGrantTypes(props.clientInformation.id));
        }
    };


    const onDeleteClient = (request: number) => {
        dispatch(deleteClientAllowedGrantTypes(props.clientInformation.id, request));
    };


    useEffect(() => {
        apiEnumerate();
    }, [props.clientInformation]);


    const hasChanges = (): boolean => {
        return (addGrantType.length > 0);
    }


    const onSelectFieldChange = (e: React.ChangeEvent<{ name?: string; value: any }>) => {
        setaddGrantType(e.target.value as string);
    }


    const onSubmit = () => {
        dispatch(addClientAllowedGrantTypes(props.clientInformation.id, addGrantType));

        setaddGrantType("");
    }


    const Columns: Column[] = [
        { name: "Allowed Grant Type", displayName: "Allowed Grant Type", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Delete", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
    ];


    const classes = makeStyles({
        row: {
            maxWidth: "500px"
        }
    })();


    var grantTypes: String[] = [];
    for (const item in ClientAllowedGrantTypeInformationGrantType) {
        grantTypes.push(item);
    }

    return (
        <>
            <div className={classes.row}>
                <FullTableComponent Columns={Columns} apiEnumerate={apiEnumerate}>
                    {clientAllowedGrantTypes ? clientAllowedGrantTypes.list.allIds.map(id => (
                        <ClientAllowedGrantTypeComponent
                            key={id}
                            allowedGrantType={clientAllowedGrantTypes.list.byId[id]}
                            onDelete={onDeleteClient}
                        ></ClientAllowedGrantTypeComponent>
                    )) : ""}
                </FullTableComponent>
            </div>
            <div className={classes.row} style={{ marginTop: '50px' }}>
                <InputLabel htmlFor="newAllowedGrantType-simple">New Allowed Grant Type</InputLabel>
                <Select
                    id="newAllowedGrantType"
                    fullWidth={true}
                    onChange={onSelectFieldChange}
                    value={addGrantType}
                    inputProps={{
                        id: 'newAllowedGrantType-simple',
                    }}
                >
                    {grantTypes.map((value, index) => {
                        return <MenuItem key={value as string} value={value as string}>{value}</MenuItem>;
                    })}
                </Select>
            </div>
            <div className={classes.row} style={{ textAlign: 'right' }}>
                <Button variant="outlined" color="primary" onClick={onSubmit} disabled={!hasChanges()}>Add Allowed Grant Type</Button>
            </div>
        </>
    );


}


export default ClientAllowedGrantTypesComponent;