export const ENUMERATE_ADMINISTRATORS = "ENUMERATE_ADMINISTRATORS";
export type ENUMERATE_ADMINISTRATORS = typeof ENUMERATE_ADMINISTRATORS;
export const ENUMERATE_ADMINISTRATORS_SUCCESS = "ENUMERATE_ADMINISTRATORS_SUCCESS";
export type ENUMERATE_ADMINISTRATORS_SUCCESS = typeof ENUMERATE_ADMINISTRATORS_SUCCESS;
export const ENUMERATE_ADMINISTRATORS_ERROR = "ENUMERATE_ADMINISTRATORS_ERROR";
export type ENUMERATE_ADMINISTRATORS_ERROR = typeof ENUMERATE_ADMINISTRATORS_ERROR;

export const ADD_ADMINISTRATOR = "ADD_ADMINISTRATOR";
export type ADD_ADMINISTRATOR = typeof ADD_ADMINISTRATOR;
export const ADD_ADMINISTRATOR_SUCCESS = "ADD_ADMINISTRATOR_SUCCESS";
export type ADD_ADMINISTRATOR_SUCCESS = typeof ADD_ADMINISTRATOR_SUCCESS;
export const ADD_ADMINISTRATOR_ERROR = "ADD_ADMINISTRATOR_ERROR";
export type ADD_ADMINISTRATOR_ERROR = typeof ADD_ADMINISTRATOR_ERROR;


export const REMOVE_ADMINISTRATOR = "REMOVE_ADMINISTRATOR";
export type REMOVE_ADMINISTRATOR = typeof REMOVE_ADMINISTRATOR;
export const REMOVE_ADMINISTRATOR_SUCCESS = "REMOVE_ADMINISTRATOR_SUCCESS";
export type REMOVE_ADMINISTRATOR_SUCCESS = typeof REMOVE_ADMINISTRATOR_SUCCESS;
export const REMOVE_ADMINISTRATOR_ERROR = "REMOVE_ADMINISTRATOR_ERROR";
export type REMOVE_ADMINISTRATOR_ERROR = typeof REMOVE_ADMINISTRATOR_ERROR;