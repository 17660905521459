import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { Button, Select, InputLabel, MenuItem } from '@material-ui/core';
import { StoreState } from '../../../../../../../../shared/redux/store';
import FullTableComponent, { DisplayType, Column } from '../../../../../../../../shared/FullTable/components/FullTableComponent';
import ResourcesScopesClaimComponent from './ResourcesScopesClaimComponent';
import { AdminResourcesScopesClaimsState } from '../redux/reducer';
import { loadResourceScopesClaims, deleteResourceScopesClaims, addResourceScopesClaims } from '../redux/actions';
import { AdminResourceClaimsState } from '../../../../claims/redux/reducer';
import { loadResourceClaims } from '../../../../claims/redux/actions';


type Props = {
    resourceId: number,
    scopeId: number
}


const ResourcesScopesClaimsComponent: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();


    const [loadedScopeId, setloadedScopeId] = useState<number>(0);
    const [addResourceScopeClaim, setaddResourceScopeClaim] = useState<string>("");


    const resourceClaims: AdminResourceClaimsState = useSelector((state: StoreState) => {
        return state.adminResourceClaimsReducer;
    });


    const resourceScopesClaims: AdminResourcesScopesClaimsState = useSelector((state: StoreState) => {
        return state.adminResourcesScopesClaimsReducer;
    });


    const apiEnumerate = () => {
        if (props.resourceId && props.scopeId) {
            dispatch(loadResourceClaims(props.resourceId));
            dispatch(loadResourceScopesClaims(props.resourceId, props.scopeId));
        }
    };


    const onDeleteClaim = (request: number) => {
        dispatch(deleteResourceScopesClaims(props.resourceId, props.scopeId, request));
    };


    useEffect(() => {
        apiEnumerate();
    }, [props.resourceId, props.scopeId]);


    const hasChanges = (): boolean => {
        return (addResourceScopeClaim.length > 0);
    }


    const onSelectFieldChange = (e: React.ChangeEvent<{ name?: string; value: any }>) => {
        setaddResourceScopeClaim(e.target.value as string);
    }


    const onSubmit = () => {
        dispatch(addResourceScopesClaims(props.resourceId, props.scopeId, addResourceScopeClaim));

        setaddResourceScopeClaim("");
    }


    const Columns: Column[] = [
        { name: "ClaimType", displayName: "Claim Type", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Delete", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
    ];


    const classes = makeStyles({
        row: {
            maxWidth: "500px"
        }
    })();


    var claimTypes: String[] = [];
    for (const id in resourceClaims.list.byId) {
        claimTypes.push(resourceClaims.list.byId[id].type);
    }

    return (
        <>
            <div className={classes.row}>
                <FullTableComponent Columns={Columns} apiEnumerate={apiEnumerate}>
                    {resourceScopesClaims ? resourceScopesClaims.list.allIds.map(id => (
                        <ResourcesScopesClaimComponent
                            key={id}
                            claimType={resourceScopesClaims.list.byId[id]}
                            onDelete={onDeleteClaim}
                        ></ResourcesScopesClaimComponent>
                    )) : ""}
                </FullTableComponent>
            </div>
            <div className={classes.row} style={{ marginTop: '50px' }}>
                <InputLabel htmlFor="newClaimType-simple">New Claim Type</InputLabel>
                <Select
                    id="newClaimType"
                    fullWidth={true}
                    onChange={onSelectFieldChange}
                    value={addResourceScopeClaim}
                    inputProps={{
                        id: 'newClaimType-simple',
                    }}
                >
                    {claimTypes.map((value, index) => {
                        return <MenuItem key={value as string} value={value as string}>{value}</MenuItem>;
                    })}
                </Select>
            </div>
            <div className={classes.row} style={{ textAlign: 'right' }}>
                <Button variant="outlined" color="primary" onClick={onSubmit} disabled={!hasChanges()}>Add Claim Type</Button>
            </div>
        </>
    );


}


export default ResourcesScopesClaimsComponent;