export const ENUMERATE_RESOURCES = "ENUMERATE_RESOURCES";
export type ENUMERATE_RESOURCES = typeof ENUMERATE_RESOURCES;
export const ENUMERATE_RESOURCES_SUCCESS = "ENUMERATE_RESOURCES_SUCCESS";
export type ENUMERATE_RESOURCES_SUCCESS = typeof ENUMERATE_RESOURCES_SUCCESS;
export const ENUMERATE_RESOURCES_ERROR = "ENUMERATE_RESOURCES_ERROR";
export type ENUMERATE_RESOURCES_ERROR = typeof ENUMERATE_RESOURCES_ERROR;

export const ADD_RESOURCE = "ADD_RESOURCE";
export type ADD_RESOURCE = typeof ADD_RESOURCE;
export const ADD_RESOURCE_SUCCESS = "ADD_RESOURCE_SUCCESS";
export type ADD_RESOURCE_SUCCESS = typeof ADD_RESOURCE_SUCCESS;
export const ADD_RESOURCE_ERROR = "ADD_RESOURCE_ERROR";
export type ADD_RESOURCE_ERROR = typeof ADD_RESOURCE_ERROR;

export const REMOVE_RESOURCE = "REMOVE_RESOURCE";
export type REMOVE_RESOURCE = typeof REMOVE_RESOURCE;
export const REMOVE_RESOURCE_SUCCESS = "REMOVE_RESOURCE_SUCCESS";
export type REMOVE_RESOURCE_SUCCESS = typeof REMOVE_RESOURCE_SUCCESS;
export const REMOVE_RESOURCE_ERROR = "REMOVE_RESOURCE_ERROR";
export type REMOVE_RESOURCE_ERROR = typeof REMOVE_RESOURCE_ERROR;