import React, { FunctionComponent } from 'react';
import { TableCell } from '@material-ui/core';
import FullTableRow from '../../../../shared/FullTable/components/FullTableRow';
import { UserInformationDisplay } from '../../users/redux/reducer';


type Props = {
    userInformation: UserInformationDisplay,
    onUserSelected(user: UserInformationDisplay): void,
}


const AdminAdministratorUserComponent: FunctionComponent<Props> = (props: Props) => {


    const { userInformation } = props;


    const onUserSelected = () => {
        props.onUserSelected(props.userInformation);
    }


    return (
        <FullTableRow
            displayState={userInformation.displayState}
            canSelect={true}
            onSelect={onUserSelected}
        >
            <TableCell>{userInformation.givenName + " " + userInformation.surname}</TableCell>
            <TableCell>{userInformation.loginEmail}</TableCell>
            <TableCell align="center">{userInformation.enabled ? "X" : ""}</TableCell>
        </FullTableRow>
    );


}


export default AdminAdministratorUserComponent;