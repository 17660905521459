import { push, ConnectedRouter } from 'connected-react-router';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import { createMuiTheme, responsiveFontSizes, useMediaQuery, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { history } from './shared/redux/store';


import { loadProfile } from './pages/profile/redux/actions';
import MenuComponent from './Menu/components/MenuComponent';
import AuthenticatedComponent from './shared/AuthenticatedComponent/components/AuthenticatedComponent';
import { Route, Switch } from 'react-router';
import ProfilePage from './pages/profile/components/ProfilePage';
import RedirectPage from './pages/redirect/components/RedirectPage';
import GrantsPage from './pages/grants/components/GrantsPage';
import AppPasswordPage from './pages/app-passwords/components/AppPasswordPage';
import AdminUsersPage from './pages/admin/users/components/AdminUsersPage';
import ErrorComponent from './shared/error/component/ErrorComponent';
import SpinnerComponent from './shared/spinner/component/SpinnerComponent';
import AdminAdministratorsPage from './pages/admin/administrators/components/AdminAdministratorsPage';
import ClientsPage from './pages/admin/clients/components/ClientsPageComponent';


import { orange } from '@material-ui/core/colors';
import ClientPage from './pages/admin/clients/client/components/ClientPageComponent';
import ResourcesPage from './pages/admin/resources/components/ResourcesPageComponent';
import ResourcePage from './pages/admin/resources/resource/components/ResourcePageComponent';
import ResourceScopesPage from './pages/admin/resources/resource/scopes/scope/components/ScopePageComponent';


const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: orange,
        contrastThreshold: 3,
    }
});


const responsiveTheme = responsiveFontSizes(theme);


interface DispatchProps {
    push: (path: string) => void;
    loadProfile: () => void;
}


export type Props = DispatchProps;


const App: FunctionComponent<Props> = (props: Props) => {
    const isSmallDisplay = !useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <ThemeProvider theme={responsiveTheme}>
            <CssBaseline />
            <AuthenticatedComponent>
                <SpinnerComponent></SpinnerComponent>
                <ErrorComponent></ErrorComponent>
                <MenuComponent isSmallDisplay={isSmallDisplay}></MenuComponent>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route exact path="/" component={ProfilePage} />
                        <Route exact path="/redirect" component={RedirectPage} />
                        <Route exact path="/app-passwords" component={AppPasswordPage} />
                        <Route exact path="/grants" component={GrantsPage} />
                        <Route exact path="/administration/administrators" component={AdminAdministratorsPage} />
                        <Route exact path="/administration/clients" component={ClientsPage} />
                        <Route exact path="/administration/clients/:id" component={ClientPage} />
                        <Route exact path="/administration/resources" component={ResourcesPage} />
                        <Route exact path="/administration/resources/:id" component={ResourcePage} />
                        <Route exact path="/administration/resources/:resourceId/scopes/:scopeId" component={ResourceScopesPage} />
                        <Route exact path="/administration/users" component={AdminUsersPage} />
                    </Switch>
                </ConnectedRouter>
            </AuthenticatedComponent>
        </ThemeProvider>
    );
}

export default connect(null, { push, loadProfile })(App);
