import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { TextField, Button } from '@material-ui/core';
import { ClientConfigInformation } from '../../../../../../shared/utils/DataAccess';
import { loadClientPostLogoutRedirectUris, addClientPostLogoutRedirectUris, deleteClientPostLogoutRedirectUris } from '../redux/actions';
import { StoreState } from '../../../../../../shared/redux/store';
import { ClientPostLogoutRedirectUrisState } from '../redux/reducer';
import FullTableComponent, { DisplayType, Column, EnumerationRequest } from '../../../../../../shared/FullTable/components/FullTableComponent';
import ClientPostLogoutRedirectUriComponent from './ClientPostLogoutRedirectUriComponent';


type Props = {
    clientInformation: ClientConfigInformation
}


const ClientPostLogoutRedirectUrisComponent: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();


    const [addCorsOrigin, setaddCorsOrigin] = useState<string>("");


    const clientPostLogoutRedirectUris: ClientPostLogoutRedirectUrisState = useSelector((state: StoreState) => {
        return state.adminClientPostLogoutRedirectUrisReducer;
    });


    const apiEnumerate = (request?: EnumerationRequest) => {
        if (props.clientInformation.id) {
            dispatch(loadClientPostLogoutRedirectUris(props.clientInformation.id));
        }
    };


    const onDeleteClient = (request: number) => {
        dispatch(deleteClientPostLogoutRedirectUris(props.clientInformation.id, request));
    };


    useEffect(() => {
        apiEnumerate();
    }, [props.clientInformation]);


    const hasChanges = (): boolean => {
        return (addCorsOrigin.length > 0);
    }


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setaddCorsOrigin(e.currentTarget.value);
    }


    const onSubmit = () => {
        dispatch(addClientPostLogoutRedirectUris(props.clientInformation.id, addCorsOrigin));

        setaddCorsOrigin("");
    }


    const Columns: Column[] = [
        { name: "Allowed Cors Origin", displayName: "Allowed Cors Origin", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Delete", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
    ];


    const classes = makeStyles({
        row: {
            maxWidth: "500px"
        }
    })();


    return (
        <>
            <div className={classes.row}>
                <FullTableComponent Columns={Columns} apiEnumerate={apiEnumerate}>
                    {clientPostLogoutRedirectUris ? clientPostLogoutRedirectUris.list.allIds.map(id => (
                        <ClientPostLogoutRedirectUriComponent
                            key={id}
                            PostLogoutRedirectUri={clientPostLogoutRedirectUris.list.byId[id]}
                            onDelete={onDeleteClient}
                        ></ClientPostLogoutRedirectUriComponent>
                    )) : ""}
                </FullTableComponent>
            </div>
            <div className={classes.row} style={{ marginTop: '50px' }}>
                <TextField
                    id="newPostLogoutRedirectUri"
                    label="New Post Logout Redirect Uri"
                    fullWidth={true}
                    value={addCorsOrigin}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row} style={{ textAlign: 'right' }}>
                <Button variant="outlined" color="primary" onClick={onSubmit} disabled={!hasChanges()}>Add Allowed Post Logout Redirect Uri</Button>
            </div>
        </>
    );


}


export default ClientPostLogoutRedirectUrisComponent;