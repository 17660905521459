import { AppPasswordsAction } from "./actions";
import { AppPasswordInformation, NewAppPasswordInformation } from "../../../shared/utils/DataAccess";
import {
    ENUMERATE_APP_PASSWORDS_SUCCESS, ENUMERATE_APP_PASSWORDS_ERROR,
    ADD_APP_PASSWORD_SUCCESS, ADD_APP_PASSWORD_ERROR,
    REMOVE_APP_PASSWORD_SUCCESS, REMOVE_APP_PASSWORD_ERROR,
} from "./actionDef";
import _ from "lodash";


export interface AppPasswordsState {
    list: {
        byId: {
            [id: string]: AppPasswordInformation
        };
        allIds: string[];
    };
    newAppPassword?: NewAppPasswordInformation;
};


export const initialState: AppPasswordsState = {
    list: {
        byId: {},
        allIds: []
    },
    newAppPassword: undefined
};


export function appPasswordsReducer(state: AppPasswordsState = initialState, action: AppPasswordsAction): AppPasswordsState {
    switch (action.type) {
        case ENUMERATE_APP_PASSWORDS_SUCCESS:
            const appPasswords = action.payload as AppPasswordInformation[];
            return {
                ...state,
                list: {
                    byId: {
                        ..._.keyBy(appPasswords, "id")
                    },
                    allIds: _.map(appPasswords, "id")
                }
            };
        case ADD_APP_PASSWORD_SUCCESS:
            const newAppPassword = action.payload as NewAppPasswordInformation;
            const appPassword = new AppPasswordInformation(action.payload as NewAppPasswordInformation);
            return {
                ...state,
                list: {
                    byId: {
                        ...state.list.byId,
                        [appPassword.id]: appPassword
                    },
                    allIds: state.list.allIds.concat(appPassword.id)
                },
                newAppPassword: newAppPassword
            };
        case REMOVE_APP_PASSWORD_SUCCESS:
            const newAllIds = [...state.list.allIds];
            const idIndex = newAllIds.findIndex(el => el === action.payload as string);
            newAllIds.splice(idIndex, 1);

            const newByids = { ...state.list.byId };
            delete newByids[action.payload as string];

            return {
                ...state,
                list: {
                    byId: {
                        ...newByids
                    },
                    allIds: newAllIds
                }
            };
        case ENUMERATE_APP_PASSWORDS_ERROR:
        case ADD_APP_PASSWORD_ERROR:
        case REMOVE_APP_PASSWORD_ERROR:
        default:
            return state;
    }
}