import React, { FunctionComponent } from 'react';
import { ResourceInformation } from '../../../../shared/utils/DataAccess';
import { TableCell } from '@material-ui/core';
import FullTableRow, { DisplayState } from '../../../../shared/FullTable/components/FullTableRow';


type Props = {
    resourceInformation: ResourceInformation,
    onSelect(id: number): void
    onDelete(id: number): void
}


const ResourceComponent: FunctionComponent<Props> = (props: Props) => {


    const { resourceInformation } = props;


    const onSelect = () => {
        props.onSelect(resourceInformation.id);
    }


    const onDelete = () => {
        props.onDelete(resourceInformation.id);
    }


    return (
        <FullTableRow displayState={DisplayState.ReadOnly} canEdit={true} canSelect={true} canDelete={true} onSelect={onSelect} onDelete={onDelete} >
            <TableCell component="th" scope="row">{resourceInformation.displayName}</TableCell>
            <TableCell component="th" scope="row">{(new Date(resourceInformation.created * 1000)).toLocaleString()}</TableCell>
            <TableCell component="th" scope="row">{resourceInformation.updated === 0 ? "Never" : (new Date(resourceInformation.updated * 1000)).toLocaleString()}</TableCell>
            <TableCell component="th" scope="row">{resourceInformation.lastAccessed === 0 ? "Never" : (new Date(resourceInformation.lastAccessed * 1000)).toLocaleString()}</TableCell>
        </FullTableRow>
    );


}


export default ResourceComponent;