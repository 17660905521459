import { AddClientPropertyRequest, ClientPropertyInformation, UpdateClientPropertyRequest } from "../../../../../../shared/utils/DataAccess";
import { ENUMERATE_CLIENT_PROPERTIES, ADD_CLIENT_PROPERTY, DELETE_CLIENT_PROPERTY, DISPLAY_STATE_CHANGE, UPDATE_CLIENT_PROPERTY } from "./actionDef";
import { action } from "typesafe-actions";
import { DisplayState } from "../../../../../../shared/FullTable/components/FullTableRow";


export interface AdminClientPropertiesAction {
    type: string;
    payload?:
    number | ChangeEditState |
    AddPropertyRequestWithClientId | DeletePropertyRequest | AddClientPropertyRequest | UpdateClientPropertyRequestWithClientId |
    ClientPropertyInformation | ClientPropertyInformation[];
}


export interface AddPropertyRequestWithClientId {
    clientId: number,
    request: AddClientPropertyRequest
}


export interface UpdateClientPropertyRequestWithClientId {
    clientId: number,
    id: number,
    request: UpdateClientPropertyRequest
}


export interface DeletePropertyRequest {
    clientId: number,
    propertyId: number
}


export interface ChangeEditState {
    id: number;
    displayState: DisplayState;
}


export const loadClientProperties = (clientId: number): AdminClientPropertiesAction => action(ENUMERATE_CLIENT_PROPERTIES, clientId);
export const addClientProperty = (clientId: number, request: AddClientPropertyRequest): AdminClientPropertiesAction =>
    action(
        ADD_CLIENT_PROPERTY,
        { clientId: clientId, request: request } as AddPropertyRequestWithClientId);
export const deleteClientProperty = (clientId: number, propertyId: number): AdminClientPropertiesAction =>
    action(DELETE_CLIENT_PROPERTY,
        { clientId: clientId, propertyId: propertyId });

export const updateClientProperty = (clientId: number, id: number, request: UpdateClientPropertyRequest): AdminClientPropertiesAction =>
    action(
        UPDATE_CLIENT_PROPERTY,
        { clientId: clientId, id: id, request: request } as UpdateClientPropertyRequestWithClientId);

export const editStart = (id: number): AdminClientPropertiesAction => action(DISPLAY_STATE_CHANGE, { id: id, displayState: DisplayState.Edit });
export const editChanges = (id: number, hasChanges: boolean) => action(DISPLAY_STATE_CHANGE, { id: id, displayState: hasChanges ? DisplayState.NotSaved : DisplayState.Edit });
export const editCancel = (id: number): AdminClientPropertiesAction => action(DISPLAY_STATE_CHANGE, { id: id, displayState: DisplayState.ReadOnly });