import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { StoreState } from '../../../../shared/redux/store';

import FullTableComponent, { DisplayType, Column, EnumerationRequest } from '../../../../shared/FullTable/components/FullTableComponent';
import { ClientConfigInformation, ConfigCreateDto } from '../../../../shared/utils/DataAccess';
import { AdminClientListState } from '../redux/reducer';
import { enumerateClients, removeClient, addClient } from '../redux/actions';
import ClientComponent from './ClientComponent';
import AddClientComponent from './AddClientComponent';

import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';


const ClientsPage: FunctionComponent = () => {
    const dispatch = useDispatch();


    const [isDialogOpen, setisDialogOpen] = useState<boolean>(false);


    const adminClientListState: AdminClientListState = useSelector((state: StoreState) => {
        return state.adminClientsReducer
    });


    const Columns: Column[] = [
        { name: "Edit", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Name", displayName: "Name", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Lifetime", displayName: "Lifetime", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "RequirePkce", displayName: "Require Pkce", sortable: false, displayType: DisplayType.Always, textAlign: "center" },
        { name: "RequireClientSecret", displayName: "Require Client Secret", sortable: false, displayType: DisplayType.Always, textAlign: "center" },
        { name: "AllowOfflineAccess", displayName: "Allow Offline Access", sortable: false, displayType: DisplayType.Always, textAlign: "center" },
        { name: "AllowAccessTokensViaBrowser", displayName: "Allow Access Tokens Via Browser", sortable: false, displayType: DisplayType.Always, textAlign: "center" },
        { name: "Delete", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
    ];


    useEffect(() => {
        dispatch(enumerateClients());
    }, []);


    const apiEnumerate = (request: EnumerationRequest) => {
        dispatch(enumerateClients());
    };


    const onOpenAddClientDialog = () => {
        setisDialogOpen(true);
    }


    const onCloseAddClientDialog = () => {
        setisDialogOpen(false);
    }


    const onAddClient = (client: ClientConfigInformation) => {
        const request: ConfigCreateDto = ConfigCreateDto.fromJS({
            clientId: client.clientId,
            clientName: client.clientName,
            allowOfflineAccess: client.allowOfflineAccess,
            requirePkce: client.requirePkce,
            requireClientSecret: client.requireClientSecret,
            allowAccessTokensViaBrowser: client.allowAccessTokensViaBrowser,
        });

        dispatch(addClient(request));

        setisDialogOpen(false);
    }


    const onSelectClient = (id: number) => {
        dispatch(push("/administration/clients/" + id.toString()));
    }


    const onDeleteClient = (id: number) => {
        dispatch(removeClient(id));

        setisDialogOpen(false);
    }


    const classes = makeStyles({
        page: {
            width: '90%',
            margin: '80px auto 0px',
            padding: '20px'
        },
    })();


    return (
        <Paper className={classes.page}>

            <h1>Clients</h1>
            Below is a list of all clients.

            <FullTableComponent Columns={Columns} apiEnumerate={apiEnumerate}>
                {adminClientListState ? adminClientListState.list.allIds.map(id => (
                    <ClientComponent
                        key={id}
                        clientInformation={adminClientListState.list.byId[id]}
                        onSelect={onSelectClient}
                        onDelete={onDeleteClient}
                    ></ClientComponent>
                )) : ""}
            </FullTableComponent>
            <div style={{ textAlign: 'center', marginTop: '20px' }}><Button variant="outlined" onClick={onOpenAddClientDialog}>Add Client</Button></div>
            <AddClientComponent visible={isDialogOpen} onAddClient={onAddClient} onClose={onCloseAddClientDialog}></AddClientComponent>
        </Paper>
    );


}


export default ClientsPage;