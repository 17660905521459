export const UPDATE_RESOURCE_SCOPE = "UPDATE_RESOURCE_SCOPE";
export type UPDATE_RESOURCE_SCOPE = typeof UPDATE_RESOURCE_SCOPE;
export const UPDATE_RESOURCE_SCOPE_SUCCESS = "UPDATE_RESOURCE_SCOPE_SUCCESS";
export type UPDATE_RESOURCE_SCOPE_SUCCESS = typeof UPDATE_RESOURCE_SCOPE_SUCCESS;
export const UPDATE_RESOURCE_SCOPE_ERROR = "UPDATE_RESOURCE_SCOPE_ERROR";
export type UPDATE_RESOURCE_SCOPE_ERROR = typeof UPDATE_RESOURCE_SCOPE_ERROR;

export const LOAD_RESOURCE_SCOPE = "LOAD_RESOURCE_SCOPE";
export type LOAD_RESOURCE_SCOPE = typeof LOAD_RESOURCE_SCOPE;
export const LOAD_RESOURCE_SCOPE_SUCCESS = "LOAD_RESOURCE_SCOPE_SUCCESS";
export type LOAD_RESOURCE_SCOPE_SUCCESS = typeof LOAD_RESOURCE_SCOPE_SUCCESS;
export const LOAD_RESOURCE_SCOPE_ERROR = "LOAD_RESOURCE_SCOPE_ERROR";
export type LOAD_RESOURCE_SCOPE_ERROR = typeof LOAD_RESOURCE_SCOPE_ERROR;
