import React, { FunctionComponent, useState } from 'react';

import { ClientConfigInformation } from '../../../../shared/utils/DataAccess';

import TextField from '@material-ui/core/TextField';
import { Button, Checkbox, Dialog, DialogTitle, FormControlLabel, DialogContent, DialogActions, makeStyles } from '@material-ui/core';


interface StateProps {
    visible: boolean,
    onAddClient(addClientRequest: ClientConfigInformation): void,
    onClose(): void
}


export type Props = StateProps;


const AddClientComponent: FunctionComponent<Props> = (props: Props) => {


    const CreateNewAddClientRequest = () => {
        return ClientConfigInformation.fromJS({
            id: 0,
            clientId: "",
            clientName: "",
            allowOfflineAccess: false,
            allowAccessTokensViaBrowser: false,
            requirePkce: false,
            requireClientSecret: false,
            consentLifetime: 0,
        });
    }


    const [addClientRequest, setaddClientRequest] = useState<ClientConfigInformation>(CreateNewAddClientRequest());


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAddClientRequest: ClientConfigInformation = ClientConfigInformation.fromJS({
            ...addClientRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setaddClientRequest(newAddClientRequest);
    }


    const onCheckFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAddClientRequest: ClientConfigInformation = ClientConfigInformation.fromJS({
            ...addClientRequest,
            [e.currentTarget.id]: e.currentTarget.checked
        });

        setaddClientRequest(newAddClientRequest);
    }


    const onCancel = () => {
        props.onClose();
    }


    const onSubmit = () => {
        props.onAddClient(addClientRequest);

        setaddClientRequest(CreateNewAddClientRequest());
    }


    const classes = makeStyles({
        row: {
            flex: 1,
            flexDirection: "row",
            clear: 'both',
            marginBottom: '5px'
        },
    })();


    return (
        <Dialog open={props.visible}>
            <DialogTitle>Add New Client</DialogTitle>
            <DialogContent>
                <div className={classes.row}>
                    <TextField
                        id="clientId"
                        label="Client Id"
                        value={addClientRequest.clientId}
                        onChange={onTextFieldChange}
                    />
                </div>
                <div className={classes.row}>
                    <TextField
                        id="clientName"
                        label="Name"
                        value={addClientRequest.clientName}
                        onChange={onTextFieldChange}
                    />
                </div>
                <div className={classes.row}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="allowAccessTokensViaBrowser"
                                checked={addClientRequest.allowAccessTokensViaBrowser}
                                onChange={onCheckFieldChange}
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                                style={{ float: 'right' }}
                            />
                        }
                        label="Allow Access Tokens Via Browser"
                    />
                </div>
                <div className={classes.row}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="allowOfflineAccess"
                                checked={addClientRequest.allowOfflineAccess}
                                onChange={onCheckFieldChange}
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                                style={{ float: 'right' }}
                            />
                        }
                        label="Allow Offline Access"
                    />
                </div>
                <div className={classes.row}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="requireClientSecret"
                                checked={addClientRequest.requireClientSecret}
                                onChange={onCheckFieldChange}
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                                style={{ float: 'right' }}
                            />
                        }
                        label="Require Client Secret"
                    />
                </div>
                <div className={classes.row}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="requirePkce"
                                checked={addClientRequest.requirePkce}
                                onChange={onCheckFieldChange}
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                                style={{ float: 'right' }}
                            />
                        }
                        label="Require Pkce"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onCancel}>Cancel</Button>
                <Button color="primary" onClick={onSubmit}>Create Client</Button>
            </DialogActions>
        </Dialog>
    );


};


export default AddClientComponent;