import React, { FunctionComponent } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { NewAppPasswordInformation } from '../../../shared/utils/DataAccess';
import { makeStyles } from '@material-ui/styles';


export interface NewAppPasswordComponentProps {
    open: boolean;
    newAppPassword: NewAppPasswordInformation;
    onClose: () => void;
}


const NewAppPasswordComponent: FunctionComponent<NewAppPasswordComponentProps> = (props: NewAppPasswordComponentProps) => {


    const handleClose = () => {
        props.onClose();
    }


    const classes = makeStyles({
        box: {
            width: '95%',
            padding: '10px'
        },
        leftColumn: {
            fontWeight: 'bold',
            margin: '5px'
        },
        rightColumn: {
            margin: '5px'
        },
        warning: {
            fontWeight: 'bold',
            fontSize: '1.5em',
            textAlign: 'center'
        },
        button: {
            textAlign: 'right'
        }
    })();


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.open}>
            <DialogTitle id="simple-dialog-title">Application Password Created</DialogTitle>

            <div className={classes.box}>
                <div className={classes.leftColumn}>Application:</div>
                <div className={classes.rightColumn}>{props.newAppPassword.appName}</div>
                <div className={classes.leftColumn}>Created:</div>
                <div className={classes.rightColumn}>{props.newAppPassword.dateCreated.toString()}</div>
                <div className={classes.leftColumn}>Expires:</div>
                <div className={classes.rightColumn}>{props.newAppPassword.dateExpires.toString()}</div>
                <div className={classes.leftColumn}>Password:</div>
                <div className={classes.rightColumn}>{props.newAppPassword.password}</div>

                <div className={classes.warning}>Password will not be available again!</div>
            </div>

            <Button variant="outlined" className={classes.button} onClick={handleClose}>Finished</Button>
        </Dialog>
    );


}


export default NewAppPasswordComponent;