export const DISPLAY_STATE_CHANGE = "DISPLAY_STATE_CHANGE";
export type DISPLAY_STATE_CHANGE = typeof DISPLAY_STATE_CHANGE;

export const ENUMERATE_RESOURCE_PROPERTIES = "ENUMERATE_RESOURCE_PROPERTIES";
export type ENUMERATE_RESOURCE_PROPERTIES = typeof ENUMERATE_RESOURCE_PROPERTIES;
export const ENUMERATE_RESOURCE_PROPERTIES_SUCCESS = "ENUMERATE_RESOURCE_PROPERTIES_SUCCESS";
export type ENUMERATE_RESOURCE_PROPERTIES_SUCCESS = typeof ENUMERATE_RESOURCE_PROPERTIES_SUCCESS;
export const ENUMERATE_RESOURCE_PROPERTIES_ERROR = "ENUMERATE_RESOURCE_PROPERTIES_ERROR";
export type ENUMERATE_RESOURCE_PROPERTIES_ERROR = typeof ENUMERATE_RESOURCE_PROPERTIES_ERROR;

export const ADD_RESOURCE_PROPERTY = "ADD_RESOURCE_PROPERTY";
export type ADD_RESOURCE_PROPERTY = typeof ADD_RESOURCE_PROPERTY;
export const ADD_RESOURCE_PROPERTY_SUCCESS = "ADD_RESOURCE_PROPERTY_SUCCESS";
export type ADD_RESOURCE_PROPERTY_SUCCESS = typeof ADD_RESOURCE_PROPERTY_SUCCESS;
export const ADD_RESOURCE_PROPERTY_ERROR = "ADD_RESOURCE_PROPERTY_ERROR";
export type ADD_RESOURCE_PROPERTY_ERROR = typeof ADD_RESOURCE_PROPERTY_ERROR;

export const UPDATE_RESOURCE_PROPERTY = "UPDATE_RESOURCE_PROPERTY";
export type UPDATE_RESOURCE_PROPERTY = typeof UPDATE_RESOURCE_PROPERTY;
export const UPDATE_RESOURCE_PROPERTY_SUCCESS = "UPDATE_RESOURCE_PROPERTY_SUCCESS";
export type UPDATE_RESOURCE_PROPERTY_SUCCESS = typeof UPDATE_RESOURCE_PROPERTY_SUCCESS;
export const UPDATE_RESOURCE_PROPERTY_ERROR = "UPDATE_RESOURCE_PROPERTY_ERROR";
export type UPDATE_RESOURCE_PROPERTY_ERROR = typeof UPDATE_RESOURCE_PROPERTY_ERROR;

export const DELETE_RESOURCE_PROPERTY = "DELETE_RESOURCE_PROPERTY";
export type DELETE_RESOURCE_PROPERTY = typeof DELETE_RESOURCE_PROPERTY;
export const DELETE_RESOURCE_PROPERTY_SUCCESS = "DELETE_RESOURCE_PROPERTY_SUCCESS";
export type DELETE_RESOURCE_PROPERTY_SUCCESS = typeof DELETE_RESOURCE_PROPERTY_SUCCESS;
export const DELETE_RESOURCE_PROPERTY_ERROR = "DELETE_RESOURCE_PROPERTY_ERROR";
export type DELETE_RESOURCE_PROPERTY_ERROR = typeof DELETE_RESOURCE_PROPERTY_ERROR;
