import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { Button, TextField } from '@material-ui/core';
import { ClientConfigInformation, AddClientsAllowedScope } from '../../../../../../shared/utils/DataAccess';
import { StoreState } from '../../../../../../shared/redux/store';
import FullTableComponent, { DisplayType, Column } from '../../../../../../shared/FullTable/components/FullTableComponent';
import ClientAllowedScopeComponent from './ClientAllowedScopeComponent';
import { AdminClientAllowedScopesState } from '../redux/reducer';
import { loadClientAllowedScopes, deleteClientAllowedScopes, addClientAllowedScopes } from '../redux/actions';


type Props = {
    clientInformation: ClientConfigInformation
}


const ClientAllowedScopesComponent: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();


    const [addScope, setaddScope] = useState<AddClientsAllowedScope>(AddClientsAllowedScope.fromJS({
        scope: ""
    }));


    const clientAllowedScopes: AdminClientAllowedScopesState = useSelector((state: StoreState) => {
        return state.adminClientAllowedScopesReducer;
    });


    const apiEnumerate = () => {
        if (props.clientInformation.id) {
            dispatch(loadClientAllowedScopes(props.clientInformation.id));
        }
    };


    const onDeleteClient = (request: number) => {
        dispatch(deleteClientAllowedScopes(props.clientInformation.id, request));
    };


    useEffect(() => {
        apiEnumerate();
    }, [props.clientInformation]);


    const hasChanges = (): boolean => {
        return (addScope.scope.length > 0);
    }


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        var newScope: AddClientsAllowedScope = AddClientsAllowedScope.fromJS({
            scope: e.currentTarget.value as string
        });

        setaddScope(newScope);
    }


    const onSubmit = () => {
        dispatch(addClientAllowedScopes(props.clientInformation.id, addScope));

        setaddScope(AddClientsAllowedScope.fromJS({
            scope: ""
        }));
    }


    const Columns: Column[] = [
        { name: "Allowed Scope", displayName: "Allowed Scope", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Delete", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
    ];


    const classes = makeStyles({
        row: {
            maxWidth: "500px"
        }
    })();


    return (
        <>
            <div className={classes.row}>
                <FullTableComponent Columns={Columns} apiEnumerate={apiEnumerate}>
                    {clientAllowedScopes ? clientAllowedScopes.list.allIds.map(id => (
                        <ClientAllowedScopeComponent
                            key={id}
                            allowedScope={clientAllowedScopes.list.byId[id]}
                            onDelete={onDeleteClient}
                        ></ClientAllowedScopeComponent>
                    )) : ""}
                </FullTableComponent>
            </div>
            <div className={classes.row} style={{ marginTop: '50px' }}>
                <TextField
                    id="newAllowedScope"
                    label="New Allowed Scope"
                    fullWidth={true}
                    value={addScope.scope}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row} style={{ textAlign: 'right' }}>
                <Button variant="outlined" color="primary" onClick={onSubmit} disabled={!hasChanges()}>Add Allowed Scope</Button>
            </div>
        </>
    );


}


export default ClientAllowedScopesComponent;