import { Middleware } from 'redux';

import {
    ClientsAllowedScope
} from '../../../../../../shared/utils/DataAccess';
import {
    ENUMERATE_CLIENT_SCOPES, ENUMERATE_CLIENT_SCOPES_SUCCESS, ENUMERATE_CLIENT_SCOPES_ERROR,
    ADD_CLIENT_SCOPE, ADD_CLIENT_SCOPE_SUCCESS, ADD_CLIENT_SCOPE_ERROR,
    DELETE_CLIENT_SCOPE, DELETE_CLIENT_SCOPE_SUCCESS, DELETE_CLIENT_SCOPE_ERROR
} from './actionDef';
import { AdminClientScopesAction, AddScopeRequestWithClientId, DeleteScopeRequest } from './actions';
import APIClientFactory from '../../../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../../../shared/spinner/redux/reducer';



const adminClientAllowedScopesDataService: Middleware = store => next => (requestedAction: AdminClientScopesAction) => {
    next(requestedAction);

    const client = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ENUMERATE_CLIENT_SCOPES:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));
            client.enumerateClientScopes(requestedAction.payload as number)
                .then((scopes: ClientsAllowedScope[]) => {
                    store.dispatch(action(ENUMERATE_CLIENT_SCOPES_SUCCESS, scopes));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_CLIENT_SCOPES_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ADD_CLIENT_SCOPE:
            const addWithID: AddScopeRequestWithClientId = (requestedAction.payload as AddScopeRequestWithClientId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.createClientAllowedScope(addWithID.clientId, addWithID.request)
                .then((newScope: ClientsAllowedScope) => {
                    store.dispatch(action(ADD_CLIENT_SCOPE_SUCCESS, newScope));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_CLIENT_SCOPE_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;

        case DELETE_CLIENT_SCOPE:
            const deleteWithID: DeleteScopeRequest = (requestedAction.payload as DeleteScopeRequest);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.removeClientAllowedScope(deleteWithID.clientId, deleteWithID.scopeId)
                .then(() => {
                    store.dispatch(action(DELETE_CLIENT_SCOPE_SUCCESS, deleteWithID.scopeId));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(DELETE_CLIENT_SCOPE_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminClientAllowedScopesDataService;