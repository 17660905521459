import { AddPostLogoutRedirectUriRequest, PostLogoutRedirectUri } from "../../../../../../shared/utils/DataAccess";
import { ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS, ADD_CLIENT_POST_LOGOUT_REDIRECT_URI, DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI } from "./actionDef";
import { action } from "typesafe-actions";


export interface AdminClientPostLogoutRedirectUrisAction {
    type: string;
    payload?: number | AddPostLogoutRedirectUriRequestWithClientId | DeletePostLogoutRedirectUriRequest | PostLogoutRedirectUri | PostLogoutRedirectUri[];
}


export interface AddPostLogoutRedirectUriRequestWithClientId {
    clientId: number,
    request: AddPostLogoutRedirectUriRequest
}


export interface DeletePostLogoutRedirectUriRequest {
    clientId: number,
    postLogoutRedirectUriId: number
}


export const loadClientPostLogoutRedirectUris = (clientId: number): AdminClientPostLogoutRedirectUrisAction => action(ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS, clientId);
export const addClientPostLogoutRedirectUris = (clientId: number, postLogoutRedirectUri: string): AdminClientPostLogoutRedirectUrisAction =>
    action(
        ADD_CLIENT_POST_LOGOUT_REDIRECT_URI,
        { clientId: clientId, request: { redirectUri: postLogoutRedirectUri } as AddPostLogoutRedirectUriRequest } as AddPostLogoutRedirectUriRequestWithClientId);
export const deleteClientPostLogoutRedirectUris = (clientId: number, postLogoutRedirectUriId: number): AdminClientPostLogoutRedirectUrisAction =>
    action(DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI,
        { clientId: clientId, postLogoutRedirectUriId: postLogoutRedirectUriId });

