import { APIClient } from "./DataAccess";


class APIClientFactory {


    private static _apiClient: APIClient;


    public static getInstance(): APIClient {
        if (APIClientFactory._apiClient === undefined) {
            APIClientFactory._apiClient = new APIClient(
                process.env.NODE_ENV === "production" ?
                    process.env.REACT_APP_PROD_API_URL :
                    process.env.REACT_APP_DEV_API_URL
            );
        }
        return APIClientFactory._apiClient;
    }


}


export default APIClientFactory;