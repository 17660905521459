import { action } from "typesafe-actions";
import { DISPLAY_STATE_CHANGE, ENUMERATE_USERS, ADD_USER, UPDATE_USER, REMOVE_USER } from "./actionDef";
import { AddUserRequest, UpdateUserRequest, UserList, Sort, Order } from "../../../../shared/utils/DataAccess";
import { DisplayState } from "../../../../shared/FullTable/components/FullTableRow";


export interface AdminUsersAction {
    type: string;
    payload?: string | AddUserRequest | UpdateUserRequestWithId | UserList | EnumerateUsersRequest | ChangeEditState | AdminUsersError;
}


export interface AdminUsersError {
    id: string;
    reason: any;
}


export interface UpdateUserRequestWithId {
    id: string;
    updateUserRequest: UpdateUserRequest;
}


export interface ChangeEditState {
    id: string;
    displayState: DisplayState;
}


export interface EnumerateUsersRequest {
    search: string | undefined;
    page: number | undefined;
    pageSize: number | undefined;
    sort: Sort | undefined;
    order: Order | undefined;
}


export const enumerateUsers = (request: EnumerateUsersRequest): AdminUsersAction => action(ENUMERATE_USERS, request);
export const addUser = (request: AddUserRequest): AdminUsersAction => action(ADD_USER, request);
export const updateUser = (request: UpdateUserRequestWithId): AdminUsersAction => action(UPDATE_USER, request);
export const removeUser = (id: string): AdminUsersAction => action(REMOVE_USER, id);
export const editStart = (id: string): AdminUsersAction => action(DISPLAY_STATE_CHANGE, { id: id, displayState: DisplayState.Edit });
export const editChanges = (id: string, hasChanges: boolean) => action(DISPLAY_STATE_CHANGE, { id: id, displayState: hasChanges ? DisplayState.NotSaved : DisplayState.Edit });
export const editCancel = (id: string): AdminUsersAction => action(DISPLAY_STATE_CHANGE, { id: id, displayState: DisplayState.ReadOnly });