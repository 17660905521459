import * as Oidc from 'oidc-client';

// export const oidcConfig = {
//     authority: "https://auth.services.peakey.com",
//     // authority: "https://auth.services.peakey.com",
//     client_id: "00000000-0000-0000-0000-000000000001:web:auth.services.peakey.com",
//     redirect_uri: "https://auth.services.peakey.com/redirect",
//     response_type: "code",
//     scope: "openid profile auth.services.peakey.com",
//     post_logout_redirect_uri: "https://auth.services.peakey.com/",
//     filterProtocolClaims: true,
//     loadUserInfo: true
// }

export const oidcConfig = {
    authority: "https://auth.services.peakey.com",
    // authority: "https://auth.services.peakey.com",
    client_id: "00000000-0000-0000-0000-000000000001:web:auth.services.peakey.com",
    redirect_uri: "https://auth.services.peakey.com/redirect",
    response_type: "code",
    scope: "openid profile auth.services.peakey.com",
    post_logout_redirect_uri: "https://auth.services.peakey.com/",
    filterProtocolClaims: true,
    loadUserInfo: true
}

const userManager = new Oidc.UserManager(oidcConfig);

//KEEP THIS COMMENT, this is where can hook into interesting things.
//Example below...
// userManager.events.addAccessTokenExpiring(() => {
//     userManager.signinSilent({ scope: oidcConfig.scope, response_type: oidcConfig.response_type })
//         .then((user: Oidc.User) => {
//             // do something with user
//         })
//         .catch((error: Error) => {
//             userManager.getUser()
//                 .then((user: Oidc.User | null) => {
//                     // do something with user
//                 });
//         });
// });

export default userManager