import { action } from "typesafe-actions";
import { ENUMERATE_APP_PASSWORDS, ADD_APP_PASSWORD, REMOVE_APP_PASSWORD } from "./actionDef";
import { AppPasswordInformation, AddAppPasswordRequest, NewAppPasswordInformation } from "../../../shared/utils/DataAccess";


export interface AppPasswordsAction {
    type: string;
    payload?: string | AddAppPasswordRequest | AppPasswordInformation[] | NewAppPasswordInformation;
}

export const enumerateAppPasswords = (): AppPasswordsAction => action(ENUMERATE_APP_PASSWORDS);
export const addAppPassword = (request: AddAppPasswordRequest): AppPasswordsAction => action(ADD_APP_PASSWORD, request);
export const removeAppPassword = (id: string): AppPasswordsAction => action(REMOVE_APP_PASSWORD, id);