export const LOAD_PROFILE = "LOAD_PROFILE";
export type LOAD_PROFILE = typeof LOAD_PROFILE;
export const LOAD_PROFILE_SUCCESS = "LOAD_PROFILE_SUCCESS";
export type LOAD_PROFILE_SUCCESS = typeof LOAD_PROFILE_SUCCESS;
export const LOAD_PROFILE_ERROR = "LOAD_PROFILE_ERROR";
export type LOAD_PROFILE_ERROR = typeof LOAD_PROFILE_ERROR;

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export type UPDATE_PROFILE = typeof UPDATE_PROFILE;
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export type UPDATE_PROFILE_SUCCESS = typeof UPDATE_PROFILE_SUCCESS;
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
export type UPDATE_PROFILE_ERROR = typeof UPDATE_PROFILE_ERROR;