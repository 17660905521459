import React, { FunctionComponent } from 'react';
import { Paper, TextField, IconButton, Table, TableHead, TableRow, TableSortLabel, TableCell, TableFooter, TableBody } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Order } from '../../utils/DataAccess';
import TablePaginationComponent, { PagingInformation } from './TablePaginationComponent';


export enum DisplayType {
    Always,
    Large
}


export interface Column {
    name: string;
    displayName: string;
    sortable: boolean;
    displayType: DisplayType;
    textAlign: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
}


export interface EnumerationRequest {
    search: string | undefined;
    sort: string | undefined;
    order: Order | undefined;
    paging: PagingInformation;
}


interface StateProps {
    enumerationRequest?: EnumerationRequest,
    Columns: Column[],
    children: React.ReactNode,
    showSearch?: boolean,
    showPaging?: boolean
}


interface DispatchProps {
    apiEnumerate: (request: EnumerationRequest) => void;
}


export type Props = StateProps & DispatchProps;


const FullTableComponent: FunctionComponent<Props> = (props: Props) => {


    const getEnumerateRequest = () => {
        return props.enumerationRequest ||
            {
                search: "",
                sort: "",
                order: Order.Asc,
                paging: {
                    pageSizes: [99999999],
                    pageSize: 99999999,
                    page: 0,
                    totalRecords: 99999999
                }
            };
    }


    const onPagingChanged = (request: PagingInformation) => {
        var enumerationRequest = getEnumerateRequest();
        enumerationRequest.paging = request;
        props.apiEnumerate(enumerationRequest);
    };


    const handleSortChange = (newSortColumn: string) => {
        var enumerationRequest = getEnumerateRequest();
        if (enumerationRequest.sort !== newSortColumn) {
            enumerationRequest.sort = newSortColumn;
            enumerationRequest.order = Order.Asc;
        } else {
            if (enumerationRequest.order === Order.Asc) {
                enumerationRequest.order = Order.Desc;
            } else {
                enumerationRequest.order = Order.Asc;
            }
        }

        props.apiEnumerate(enumerationRequest);
    }


    const handleFilterChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSearch: string = e.currentTarget.value;
        var enumerationRequest = getEnumerateRequest();
        if (newSearch.length === 0) {
            enumerationRequest.search = undefined;
        } else {
            enumerationRequest.search = newSearch;
        }

        props.apiEnumerate(enumerationRequest);
    }


    const { Columns } = props;
    var request = getEnumerateRequest();

    return (
        <Paper style={{ marginTop: '10px' }}>
            <div style={{ textAlign: 'right', display: props.showSearch ? "" : "none" }}>
                <TextField
                    id="searchField"
                    label="Search"
                    onChange={handleFilterChanged}
                >
                </TextField>
                <IconButton aria-label="search">
                    <SearchIcon />
                </IconButton>
            </div>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        {Columns ? Columns.map(column => (
                            <TableCell key={column.name} align={column.textAlign}>
                                {column.sortable ?
                                    <TableSortLabel
                                        active={request.sort === column.name}
                                        direction={request.order}
                                        onClick={(event: React.MouseEvent<unknown>) => { handleSortChange(column.name); }}
                                    >{column.displayName}</TableSortLabel>
                                    : column.displayName}
                            </TableCell>
                        )) : ""}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.children}
                </TableBody>
                <TableFooter style={{ display: props.showPaging ? "" : "none" }}>
                    <TableRow>
                        <TablePaginationComponent pagingInformation={request.paging} onPagingChanged={onPagingChanged}></TablePaginationComponent>
                    </TableRow>
                </TableFooter>
            </Table>
        </Paper >);


}


export default FullTableComponent