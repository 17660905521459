import { Middleware } from 'redux';

import {
    AllowedCorsOrigin
} from '../../../../../../shared/utils/DataAccess';
import {
    ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS, ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_SUCCESS, ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_ERROR,
    ADD_CLIENT_ALLOWED_CORS_ORIGIN, ADD_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS, ADD_CLIENT_ALLOWED_CORS_ORIGIN_ERROR,
    DELETE_CLIENT_ALLOWED_CORS_ORIGIN, DELETE_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS, DELETE_CLIENT_ALLOWED_CORS_ORIGIN_ERROR
} from './actionDef';
import { AdminClientAllowedCorsOriginsAction, AddAllowedCorsOriginRequestWithClientId, DeleteAllowedCorsOriginRequest } from './actions';
import APIClientFactory from '../../../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../../../shared/spinner/redux/reducer';



const adminClientAllowedCorsOriginsDataService: Middleware = store => next => (requestedAction: AdminClientAllowedCorsOriginsAction) => {
    next(requestedAction);

    const client = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.enumerateCorsOrigin(requestedAction.payload as number)
                .then((allowedCorsOrigins: AllowedCorsOrigin[]) => {
                    store.dispatch(action(ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_SUCCESS, allowedCorsOrigins));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ADD_CLIENT_ALLOWED_CORS_ORIGIN:
            const addWithID: AddAllowedCorsOriginRequestWithClientId = (requestedAction.payload as AddAllowedCorsOriginRequestWithClientId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.addCorsOrigin(addWithID.clientId, addWithID.request)
                .then((newAllowedOrigin: AllowedCorsOrigin) => {
                    store.dispatch(action(ADD_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS, newAllowedOrigin));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_CLIENT_ALLOWED_CORS_ORIGIN_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case DELETE_CLIENT_ALLOWED_CORS_ORIGIN:
            const deleteWithID: DeleteAllowedCorsOriginRequest = (requestedAction.payload as DeleteAllowedCorsOriginRequest);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.removeCorsOrigin(deleteWithID.clientId, deleteWithID.allowedCorsOriginId)
                .then(() => {
                    store.dispatch(action(DELETE_CLIENT_ALLOWED_CORS_ORIGIN_SUCCESS, deleteWithID.allowedCorsOriginId));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(DELETE_CLIENT_ALLOWED_CORS_ORIGIN_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminClientAllowedCorsOriginsDataService;