import React, { FunctionComponent } from 'react';
import { PostLogoutRedirectUri } from '../../../../../../shared/utils/DataAccess';
import { TableCell } from '@material-ui/core';
import FullTableRow, { DisplayState } from '../../../../../../shared/FullTable/components/FullTableRow';


type Props = {
    PostLogoutRedirectUri: PostLogoutRedirectUri,
    onDelete(id: number): void
}


const ClientPostLogoutRedirectUriComponent: FunctionComponent<Props> = (props: Props) => {


    const { PostLogoutRedirectUri } = props;


    const onDelete = () => {
        props.onDelete(PostLogoutRedirectUri.id);
    }


    return (
        <FullTableRow displayState={DisplayState.ReadOnly} canDelete={true} onDelete={onDelete} >
            <TableCell component="th" scope="row">{PostLogoutRedirectUri.uri}</TableCell>
        </FullTableRow>
    );


}


export default ClientPostLogoutRedirectUriComponent;