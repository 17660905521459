import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { __RouterContext as RouterContext } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import { ClientConfigInformation } from '../../../../../shared/utils/DataAccess';
import { loadClient } from '../edit/redux/actions';
import { StoreState } from '../../../../../shared/redux/store';
import ClientEditComponent from '../edit/components/ClientEditComponent';
import ClientAllowedCorsOriginsComponent from '../allowedCorsOrigins/components/ClientAllowedCorsOrigins';
import ClientAllowedGrantTypesComponent from '../allowedGrantTypes/components/ClientAllowedGrantTypes';
import ClientClaimsComponent from '../claims/components/ClientClaims';
import ClientPropertiesComponent from '../properties/components/ClientProperties';
import ClientPostLogoutRedirectUrisComponent from '../postLogoutRedirectUris/components/ClientPostLogoutRedirectUris';
import ResponstiveTabsComponent from '../../../../../shared/ResponsiveTabs/ResponsiveTabsComponent';
import ResponsiveTabComponent from '../../../../../shared/ResponsiveTabs/ResponstiveTabPanelComponent';
import ClientRedirectUrisComponent from '../RedirectUris/components/ClientRedirectUris';
import ClientAllowedScopesComponent from '../allowedScopes/components/ClientAllowedScopes';


const ClientPage: FunctionComponent = () => {
    const dispatch = useDispatch();

    let { id } = (useContext(RouterContext).match.params) as any;


    const clientInformation: ClientConfigInformation = useSelector((state: StoreState) => {
        return state.adminClientReducer.clientConfiguration
    });


    useEffect(() => {
        dispatch(loadClient(id));
    }, []);


    const classes = makeStyles({
        page: {
            width: '90%',
            margin: '80px auto 0px',
            padding: '20px'
        },
        row: {
            maxWidth: "500px"
        },
        useDropdown: {
            display: "none",
            ['@media (max-width:600px)']: {
                display: "inherit"
            }
        },
        useTabs: {
            display: "inherit",
            ['@media (max-width:600px)']: {
                display: "none"
            }
        },
    })();


    const tabs = [
        { id: 0, title: "Client" },
        { id: 1, title: "Properties" },
        { id: 2, title: "Claims" },
        { id: 3, title: "Allowed CORS Origins" },
        { id: 4, title: "Allowed Grant Types" },
        { id: 5, title: "Allowed Scopes" },
        { id: 6, title: "Redirect Uris" },
        { id: 7, title: "Post Logout Redirect Uris" },
    ];


    return (
        <Paper className={classes.page}>
            <h3>{clientInformation.clientName}</h3>
            <ResponstiveTabsComponent tabs={tabs}>
                <ResponsiveTabComponent id={0}>
                    <ClientEditComponent clientInformation={clientInformation}></ClientEditComponent>
                </ResponsiveTabComponent>
                <ResponsiveTabComponent id={1}>
                    <ClientPropertiesComponent clientInformation={clientInformation}></ClientPropertiesComponent>
                </ResponsiveTabComponent>
                <ResponsiveTabComponent id={2}>
                    <ClientClaimsComponent clientInformation={clientInformation}></ClientClaimsComponent>
                </ResponsiveTabComponent>
                <ResponsiveTabComponent id={3}>
                    <ClientAllowedCorsOriginsComponent clientInformation={clientInformation}></ClientAllowedCorsOriginsComponent>
                </ResponsiveTabComponent>
                <ResponsiveTabComponent id={4}>
                    <ClientAllowedGrantTypesComponent clientInformation={clientInformation}></ClientAllowedGrantTypesComponent>
                </ResponsiveTabComponent>
                <ResponsiveTabComponent id={5}>
                    <ClientAllowedScopesComponent clientInformation={clientInformation}></ClientAllowedScopesComponent>
                </ResponsiveTabComponent>
                <ResponsiveTabComponent id={6}>
                    <ClientRedirectUrisComponent clientInformation={clientInformation}></ClientRedirectUrisComponent>
                </ResponsiveTabComponent>
                <ResponsiveTabComponent id={7}>
                    <ClientPostLogoutRedirectUrisComponent clientInformation={clientInformation}></ClientPostLogoutRedirectUrisComponent>
                </ResponsiveTabComponent>
            </ResponstiveTabsComponent>
        </Paper>
    );


}


export default ClientPage;