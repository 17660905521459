import React, { FunctionComponent, useState } from 'react';

import { ResourceInformation } from '../../../../shared/utils/DataAccess';

import TextField from '@material-ui/core/TextField';
import { Button, Checkbox, Dialog, DialogTitle, FormControlLabel, DialogContent, DialogActions, makeStyles } from '@material-ui/core';


interface StateProps {
    visible: boolean,
    onAddResource(addResourceRequest: ResourceInformation): void,
    onClose(): void
}


export type Props = StateProps;


const AddResourceComponent: FunctionComponent<Props> = (props: Props) => {


    const CreateNewAddResourceRequest = () => {
        return ResourceInformation.fromJS({
            id: 0,
            enabled: true,
            name: "",
            displayName: "",
            description: "",
            created: new Date(),
            updated: new Date(),
            lastAccessed: new Date(),
            nonEditable: false,
        });
    }


    const [addResourceRequest, setaddResourceRequest] = useState<ResourceInformation>(CreateNewAddResourceRequest());


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAddResourceRequest: ResourceInformation = ResourceInformation.fromJS({
            ...addResourceRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setaddResourceRequest(newAddResourceRequest);
    }


    const onCheckFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAddResourceRequest: ResourceInformation = ResourceInformation.fromJS({
            ...addResourceRequest,
            [e.currentTarget.id]: e.currentTarget.checked
        });

        setaddResourceRequest(newAddResourceRequest);
    }


    const onCancel = () => {
        props.onClose();
    }


    const onSubmit = () => {
        props.onAddResource(addResourceRequest);

        setaddResourceRequest(CreateNewAddResourceRequest());
    }


    const classes = makeStyles({
        row: {
            flex: 1,
            flexDirection: "row",
            clear: 'both',
            marginBottom: '5px'
        },
    })();


    return (
        <Dialog open={props.visible}>
            <DialogTitle>Add New Resource</DialogTitle>
            <DialogContent>
                <div className={classes.row}>
                    <TextField
                        id="name"
                        label="Name"
                        value={addResourceRequest.name}
                        onChange={onTextFieldChange}
                    />
                </div>
                <div className={classes.row}>
                    <TextField
                        id="displayName"
                        label="Display Name"
                        value={addResourceRequest.displayName}
                        onChange={onTextFieldChange}
                    />
                </div>
                <div className={classes.row}>
                    <TextField
                        id="description"
                        label="Description"
                        value={addResourceRequest.description}
                        onChange={onTextFieldChange}
                    />
                </div>
                <div className={classes.row}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="nonEditable"
                                checked={addResourceRequest.nonEditable}
                                onChange={onCheckFieldChange}
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                                style={{ float: 'right' }}
                            />
                        }
                        label="Non-Editable"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onCancel}>Cancel</Button>
                <Button color="primary" onClick={onSubmit}>Create Resource</Button>
            </DialogActions>
        </Dialog>
    );


};


export default AddResourceComponent;