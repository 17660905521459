import { Middleware } from 'redux';

import {
    ClientPropertyInformation
} from '../../../../../../shared/utils/DataAccess';
import {
    ENUMERATE_CLIENT_PROPERTIES, ENUMERATE_CLIENT_PROPERTIES_SUCCESS, ENUMERATE_CLIENT_PROPERTIES_ERROR,
    ADD_CLIENT_PROPERTY, ADD_CLIENT_PROPERTY_SUCCESS, ADD_CLIENT_PROPERTY_ERROR,
    DELETE_CLIENT_PROPERTY, DELETE_CLIENT_PROPERTY_SUCCESS, DELETE_CLIENT_PROPERTY_ERROR, UPDATE_CLIENT_PROPERTY_SUCCESS, UPDATE_CLIENT_PROPERTY
} from './actionDef';
import { AdminClientPropertiesAction, AddPropertyRequestWithClientId, DeletePropertyRequest, UpdateClientPropertyRequestWithClientId } from './actions';
import APIClientFactory from '../../../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../../../shared/spinner/redux/reducer';



const adminClientPropertiesDataService: Middleware = store => next => (requestedAction: AdminClientPropertiesAction) => {
    next(requestedAction);

    const client = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ENUMERATE_CLIENT_PROPERTIES:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));
            client.enumerateClientProperties(requestedAction.payload as number)
                .then((Claims: ClientPropertyInformation[]) => {
                    store.dispatch(action(ENUMERATE_CLIENT_PROPERTIES_SUCCESS, Claims));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_CLIENT_PROPERTIES_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ADD_CLIENT_PROPERTY:
            const addWithID: AddPropertyRequestWithClientId = (requestedAction.payload as AddPropertyRequestWithClientId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.addClientProperty(addWithID.clientId, addWithID.request)
                .then((newGrantTypeInformation: ClientPropertyInformation) => {
                    store.dispatch(action(ADD_CLIENT_PROPERTY_SUCCESS, newGrantTypeInformation));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_CLIENT_PROPERTY_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case UPDATE_CLIENT_PROPERTY:
            const updateWithId: UpdateClientPropertyRequestWithClientId = (requestedAction.payload as UpdateClientPropertyRequestWithClientId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.updateClientProperty(updateWithId.clientId, updateWithId.id, updateWithId.request)
                .then(() => {
                    //we want to update our state, so make new UserInformation from UpdateUserRequest
                    const updatedRecord: ClientPropertyInformation = ClientPropertyInformation.fromJS({
                        id: updateWithId.id,
                        key: updateWithId.request.key,
                        value: updateWithId.request.value,
                    });
                    store.dispatch(action(UPDATE_CLIENT_PROPERTY_SUCCESS, updatedRecord));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_CLIENT_PROPERTY_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;

        case DELETE_CLIENT_PROPERTY:
            const deleteWithID: DeletePropertyRequest = (requestedAction.payload as DeletePropertyRequest);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.removeClientProperty(deleteWithID.clientId, deleteWithID.propertyId)
                .then(() => {
                    store.dispatch(action(DELETE_CLIENT_PROPERTY_SUCCESS, deleteWithID.propertyId));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(DELETE_CLIENT_PROPERTY_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminClientPropertiesDataService;