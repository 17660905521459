import { SigninAction } from "./actions";
import { SIGNIN_ERROR } from "./actionDef";


export interface SigninState {
  error: Error;
}

export const initialState: SigninState = {
  error: new Error()
}

export function signinReducer(state: SigninState = initialState, action: SigninAction): SigninState {
  switch (action.type) {
    case SIGNIN_ERROR:
      return {
        ...state,
        error: action.payload as Error
      }
    default:
      return state;
  }
}