import { action } from "typesafe-actions"
import { SERVER_ERROR } from "./actionDefs";
import { ErrorState } from "./reducer";


export interface ErrorAction {
    type: string;
    payload?: ErrorState | null
}


export const setError = (data: ErrorState): ErrorAction => action(SERVER_ERROR, data);