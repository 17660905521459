import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { __RouterContext as RouterContext } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import { ResourceInformation, ResourceScopeInformation } from '../../../../../../../shared/utils/DataAccess';
import { loadResource } from '../../../edit/redux/actions';
import { StoreState } from '../../../../../../../shared/redux/store';
import ResponstiveTabsComponent from '../../../../../../../shared/ResponsiveTabs/ResponsiveTabsComponent';
import ResponsiveTabComponent from '../../../../../../../shared/ResponsiveTabs/ResponstiveTabPanelComponent';
import ResourcesScopeEditComponent from '../edit/components/ResourceScopeEditComponent';
import { loadResourceScope } from '../edit/redux/actions';
import ResourcesScopesClaimsComponent from '../claims/components/ResourcesScopesClaims';
import { push } from 'connected-react-router';


const ResourceScopesPage: FunctionComponent = () => {
    const dispatch = useDispatch();

    let { resourceId } = (useContext(RouterContext).match.params) as any;
    let { scopeId } = (useContext(RouterContext).match.params) as any;


    const [loadedScopeId, setloadedScopeId] = useState<number>(0);


    const resourceInformation: ResourceInformation = useSelector((state: StoreState) => {
        return state.adminResourceReducer.resourceInformation
    });


    const scopeInformation: ResourceScopeInformation = useSelector((state: StoreState) => {
        return state.adminResourcesScopeReducer.scope
    });


    useEffect(() => {
        dispatch(loadResource(resourceId));
    }, []);


    useEffect(() => {
        if (loadedScopeId !== scopeId) {
            setloadedScopeId(scopeId);
            dispatch(loadResourceScope(resourceId, scopeId));
        }
    }, [resourceInformation]);


    const onClick = () => {
        dispatch(push("/administration/resources/" + resourceInformation.id.toString()));
    }


    const classes = makeStyles({
        page: {
            width: '90%',
            margin: '80px auto 0px',
            padding: '20px'
        },
        row: {
            maxWidth: "500px"
        },
        useDropdown: {
            display: "none",
            ['@media (max-width:600px)']: {
                display: "inherit"
            }
        },
        useTabs: {
            display: "inherit",
            ['@media (max-width:600px)']: {
                display: "none"
            }
        },
    })();


    const tabs = [
        { id: 0, title: "Scope" },
        { id: 1, title: "Claims" },
    ];


    return (
        <Paper className={classes.page}>
            <h3 style={{ textDecoration: 'underline', cursor: 'pointer', float: 'left', marginRight: '10px' }} onClick={onClick}>{resourceInformation ? resourceInformation.displayName : ""}</h3>
            <h3> &gt; {scopeInformation ? scopeInformation.displayName : ""}</h3>
            <ResponstiveTabsComponent tabs={tabs}>
                <ResponsiveTabComponent id={0}>
                    <ResourcesScopeEditComponent resourceId={resourceId} scopeInformation={scopeInformation}></ResourcesScopeEditComponent>
                </ResponsiveTabComponent>
                <ResponsiveTabComponent id={1}>
                    <ResourcesScopesClaimsComponent resourceId={resourceId} scopeId={scopeInformation.id}></ResourcesScopesClaimsComponent>
                </ResponsiveTabComponent>
            </ResponstiveTabsComponent>
        </Paper>
    );


}


export default ResourceScopesPage;