import { AdminClientRedirectUrisAction } from "./actions";
import { RedirectUri } from "../../../../../../shared/utils/DataAccess";
import {
    ENUMERATE_CLIENT_REDIRECT_URIS_SUCCESS, ENUMERATE_CLIENT_REDIRECT_URIS_ERROR,
    ADD_CLIENT_REDIRECT_URI_SUCCESS, ADD_CLIENT_REDIRECT_URI_ERROR,
    DELETE_CLIENT_REDIRECT_URI_SUCCESS, DELETE_CLIENT_REDIRECT_URI_ERROR,
} from "./actionDef";
import _ from "lodash";


export interface ClientRedirectUrisState {
    list: {
        byId: {
            [id: number]: RedirectUri
        };
        allIds: number[];
    };
    newRedirectUri?: RedirectUri;
};


export const initialState: ClientRedirectUrisState = {
    list: {
        byId: {},
        allIds: []
    },
    newRedirectUri: undefined
};


export function adminClientRedirectUrisReducer(state: ClientRedirectUrisState = initialState, action: AdminClientRedirectUrisAction): ClientRedirectUrisState {
    switch (action.type) {
        case ENUMERATE_CLIENT_REDIRECT_URIS_SUCCESS:
            const redirectUris = action.payload as RedirectUri[];
            return {
                ...state,
                list: {
                    byId: {
                        ..._.keyBy(redirectUris, "id")
                    },
                    allIds: _.map(redirectUris, "id")
                }
            };
        case ADD_CLIENT_REDIRECT_URI_SUCCESS:
            const newRedirectUri = action.payload as RedirectUri;
            return {
                ...state,
                list: {
                    byId: {
                        ...state.list.byId,
                        [newRedirectUri.id]: newRedirectUri
                    },
                    allIds: state.list.allIds.concat(newRedirectUri.id)
                },
                newRedirectUri: newRedirectUri
            };
        case DELETE_CLIENT_REDIRECT_URI_SUCCESS:
            const newAllIds = [...state.list.allIds];
            const idIndex = newAllIds.findIndex(el => el === action.payload as number);
            newAllIds.splice(idIndex, 1);

            const newByids = { ...state.list.byId };
            delete newByids[action.payload as number];

            return {
                ...state,
                list: {
                    byId: {
                        ...newByids
                    },
                    allIds: newAllIds
                }
            };
        case ENUMERATE_CLIENT_REDIRECT_URIS_ERROR:
        case ADD_CLIENT_REDIRECT_URI_ERROR:
        case DELETE_CLIENT_REDIRECT_URI_ERROR:
        default:
            return state;
    }
}