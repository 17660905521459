import { ConfigUpdateDto, ClientConfigInformation } from "../../../../../../shared/utils/DataAccess";
import { UPDATE_CLIENT, LOAD_CLIENT } from "./actionDef";
import { action } from "typesafe-actions";


export interface AdminClientAction {
    type: string;
    payload?:
    number | ConfigUpdateDto | ConfigUpdateDtoWithId |
    ClientConfigInformation |
    null;
}


export interface ConfigUpdateDtoWithId {
    id: number,
    request: ConfigUpdateDto;
}

export const loadClient = (id: number): AdminClientAction => action(LOAD_CLIENT, id);
export const updateClient = (id: number, request: ConfigUpdateDto): AdminClientAction => action(UPDATE_CLIENT, { id: id, request: request } as ConfigUpdateDtoWithId);
