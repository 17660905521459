import { Middleware } from 'redux';

import {
    ClientClaimInformation
} from '../../../../../../shared/utils/DataAccess';
import {
    ENUMERATE_CLIENT_CLAIMS, ENUMERATE_CLIENT_CLAIMS_SUCCESS, ENUMERATE_CLIENT_CLAIMS_ERROR,
    ADD_CLIENT_CLAIM, ADD_CLIENT_CLAIM_SUCCESS, ADD_CLIENT_CLAIM_ERROR,
    DELETE_CLIENT_CLAIM, DELETE_CLIENT_CLAIM_SUCCESS, DELETE_CLIENT_CLAIM_ERROR, UPDATE_CLIENT_CLAIM_SUCCESS, UPDATE_CLIENT_CLAIM
} from './actionDef';
import { AdminClientClaimsAction, AddClaimRequestWithClientId, DeleteClaimRequest, UpdateClientClaimRequestWithClientId } from './actions';
import APIClientFactory from '../../../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../../../shared/spinner/redux/reducer';



const adminClientClaimsDataService: Middleware = store => next => (requestedAction: AdminClientClaimsAction) => {
    next(requestedAction);

    const client = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ENUMERATE_CLIENT_CLAIMS:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));
            client.enumerateClientClaims(requestedAction.payload as number)
                .then((Claims: ClientClaimInformation[]) => {
                    store.dispatch(action(ENUMERATE_CLIENT_CLAIMS_SUCCESS, Claims));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_CLIENT_CLAIMS_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ADD_CLIENT_CLAIM:
            const addWithID: AddClaimRequestWithClientId = (requestedAction.payload as AddClaimRequestWithClientId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.addClientClaim(addWithID.clientId, addWithID.request)
                .then((newGrantTypeInformation: ClientClaimInformation) => {
                    store.dispatch(action(ADD_CLIENT_CLAIM_SUCCESS, newGrantTypeInformation));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_CLIENT_CLAIM_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case UPDATE_CLIENT_CLAIM:
            const updateWithId: UpdateClientClaimRequestWithClientId = (requestedAction.payload as UpdateClientClaimRequestWithClientId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.updateClientClaim(updateWithId.clientId, updateWithId.id, updateWithId.request)
                .then(() => {
                    //we want to update our state, so make new UserInformation from UpdateUserRequest
                    const updatedRecord: ClientClaimInformation = ClientClaimInformation.fromJS({
                        id: updateWithId.id,
                        type: updateWithId.request.type,
                        value: updateWithId.request.value,
                    });
                    store.dispatch(action(UPDATE_CLIENT_CLAIM_SUCCESS, updatedRecord));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_CLIENT_CLAIM_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;

        case DELETE_CLIENT_CLAIM:
            const deleteWithID: DeleteClaimRequest = (requestedAction.payload as DeleteClaimRequest);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.removeClientClaim(deleteWithID.clientId, deleteWithID.ClaimId)
                .then(() => {
                    store.dispatch(action(DELETE_CLIENT_CLAIM_SUCCESS, deleteWithID.ClaimId));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(DELETE_CLIENT_CLAIM_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminClientClaimsDataService;