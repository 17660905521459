export const LOAD_CLIENT = "LOAD_CLIENT";
export type LOAD_CLIENT = typeof LOAD_CLIENT;
export const LOAD_CLIENT_SUCCESS = "LOAD_CLIENT_SUCCESS";
export type LOAD_CLIENT_SUCCESS = typeof LOAD_CLIENT_SUCCESS;
export const LOAD_CLIENT_ERROR = "LOAD_CLIENT_ERROR";
export type LOAD_CLIENT_ERROR = typeof LOAD_CLIENT_ERROR;

export const UPDATE_CLIENT = "UPDATE_CLIENT";
export type UPDATE_CLIENT = typeof UPDATE_CLIENT;
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export type UPDATE_CLIENT_SUCCESS = typeof UPDATE_CLIENT_SUCCESS;
export const UPDATE_CLIENT_ERROR = "UPDATE_CLIENT_ERROR";
export type UPDATE_CLIENT_ERROR = typeof UPDATE_CLIENT_ERROR;
