import { AddClientsAllowedScope, ClientsAllowedScope } from "../../../../../../shared/utils/DataAccess";
import { ENUMERATE_CLIENT_SCOPES, ADD_CLIENT_SCOPE, DELETE_CLIENT_SCOPE } from "./actionDef";
import { action } from "typesafe-actions";


export interface AdminClientScopesAction {
    type: string;
    payload?:
    number | AddScopeRequestWithClientId | DeleteScopeRequest | AddClientsAllowedScope |
    ClientsAllowedScope | ClientsAllowedScope[];
}


export interface AddScopeRequestWithClientId {
    clientId: number,
    request: AddClientsAllowedScope
}


export interface DeleteScopeRequest {
    clientId: number,
    scopeId: number
}


export const loadClientAllowedScopes = (clientId: number): AdminClientScopesAction => action(ENUMERATE_CLIENT_SCOPES, clientId);
export const addClientAllowedScopes = (clientId: number, request: AddClientsAllowedScope): AdminClientScopesAction =>
    action(
        ADD_CLIENT_SCOPE,
        { clientId: clientId, request: request } as AddScopeRequestWithClientId);
export const deleteClientAllowedScopes = (clientId: number, scopeId: number): AdminClientScopesAction =>
    action(DELETE_CLIENT_SCOPE,
        { clientId: clientId, scopeId: scopeId });
