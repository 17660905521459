import { ResourceScopeInformation, UpdateResourceScopeRequest } from "../../../../../../../../shared/utils/DataAccess";
import { UPDATE_RESOURCE_SCOPE, LOAD_RESOURCE_SCOPE } from "./actionDef";
import { action } from "typesafe-actions";


export interface AdminResourcesScopeAction {
    type: string;
    payload?: number | LoadResourceScopeRequest | UpdateResourceScopeRequestWithResourceId | ResourceScopeInformation
}


export interface LoadResourceScopeRequest {
    resourceId: number,
    scopeId: number,
}


export interface UpdateResourceScopeRequestWithResourceId {
    resourceId: number,
    id: number,
    request: UpdateResourceScopeRequest
}


export const loadResourceScope = (resourceId: number, scopeId: number): AdminResourcesScopeAction =>
    action(
        LOAD_RESOURCE_SCOPE,
        { resourceId: resourceId, scopeId: scopeId } as LoadResourceScopeRequest);


export const updateResourceScope = (resourceId: number, id: number, request: UpdateResourceScopeRequest): AdminResourcesScopeAction =>
    action(
        UPDATE_RESOURCE_SCOPE,
        { resourceId: resourceId, id: id, request: request } as UpdateResourceScopeRequestWithResourceId);