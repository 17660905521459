import React, { FunctionComponent, useEffect } from 'react';

import { Sort } from '../../../../shared/utils/DataAccess';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import FullTableComponent, { DisplayType, Column, EnumerationRequest } from '../../../../shared/FullTable/components/FullTableComponent';
import { StoreState } from '../../../../shared/redux/store';
import { AdminUserListState, UserInformationDisplay } from '../../users/redux/reducer';
import { useSelector, useDispatch } from 'react-redux';
import { enumerateUsers } from '../../users/redux/actions';
import AdminAdministratorUserComponent from './AdminAdministratorUserComponent';


interface StateProps {
    visible: boolean,
    onAddAdministrator(user: UserInformationDisplay): void,
    onClose(): void
}


export type Props = StateProps;


const AdminAddAdministratorComponent: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();


    const userListState: AdminUserListState = useSelector((state: StoreState) => {
        return state.adminUsersReducer
    });


    useEffect(() => {
        dispatch(enumerateUsers(userListState.paginationState.enumerateUsersRequest));
    }, []);


    const apiEnumerate = (request: EnumerationRequest) => {
        var { enumerateUsersRequest } = userListState.paginationState;
        enumerateUsersRequest.order = request.order;
        enumerateUsersRequest.page = request.paging.page;
        enumerateUsersRequest.pageSize = request.paging.pageSize;
        enumerateUsersRequest.search = request.search;
        enumerateUsersRequest.sort = request.sort as Sort;

        document.title = enumerateUsersRequest.sort;
        dispatch(enumerateUsers(enumerateUsersRequest));
    };


    const { paginationState: pagination } = userListState;

    const enumerationRequest: EnumerationRequest = {
        search: pagination.enumerateUsersRequest.search,
        sort: pagination.enumerateUsersRequest.sort,
        order: pagination.enumerateUsersRequest.order,
        paging: {
            pageSizes: [2, 10, 25],
            pageSize: pagination.enumerateUsersRequest.pageSize,
            page: pagination.enumerateUsersRequest.page,
            totalRecords: pagination.totalRecords
        }
    };


    const onCancel = () => {
        props.onClose();
    }


    const onUserSelected = (user: UserInformationDisplay) => {
        props.onAddAdministrator(user);
    }


    const Columns: Column[] = [
        { name: "GivenName", displayName: "Name", sortable: true, displayType: DisplayType.Always, textAlign: undefined },
        { name: "LoginEmail", displayName: "Login Email", sortable: true, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Enabled", displayName: "Enabled", sortable: false, displayType: DisplayType.Always, textAlign: "center" },
        { name: "Choose", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
    ];


    return (
        <Dialog open={props.visible}>
            <DialogTitle>Add New Administrator</DialogTitle>
            <DialogContent style={{ width: "550px" }}>
                <FullTableComponent Columns={Columns} enumerationRequest={enumerationRequest} apiEnumerate={apiEnumerate} showPaging={true} showSearch={true}>
                    {userListState ? userListState.list.allIds.map(id => (
                        <AdminAdministratorUserComponent
                            key={id}
                            userInformation={userListState.list.byId[id]}
                            onUserSelected={onUserSelected}
                        ></AdminAdministratorUserComponent>
                    )) : ""}
                </FullTableComponent>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onCancel}>Cancel</Button>
            </DialogActions>
        </Dialog >
    );


};


export default AdminAddAdministratorComponent;