import { AddResourceSecretRequest, ResourceSecretInformation, UpdateResourceSecretRequest } from "../../../../../../shared/utils/DataAccess";
import { ENUMERATE_RESOURCE_SECRETS, ADD_RESOURCE_SECRET, DELETE_RESOURCE_SECRET, DISPLAY_STATE_CHANGE, UPDATE_RESOURCE_SECRET } from "./actionDef";
import { action } from "typesafe-actions";
import { DisplayState } from "../../../../../../shared/FullTable/components/FullTableRow";


export interface AdminResourceSecretsAction {
    type: string;
    payload?:
    number | ChangeEditState |
    AddSecretRequestWithResourceId | DeleteSecretRequest | AddResourceSecretRequest | UpdateResourceSecretRequestWithResourceId |
    ResourceSecretInformation | ResourceSecretInformation[];
}


export interface AddSecretRequestWithResourceId {
    resourceId: number,
    request: AddResourceSecretRequest
}


export interface UpdateResourceSecretRequestWithResourceId {
    resourceId: number,
    id: number,
    request: UpdateResourceSecretRequest
}


export interface DeleteSecretRequest {
    resourceId: number,
    propertyId: number
}


export interface ChangeEditState {
    id: number;
    displayState: DisplayState;
}


export const loadResourceSecrets = (resourceId: number): AdminResourceSecretsAction => action(ENUMERATE_RESOURCE_SECRETS, resourceId);
export const addResourceSecret = (resourceId: number, request: AddResourceSecretRequest): AdminResourceSecretsAction =>
    action(
        ADD_RESOURCE_SECRET,
        { resourceId: resourceId, request: request } as AddSecretRequestWithResourceId);
export const deleteResourceSecret = (resourceId: number, propertyId: number): AdminResourceSecretsAction =>
    action(DELETE_RESOURCE_SECRET,
        { resourceId: resourceId, propertyId: propertyId });

export const updateResourceSecret = (resourceId: number, id: number, request: UpdateResourceSecretRequest): AdminResourceSecretsAction =>
    action(
        UPDATE_RESOURCE_SECRET,
        { resourceId: resourceId, id: id, request: request } as UpdateResourceSecretRequestWithResourceId);

export const editStart = (id: number): AdminResourceSecretsAction => action(DISPLAY_STATE_CHANGE, { id: id, displayState: DisplayState.Edit });
export const editChanges = (id: number, hasChanges: boolean) => action(DISPLAY_STATE_CHANGE, { id: id, displayState: hasChanges ? DisplayState.NotSaved : DisplayState.Edit });
export const editCancel = (id: number): AdminResourceSecretsAction => action(DISPLAY_STATE_CHANGE, { id: id, displayState: DisplayState.ReadOnly });