import React, { FunctionComponent } from 'react';
import { ClientConfigInformation } from '../../../../shared/utils/DataAccess';
import { TableCell } from '@material-ui/core';
import FullTableRow, { DisplayState } from '../../../../shared/FullTable/components/FullTableRow';


type Props = {
    clientInformation: ClientConfigInformation,
    onSelect(id: number): void
    onDelete(id: number): void
}


const ClientComponent: FunctionComponent<Props> = (props: Props) => {


    const { clientInformation } = props;


    const onSelect = () => {
        props.onSelect(clientInformation.id);
    }


    const onDelete = () => {
        props.onDelete(clientInformation.id);
    }

    const SECONDS_IN_DAY = 60 * 60 * 24;


    return (
        <FullTableRow displayState={DisplayState.ReadOnly} canEdit={true} canSelect={true} canDelete={true} onSelect={onSelect} onDelete={onDelete} >
            <TableCell component="th" scope="row">{clientInformation.clientName}</TableCell>
            <TableCell component="th" scope="row">{clientInformation.consentLifetime / SECONDS_IN_DAY} days</TableCell>
            <TableCell style={{ textAlign: "center" }}>{clientInformation.requirePkce ? "X" : ""}</TableCell>
            <TableCell style={{ textAlign: "center" }}>{clientInformation.requireClientSecret ? "X" : ""}</TableCell>
            <TableCell style={{ textAlign: "center" }}>{clientInformation.allowOfflineAccess ? "X" : ""}</TableCell>
            <TableCell style={{ textAlign: "center" }}>{clientInformation.allowAccessTokensViaBrowser ? "X" : ""}</TableCell>
        </FullTableRow>
    );


}


export default ClientComponent;