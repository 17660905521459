import React, { FunctionComponent } from 'react';
import { AdministratorInformation } from '../../../../shared/utils/DataAccess';
import { TableCell } from '@material-ui/core';
import FullTableRow, { DisplayState } from '../../../../shared/FullTable/components/FullTableRow';


type Props = {
    administratorInformation: AdministratorInformation,
    onDelete(id: string): void
}


const AdminAdministratorComponent: FunctionComponent<Props> = (props: Props) => {


    const { administratorInformation } = props;


    const onDelete = () => {
        props.onDelete(administratorInformation.id);
    }


    return (
        <FullTableRow displayState={DisplayState.ReadOnly} canDelete={true} onDelete={onDelete} >
            <TableCell component="th" scope="row">{administratorInformation.name}</TableCell>
            <TableCell>{administratorInformation.loginEmail}</TableCell>
        </FullTableRow>
    );


}


export default AdminAdministratorComponent;