import { AdminClientAllowedGrantTypesAction } from "./actions";
import { ClientAllowedGrantTypeInformation } from "../../../../../../shared/utils/DataAccess";
import {
    ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_SUCCESS, ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_ERROR,
    ADD_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS, ADD_CLIENT_ALLOWED_GRANT_TYPE_ERROR,
    DELETE_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS, DELETE_CLIENT_ALLOWED_GRANT_TYPE_ERROR,
} from "./actionDef";
import _ from "lodash";


export interface AdminClientAllowedGrantTypesState {
    list: {
        byId: {
            [id: number]: ClientAllowedGrantTypeInformation
        };
        allIds: number[];
    };
    newAllowedGrantType?: ClientAllowedGrantTypeInformation;
};


export const initialState: AdminClientAllowedGrantTypesState = {
    list: {
        byId: {},
        allIds: []
    },
    newAllowedGrantType: undefined
};


export function adminClientAllowedGrantTypesReducer(state: AdminClientAllowedGrantTypesState = initialState, action: AdminClientAllowedGrantTypesAction): AdminClientAllowedGrantTypesState {
    switch (action.type) {
        case ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_SUCCESS:
            const grantTypes = action.payload as ClientAllowedGrantTypeInformation[];
            return {
                ...state,
                list: {
                    byId: {
                        ..._.keyBy(grantTypes, "id")
                    },
                    allIds: _.map(grantTypes, "id")
                }
            };
        case ADD_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS:
            const newAllowedGrantType = action.payload as ClientAllowedGrantTypeInformation;
            return {
                ...state,
                list: {
                    byId: {
                        ...state.list.byId,
                        [newAllowedGrantType.id]: newAllowedGrantType
                    },
                    allIds: state.list.allIds.concat(newAllowedGrantType.id)
                },
                newAllowedGrantType: newAllowedGrantType
            };
        case DELETE_CLIENT_ALLOWED_GRANT_TYPE_SUCCESS:
            const newAllIds = [...state.list.allIds];
            const idIndex = newAllIds.findIndex(el => el === action.payload as number);
            newAllIds.splice(idIndex, 1);

            const newByids = { ...state.list.byId };
            delete newByids[action.payload as number];

            return {
                ...state,
                list: {
                    byId: {
                        ...newByids
                    },
                    allIds: newAllIds
                }
            };
        case ENUMERATE_CLIENT_ALLOWED_GRANT_TYPES_ERROR:
        case ADD_CLIENT_ALLOWED_GRANT_TYPE_ERROR:
        case DELETE_CLIENT_ALLOWED_GRANT_TYPE_ERROR:
        default:
            return state;
    }
}