import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { ResourceInformation, ResourceScopeInformation, AddResourceScopeRequest } from '../../../../../../shared/utils/DataAccess';
import { StoreState } from '../../../../../../shared/redux/store';
import FullTableComponent, { DisplayType, Column } from '../../../../../../shared/FullTable/components/FullTableComponent';
import ResourceScopeComponent from './ResourceScopeComponent';
import { AdminResourceScopesState } from '../redux/reducer';
import { loadResourceScopes, deleteResourceScope, addResourceScope } from '../redux/actions';
import { push } from 'connected-react-router';


type Props = {
    resourceInformation: ResourceInformation
}


const ResourceScopesComponent: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();


    const [addResourceScopeRequest, setResourceScopeRequest] = useState<AddResourceScopeRequest>(AddResourceScopeRequest.fromJS({
        name: "",
        displayName: "",
        description: "",
        required: false,
        emphasize: false,
        showInDiscoveryDocument: false
    }));


    const clientScopesState: AdminResourceScopesState = useSelector((state: StoreState) => {
        return state.adminResourceScopesReducer;
    });


    const apiEnumerate = () => {
        if (props.resourceInformation.id) {
            dispatch(loadResourceScopes(props.resourceInformation.id));
        }
    };


    const onSelect = (id: number) => {
        dispatch(push("/administration/resources/" + props.resourceInformation.id.toString() + '/scopes/' + id));
    }


    const onDelete = (request: number) => {
        dispatch(deleteResourceScope(props.resourceInformation.id, request));
    };


    useEffect(() => {
        apiEnumerate();
    }, [props.resourceInformation]);


    const hasChanges = (): boolean => {
        return (
            addResourceScopeRequest.name.length > 0 &&
            addResourceScopeRequest.displayName.length > 0
        );
    }


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newRequest: ResourceScopeInformation = ResourceScopeInformation.fromJS({
            ...addResourceScopeRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setResourceScopeRequest(newRequest);
    }


    const onCheckFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newRequest: ResourceScopeInformation = ResourceScopeInformation.fromJS({
            ...addResourceScopeRequest,
            [e.currentTarget.id]: e.currentTarget.checked
        });

        setResourceScopeRequest(newRequest);
    }


    const onSubmit = () => {
        dispatch(addResourceScope(props.resourceInformation.id, addResourceScopeRequest));

        setResourceScopeRequest(AddResourceScopeRequest.fromJS({
            name: "",
            displayName: "",
            description: "",
            required: false,
            emphasize: false,
            showInDiscoveryDocument: false
        }));
    }


    const Columns: Column[] = [
        { name: "Edit", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Name", displayName: "Name", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "DisplayName", displayName: "Display Name", sortable: false, displayType: DisplayType.Always, textAlign: "right" },
        { name: "Description", displayName: "Description", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Required", displayName: "Required", sortable: false, displayType: DisplayType.Always, textAlign: "center" },
        { name: "Emphasize", displayName: "Emphasize", sortable: false, displayType: DisplayType.Always, textAlign: "center" },
        { name: "ShowInDiscoveryDocument", displayName: "Show in Discovery Document", sortable: false, displayType: DisplayType.Always, textAlign: "center" },
        { name: "Delete", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
    ];


    const classes = makeStyles({
        row: {
            maxWidth: "500px"
        }
    })();


    return (
        <>
            <div className={classes.row}>
                <FullTableComponent Columns={Columns} apiEnumerate={apiEnumerate}>
                    {clientScopesState ? clientScopesState.list.allIds.map(id => (
                        <ResourceScopeComponent
                            key={id}
                            scope={clientScopesState.list.byId[id]}
                            onSelect={onSelect}
                            onDelete={onDelete}
                        ></ResourceScopeComponent>
                    )) : ""}
                </FullTableComponent>
            </div>
            <div className={classes.row} style={{ marginTop: '50px' }}>
                <TextField
                    id="name"
                    label="New Scope Name"
                    fullWidth={true}
                    value={addResourceScopeRequest.name}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    id="displayName"
                    label="Display Name"
                    fullWidth={true}
                    value={addResourceScopeRequest.displayName}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    id="description"
                    label="Description"
                    fullWidth={true}
                    value={addResourceScopeRequest.description}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="required"
                            checked={addResourceScopeRequest.required}
                            onChange={onCheckFieldChange}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                            style={{ float: 'right' }}
                        />
                    }
                    label="Required"
                />
            </div>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="emphasize"
                            checked={addResourceScopeRequest.emphasize}
                            onChange={onCheckFieldChange}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                            style={{ float: 'right' }}
                        />
                    }
                    label="Emphasized"
                />
            </div>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="showInDiscoveryDocument"
                            checked={addResourceScopeRequest.showInDiscoveryDocument}
                            onChange={onCheckFieldChange}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                            style={{ float: 'right' }}
                        />
                    }
                    label="Show in Discovery Document"
                />
            </div>
            <div className={classes.row} style={{ textAlign: 'right' }}>
                <Button variant="outlined" color="primary" onClick={onSubmit} disabled={!hasChanges()}>Add Scope</Button>
            </div>
        </>
    );


}


export default ResourceScopesComponent;