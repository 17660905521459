import React, { FunctionComponent, useState } from 'react';
import { UpdateUserRequest } from '../../../../shared/utils/DataAccess';
import { TextField, Checkbox, TableCell } from '@material-ui/core';
import { UpdateUserRequestWithId } from '../redux/actions';
import FullTableRow, { DisplayState } from '../../../../shared/FullTable/components/FullTableRow';
import { UserInformationDisplay } from '../redux/reducer';


type Props = {
    userInformation: UserInformationDisplay,
    onUpdateUser(request: UpdateUserRequestWithId): void,
    onEditStart(id: string): void,
    onEditChanges(id: string, hasChanges: boolean): void,
    onEditCancel(id: string): void
}


const AdminUserComponent: FunctionComponent<Props> = (props: Props) => {


    const InitialUpdateUserRequest = () => {
        return UpdateUserRequest.fromJS({
            enabled: props.userInformation.enabled,
            loginEmail: props.userInformation.loginEmail,
            loginPhone: props.userInformation.loginPhone,
            externalId: props.userInformation.externalId,
            givenName: props.userInformation.givenName,
            surname: props.userInformation.surname,
            emailVerified: props.userInformation.emailVerified,
        });
    }


    const [updateUserRequest, setupdateUserRequest] = useState<UpdateUserRequest>(InitialUpdateUserRequest());


    const hasChanges = (newUpdateUserRequest: UpdateUserRequest): boolean => {
        if (props.userInformation === undefined) {
            return false;
        }

        return (
            newUpdateUserRequest.loginEmail !== props.userInformation.loginEmail ||
            newUpdateUserRequest.loginPhone !== props.userInformation.loginPhone ||
            newUpdateUserRequest.givenName !== props.userInformation.givenName ||
            newUpdateUserRequest.surname !== props.userInformation.surname ||
            newUpdateUserRequest.emailVerified !== props.userInformation.emailVerified ||
            newUpdateUserRequest.enabled !== props.userInformation.enabled);
    }


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUpdateUserRequest: UpdateUserRequest = UpdateUserRequest.fromJS({
            ...updateUserRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setupdateUserRequest(newUpdateUserRequest);
        props.onEditChanges(props.userInformation.id, hasChanges(newUpdateUserRequest));
    }


    const onCheckFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUpdateUserRequest: UpdateUserRequest = UpdateUserRequest.fromJS({
            ...updateUserRequest,
            [e.currentTarget.id]: e.currentTarget.checked
        });

        setupdateUserRequest(newUpdateUserRequest);
        props.onEditChanges(props.userInformation.id, hasChanges(newUpdateUserRequest));
    }


    const onEditStart = () => {
        props.onEditStart(props.userInformation.id);
    }


    const onEditCancel = () => {
        setupdateUserRequest(InitialUpdateUserRequest());
        props.onEditCancel(props.userInformation.id);
    }


    const onSave = () => {
        props.onUpdateUser({
            id: props.userInformation.id,
            updateUserRequest: updateUserRequest
        });
    }


    const { userInformation } = props;
    const isEditing: boolean =
        userInformation.displayState === DisplayState.Edit ||
        userInformation.displayState === DisplayState.NotSaved ||
        userInformation.displayState === DisplayState.Error;


    return (
        <FullTableRow
            displayState={userInformation.displayState}
            onEditStart={onEditStart}
            onEditCancel={onEditCancel}
            onSave={onSave}
            canEdit={true}
        >
            {isEditing ?
                <>
                    <TableCell component="th" scope="row">
                        <TextField
                            id="givenName"
                            onChange={onTextFieldChange}
                            value={updateUserRequest.givenName}
                            style={{ width: '90px' }}
                        >
                        </TextField>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField
                            id="surname"
                            onChange={onTextFieldChange}
                            value={updateUserRequest.surname}
                            style={{ width: '90px' }}
                        >
                        </TextField>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField
                            id="loginEmail"
                            onChange={onTextFieldChange}
                            value={updateUserRequest.loginEmail}
                            style={{ width: '170px' }}
                        >
                        </TextField>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField
                            id="loginPhone"
                            onChange={onTextFieldChange}
                            value={updateUserRequest.loginPhone}
                            style={{ width: '110px' }}
                        >
                        </TextField>
                    </TableCell>
                    <TableCell align="center">
                        <Checkbox
                            id="emailVerified"
                            checked={updateUserRequest.emailVerified}
                            onChange={onCheckFieldChange}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <Checkbox
                            id="enabled"
                            checked={updateUserRequest.enabled}
                            onChange={onCheckFieldChange}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                        />
                    </TableCell>
                    <TableCell align="right">{props.userInformation.dateCreated.toLocaleString()}</TableCell>
                </>
                :
                <>
                    <TableCell component="th" scope="row">{updateUserRequest.givenName}</TableCell>
                    <TableCell>{updateUserRequest.surname}</TableCell>
                    <TableCell>{updateUserRequest.loginEmail}</TableCell>
                    <TableCell>{updateUserRequest.loginPhone}</TableCell>
                    <TableCell align="center">{updateUserRequest.emailVerified ? "X" : ""}</TableCell>
                    <TableCell align="center">{updateUserRequest.enabled ? "X" : ""}</TableCell>
                    <TableCell align="right">{userInformation.dateCreated.toLocaleString()}</TableCell>
                </>
            }
        </FullTableRow>
    );


}


export default AdminUserComponent;