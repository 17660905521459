export const ENUMERATE_CLIENTS = "ENUMERATE_CLIENTS";
export type ENUMERATE_CLIENTS = typeof ENUMERATE_CLIENTS;
export const ENUMERATE_CLIENTS_SUCCESS = "ENUMERATE_CLIENTS_SUCCESS";
export type ENUMERATE_CLIENTS_SUCCESS = typeof ENUMERATE_CLIENTS_SUCCESS;
export const ENUMERATE_CLIENTS_ERROR = "ENUMERATE_CLIENTS_ERROR";
export type ENUMERATE_CLIENTS_ERROR = typeof ENUMERATE_CLIENTS_ERROR;

export const ADD_CLIENT = "ADD_CLIENT";
export type ADD_CLIENT = typeof ADD_CLIENT;
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export type ADD_CLIENT_SUCCESS = typeof ADD_CLIENT_SUCCESS;
export const ADD_CLIENT_ERROR = "ADD_CLIENT_ERROR";
export type ADD_CLIENT_ERROR = typeof ADD_CLIENT_ERROR;

export const REMOVE_CLIENT = "REMOVE_CLIENT";
export type REMOVE_CLIENT = typeof REMOVE_CLIENT;
export const REMOVE_CLIENT_SUCCESS = "REMOVE_CLIENT_SUCCESS";
export type REMOVE_CLIENT_SUCCESS = typeof REMOVE_CLIENT_SUCCESS;
export const REMOVE_CLIENT_ERROR = "REMOVE_CLIENT_ERROR";
export type REMOVE_CLIENT_ERROR = typeof REMOVE_CLIENT_ERROR;