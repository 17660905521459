import { AddAllowedCorsOriginRequest, AllowedCorsOrigin } from "../../../../../../shared/utils/DataAccess";
import { ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS, ADD_CLIENT_ALLOWED_CORS_ORIGIN, DELETE_CLIENT_ALLOWED_CORS_ORIGIN } from "./actionDef";
import { action } from "typesafe-actions";


export interface AdminClientAllowedCorsOriginsAction {
    type: string;
    payload?: number | AddAllowedCorsOriginRequestWithClientId | DeleteAllowedCorsOriginRequest | AllowedCorsOrigin | AllowedCorsOrigin[];
}


export interface AddAllowedCorsOriginRequestWithClientId {
    clientId: number,
    request: AddAllowedCorsOriginRequest
}


export interface DeleteAllowedCorsOriginRequest {
    clientId: number,
    allowedCorsOriginId: number
}


export const loadClientAllowedCorsOrigins = (clientId: number): AdminClientAllowedCorsOriginsAction => action(ENUMERATE_CLIENT_ALLOWED_CORS_ORIGINS, clientId);
export const addClientAllowedCorsOrigins = (clientId: number, allowedCorsOrigin: string): AdminClientAllowedCorsOriginsAction =>
    action(
        ADD_CLIENT_ALLOWED_CORS_ORIGIN,
        { clientId: clientId, request: { origin: allowedCorsOrigin } as AddAllowedCorsOriginRequest } as AddAllowedCorsOriginRequestWithClientId);
export const deleteClientAllowedCorsOrigins = (clientId: number, allowedCorsOriginId: number): AdminClientAllowedCorsOriginsAction =>
    action(DELETE_CLIENT_ALLOWED_CORS_ORIGIN,
        { clientId: clientId, allowedCorsOriginId: allowedCorsOriginId });

