import React, { FunctionComponent } from 'react';


export interface ResponstiveTabComponentProps {
    id: number,
    children?: React.ReactNode
};


export type Props = ResponstiveTabComponentProps;


const ResponsiveTabComponent: FunctionComponent<Props> = (props: Props) => {


    const { children } = props;

    return (
        <>{children}</>
    );


}

export default ResponsiveTabComponent;