import { Middleware } from 'redux';

import {
    PostLogoutRedirectUri
} from '../../../../../../shared/utils/DataAccess';
import {
    ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS, ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS_SUCCESS, ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS_ERROR,
    ADD_CLIENT_POST_LOGOUT_REDIRECT_URI, ADD_CLIENT_POST_LOGOUT_REDIRECT_URI_SUCCESS, ADD_CLIENT_POST_LOGOUT_REDIRECT_URI_ERROR,
    DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI, DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI_SUCCESS, DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI_ERROR
} from './actionDef';
import { AdminClientPostLogoutRedirectUrisAction, AddPostLogoutRedirectUriRequestWithClientId, DeletePostLogoutRedirectUriRequest } from './actions';
import APIClientFactory from '../../../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../../../shared/spinner/redux/reducer';



const adminClientPostLogoutRedirectUrisDataService: Middleware = store => next => (requestedAction: AdminClientPostLogoutRedirectUrisAction) => {
    next(requestedAction);

    const client = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.enumeratePostLogoutRedirectUris(requestedAction.payload as number)
                .then((PostLogoutRedirectUris: PostLogoutRedirectUri[]) => {
                    store.dispatch(action(ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS_SUCCESS, PostLogoutRedirectUris));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_CLIENT_POST_LOGOUT_REDIRECT_URIS_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ADD_CLIENT_POST_LOGOUT_REDIRECT_URI:
            const addWithID: AddPostLogoutRedirectUriRequestWithClientId = (requestedAction.payload as AddPostLogoutRedirectUriRequestWithClientId);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.addClientPostLogoutRedirectUri(addWithID.clientId, addWithID.request)
                .then((newAllowedOrigin: PostLogoutRedirectUri) => {
                    store.dispatch(action(ADD_CLIENT_POST_LOGOUT_REDIRECT_URI_SUCCESS, newAllowedOrigin));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ADD_CLIENT_POST_LOGOUT_REDIRECT_URI_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI:
            const deleteWithID: DeletePostLogoutRedirectUriRequest = (requestedAction.payload as DeletePostLogoutRedirectUriRequest);
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.removeClientPostLogoutRedirectUri(deleteWithID.clientId, deleteWithID.postLogoutRedirectUriId)
                .then(() => {
                    store.dispatch(action(DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI_SUCCESS, deleteWithID.postLogoutRedirectUriId));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(DELETE_CLIENT_POST_LOGOUT_REDIRECT_URI_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminClientPostLogoutRedirectUrisDataService;