import { API_SPINNER_CHANGE } from "./actionDefs";


export enum SpinnerDisplayState {
    Hidden,
    Pending,
    Visible
}


export interface SpinnerState {
    displayState: SpinnerDisplayState
};


export const initialState: SpinnerState = {
    displayState: SpinnerDisplayState.Hidden
};


export interface SpinnerAction {
    type: string;
    payload: SpinnerState
}


export function spinnerReducer(state: SpinnerState = initialState, action: SpinnerAction): SpinnerState {
    switch (action.type) {
        case API_SPINNER_CHANGE:
            return {
                displayState: action.payload.displayState
            };
        default:
            return state;
    }
}