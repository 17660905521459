import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddUserRequest, Sort } from '../../../../shared/utils/DataAccess';
import { StoreState } from '../../../../shared/redux/store';

import { enumerateUsers, addUser, updateUser, UpdateUserRequestWithId, editStart, editChanges, editCancel } from '../redux/actions';
import { AdminUserListState } from '../redux/reducer';
import AdminUserComponent from './AdminUserComponent';

import Button from '@material-ui/core/Button';
import AdminAddUserComponent from './AdminAddUserComponent';
import FullTableComponent, { DisplayType, Column, EnumerationRequest } from '../../../../shared/FullTable/components/FullTableComponent';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';


const AdminUsersPage: FunctionComponent = () => {
    const dispatch = useDispatch();


    const [isDialogOpen, setisDialogOpen] = useState<boolean>(false);


    const userListState: AdminUserListState = useSelector((state: StoreState) => {
        return state.adminUsersReducer
    });


    const Columns: Column[] = [
        { name: "Edit", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "GivenName", displayName: "Given Name", sortable: true, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Surname", displayName: "Surname", sortable: true, displayType: DisplayType.Always, textAlign: undefined },
        { name: "LoginEmail", displayName: "Login Email", sortable: true, displayType: DisplayType.Always, textAlign: undefined },
        { name: "LoginPhone", displayName: "Login Phone", sortable: true, displayType: DisplayType.Always, textAlign: undefined },
        { name: "EmailVerified", displayName: "Email Verified", sortable: false, displayType: DisplayType.Always, textAlign: "center" },
        { name: "Enabled", displayName: "Enabled", sortable: false, displayType: DisplayType.Always, textAlign: "center" },
        { name: "Created", displayName: "Created", sortable: false, displayType: DisplayType.Always, textAlign: "right" },
    ];


    useEffect(() => {
        dispatch(enumerateUsers(userListState.paginationState.enumerateUsersRequest));
    }, []);


    const apiEnumerate = (request: EnumerationRequest) => {
        var { enumerateUsersRequest } = userListState.paginationState;
        enumerateUsersRequest.order = request.order;
        enumerateUsersRequest.page = request.paging.page;
        enumerateUsersRequest.pageSize = request.paging.pageSize;
        enumerateUsersRequest.search = request.search;
        enumerateUsersRequest.sort = request.sort as Sort;

        dispatch(enumerateUsers(enumerateUsersRequest));
    };


    const onOpenAddUserDialog = () => {
        setisDialogOpen(true);
    }

    const onCloseAddUserDialog = () => {
        setisDialogOpen(false);
    }


    const onEditStart = (id: string) => {
        dispatch(editStart(id));
    }


    const onEditChanges = (id: string, hasChanges: boolean) => {
        dispatch(editChanges(id, hasChanges));
    }


    const onEditCancel = (id: string) => {
        dispatch(editCancel(id));
    }


    const onUpdateUser = (request: UpdateUserRequestWithId) => {
        dispatch(updateUser(request));
    }


    const onAddUser = (request: AddUserRequest) => {
        addUser(request);

        setisDialogOpen(false);

        var { enumerateUsersRequest } = userListState.paginationState;
        enumerateUsers(enumerateUsersRequest);
    }


    const { paginationState: pagination } = userListState;

    const enumerationRequest: EnumerationRequest = {
        search: pagination.enumerateUsersRequest.search,
        sort: pagination.enumerateUsersRequest.sort,
        order: pagination.enumerateUsersRequest.order,
        paging: {
            pageSizes: [2, 10, 25],
            pageSize: pagination.enumerateUsersRequest.pageSize,
            page: pagination.enumerateUsersRequest.page,
            totalRecords: pagination.totalRecords
        }
    };


    const classes = makeStyles({
        page: {
            width: '90%',
            margin: '80px auto 0px',
            padding: '20px'
        },
    })();


    return (
        <Paper className={classes.page}>
            <h1>Users</h1>
            Below are all the users that have been registered with our auth services.

            <FullTableComponent Columns={Columns} enumerationRequest={enumerationRequest} apiEnumerate={apiEnumerate} showPaging={true} showSearch={true}>
                {userListState ? userListState.list.allIds.map(id => (
                    <AdminUserComponent
                        key={id}
                        userInformation={userListState.list.byId[id]}
                        onUpdateUser={onUpdateUser}
                        onEditStart={onEditStart}
                        onEditChanges={onEditChanges}
                        onEditCancel={onEditCancel}
                    ></AdminUserComponent>
                )) : ""}
            </FullTableComponent>
            <div style={{ textAlign: 'center', marginTop: '20px' }}><Button variant="outlined" onClick={onOpenAddUserDialog}>Add User</Button></div>
            <AdminAddUserComponent visible={isDialogOpen} onAddUser={onAddUser} onClose={onCloseAddUserDialog}></AdminAddUserComponent>
        </Paper>
    );


}


export default AdminUsersPage;