import { AdminResourcesScopeAction } from "./actions";
import { ResourceScopeInformation } from "../../../../../../../../shared/utils/DataAccess";
import {
    UPDATE_RESOURCE_SCOPE_SUCCESS, LOAD_RESOURCE_SCOPE_SUCCESS,
} from "./actionDef";


export interface AdminResourcesScopeState {
    scope: ResourceScopeInformation,
};


export const initialState: AdminResourcesScopeState = {
    scope: ResourceScopeInformation.fromJS({}),
};


export function adminResourcesScopeReducer(state: AdminResourcesScopeState = initialState, action: AdminResourcesScopeAction): AdminResourcesScopeState {
    switch (action.type) {
        case LOAD_RESOURCE_SCOPE_SUCCESS:
            const scope = action.payload as ResourceScopeInformation;

            return {
                ...state,
                scope: scope
            };
        case UPDATE_RESOURCE_SCOPE_SUCCESS:
            const newScope = action.payload as ResourceScopeInformation;

            return {
                ...state,
                scope: newScope
            };
        default:
            return state;
    }
}