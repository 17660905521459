export const ENUMERATE_CLIENT_SCOPES = "ENUMERATE_CLIENT_SCOPES";
export type ENUMERATE_CLIENT_SCOPES = typeof ENUMERATE_CLIENT_SCOPES;
export const ENUMERATE_CLIENT_SCOPES_SUCCESS = "ENUMERATE_CLIENT_SCOPES_SUCCESS";
export type ENUMERATE_CLIENT_SCOPES_SUCCESS = typeof ENUMERATE_CLIENT_SCOPES_SUCCESS;
export const ENUMERATE_CLIENT_SCOPES_ERROR = "ENUMERATE_CLIENT_SCOPES_ERROR";
export type ENUMERATE_CLIENT_SCOPES_ERROR = typeof ENUMERATE_CLIENT_SCOPES_ERROR;

export const ADD_CLIENT_SCOPE = "ADD_CLIENT_SCOPE";
export type ADD_CLIENT_SCOPE = typeof ADD_CLIENT_SCOPE;
export const ADD_CLIENT_SCOPE_SUCCESS = "ADD_CLIENT_SCOPE_SUCCESS";
export type ADD_CLIENT_SCOPE_SUCCESS = typeof ADD_CLIENT_SCOPE_SUCCESS;
export const ADD_CLIENT_SCOPE_ERROR = "ADD_CLIENT_SCOPE_ERROR";
export type ADD_CLIENT_SCOPE_ERROR = typeof ADD_CLIENT_SCOPE_ERROR;

export const DELETE_CLIENT_SCOPE = "DELETE_CLIENT_SCOPE";
export type DELETE_CLIENT_SCOPE = typeof DELETE_CLIENT_SCOPE;
export const DELETE_CLIENT_SCOPE_SUCCESS = "DELETE_CLIENT_SCOPE_SUCCESS";
export type DELETE_CLIENT_SCOPE_SUCCESS = typeof DELETE_CLIENT_SCOPE_SUCCESS;
export const DELETE_CLIENT_SCOPE_ERROR = "DELETE_CLIENT_SCOPE_ERROR";
export type DELETE_CLIENT_SCOPE_ERROR = typeof DELETE_CLIENT_SCOPE_ERROR;
