export const LOAD_RESOURCE = "LOAD_RESOURCE";
export type LOAD_RESOURCE = typeof LOAD_RESOURCE;
export const LOAD_RESOURCE_SUCCESS = "LOAD_RESOURCE_SUCCESS";
export type LOAD_RESOURCE_SUCCESS = typeof LOAD_RESOURCE_SUCCESS;
export const LOAD_RESOURCE_ERROR = "LOAD_RESOURCE_ERROR";
export type LOAD_RESOURCE_ERROR = typeof LOAD_RESOURCE_ERROR;

export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
export type UPDATE_RESOURCE = typeof UPDATE_RESOURCE;
export const UPDATE_RESOURCE_SUCCESS = "UPDATE_RESOURCE_SUCCESS";
export type UPDATE_RESOURCE_SUCCESS = typeof UPDATE_RESOURCE_SUCCESS;
export const UPDATE_RESOURCE_ERROR = "UPDATE_RESOURCE_ERROR";
export type UPDATE_RESOURCE_ERROR = typeof UPDATE_RESOURCE_ERROR;
