import React, { FunctionComponent, useState } from 'react';
import { Select, makeStyles, MenuItem, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../tabs/component/TabPanel';
import ResponsiveTabComponent, { ResponstiveTabComponentProps } from './ResponstiveTabPanelComponent';


export interface Tab {
    id: number;
    title: string;
}


type Props = {
    onChange?(oldId: number, newId: number): void,
    tabs: Tab[],
    children: React.ReactElement<typeof ResponsiveTabComponent> | Array<React.ReactElement<typeof ResponsiveTabComponent>>;
}


const ResponstiveTabsComponent: FunctionComponent<Props> = (props: Props) => {


    const [value, setValue] = useState<number>(props.tabs[0].id);


    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    const onSelectFieldChange = (e: React.ChangeEvent<{ name?: string; value: any }>) => {
        const newValue: number = e.target.value as number;
        if (props.onChange) {
            props.onChange(value, newValue);
        }
        setValue(newValue);
    }


    const onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (props.onChange) {
            props.onChange(value, newValue);
        }
        setValue(newValue);
    };


    const classes = makeStyles({
        useDropdown: {
            display: "none",
            ['@media (max-width:600px)']: {
                display: "inherit"
            }
        },
        useTabs: {
            display: "inherit",
            ['@media (max-width:600px)']: {
                display: "none"
            }
        },
    })();


    const { tabs, children } = props;


    return (
        <>
            <Select
                id="tabSelector"
                fullWidth={true}
                onChange={onSelectFieldChange}
                value={value}
                className={classes.useDropdown}
            >
                {tabs.map((tab: Tab) => {
                    return <MenuItem key={tab.id} value={tab.id}>{tab.title}</MenuItem>
                })}
            </Select>
            <Tabs value={value} onChange={onTabChange} aria-label="simple tabs example" className={classes.useTabs}>
                {tabs.map((tab: Tab) => {
                    return <Tab key={tab.id} label={tab.title} {...tabsProps(tab.id)} />
                })}
            </Tabs>
            {React.Children.map(children, (child: React.ReactNode) => {
                const panelChild: any = child as any;
                const panelProps: ResponstiveTabComponentProps = panelChild.props as ResponstiveTabComponentProps
                return <TabPanel value={value} index={panelProps.id}>
                    {panelProps.children}
                </TabPanel>
            })}
        </>);


}


export default ResponstiveTabsComponent