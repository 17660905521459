import { Middleware } from 'redux';

import { SigninAction } from './actions';
import { ADD_APP_PASSWORD_ERROR, ENUMERATE_APP_PASSWORDS_ERROR, REMOVE_APP_PASSWORD_ERROR } from '../../app-passwords/redux/actionDef';
import { ENUMERATE_GRANTS_ERROR, REVOKE_GRANT_ERROR } from '../../grants/redux/actionDef';
import { LOAD_PROFILE_ERROR, UPDATE_PROFILE_ERROR } from '../../profile/redux/actionDef';


const signinDataService: Middleware = store => next => (action: SigninAction) => {
    next(action);

    switch (action.type) {
        case ENUMERATE_APP_PASSWORDS_ERROR:
        case ADD_APP_PASSWORD_ERROR:
        case REMOVE_APP_PASSWORD_ERROR:
        case ENUMERATE_GRANTS_ERROR:
        case REVOKE_GRANT_ERROR:
        case LOAD_PROFILE_ERROR:
        case UPDATE_PROFILE_ERROR:

            break;
        default:
            break;
    }
}

export default signinDataService;