import { Middleware } from "redux";
import {
    ENUMERATE_GRANTS, ENUMERATE_GRANTS_SUCCESS, ENUMERATE_GRANTS_ERROR,
    REVOKE_GRANT, REVOKE_GRANT_SUCCESS, REVOKE_GRANT_ERROR
} from "./actionDef";
import { GrantInformation } from "../../../shared/utils/DataAccess";
import APIClientFactory from "../../../shared/utils/APIClientFactory";
import { action } from "typesafe-actions";
import { RevokeGrantInformation } from "./actions";
import { SERVER_ERROR } from "../../../shared/error/redux/actionDefs";
import { ErrorState } from "../../../shared/error/redux/reducer";
import { API_SPINNER_CHANGE } from "../../../shared/spinner/redux/actionDefs";
import { SpinnerDisplayState, SpinnerState } from "../../../shared/spinner/redux/reducer";


const grantManagementDataService: Middleware = store => next => requestedAction => {
    next(requestedAction);

    const client = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ENUMERATE_GRANTS:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.enumerateGrants()
                .then((grants: GrantInformation[]) => {
                    store.dispatch(action(ENUMERATE_GRANTS_SUCCESS, grants));
                })
                .catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_GRANTS_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case REVOKE_GRANT:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            const revoke = requestedAction.payload as RevokeGrantInformation;
            client.revokeGrant(revoke.clientId)
                .then(() => {
                    store.dispatch(action(REVOKE_GRANT_SUCCESS, requestedAction.payload));
                })
                .catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(REVOKE_GRANT_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default grantManagementDataService;