import { Middleware } from 'redux';

import {
    AdministratorInformation, AddAdministratorRequest
} from '../../../../shared/utils/DataAccess';
import {
    ENUMERATE_ADMINISTRATORS, ENUMERATE_ADMINISTRATORS_SUCCESS, ENUMERATE_ADMINISTRATORS_ERROR,
    ADD_ADMINISTRATOR, ADD_ADMINISTRATOR_SUCCESS,
    REMOVE_ADMINISTRATOR, REMOVE_ADMINISTRATOR_SUCCESS
} from './actionDef';
import { AdminAdministratorsAction } from './actions';
import APIClientFactory from '../../../../shared/utils/APIClientFactory';
import { action } from 'typesafe-actions';
import { SERVER_ERROR } from '../../../../shared/error/redux/actionDefs';
import { ErrorState } from '../../../../shared/error/redux/reducer';
import { API_SPINNER_CHANGE } from '../../../../shared/spinner/redux/actionDefs';
import { SpinnerDisplayState, SpinnerState } from '../../../../shared/spinner/redux/reducer';



const adminAdministratorsDataService: Middleware = store => next => (requestedAction: AdminAdministratorsAction) => {
    next(requestedAction);

    const client = APIClientFactory.getInstance();

    switch (requestedAction.type) {
        case ADD_ADMINISTRATOR:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.addAdministrator(requestedAction.payload as AddAdministratorRequest)
                .then((newAdministrator: AdministratorInformation) => {
                    store.dispatch(action(ADD_ADMINISTRATOR_SUCCESS, newAdministrator));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case ENUMERATE_ADMINISTRATORS:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.enumerateAdministrators()
                .then((administrators: AdministratorInformation[]) => {
                    store.dispatch(action(ENUMERATE_ADMINISTRATORS_SUCCESS, administrators));
                }).catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                    store.dispatch(action(ENUMERATE_ADMINISTRATORS_ERROR, reason));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        case REMOVE_ADMINISTRATOR:
            store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Pending } as SpinnerState));

            client.removeAdministrator(requestedAction.payload as string)
                .then(() => {
                    store.dispatch(action(REMOVE_ADMINISTRATOR_SUCCESS, requestedAction.payload));
                })
                .catch((reason: any) => {
                    store.dispatch(action(SERVER_ERROR, { errorDetails: reason } as ErrorState));
                })
                .finally(() => {
                    store.dispatch(action(API_SPINNER_CHANGE, { displayState: SpinnerDisplayState.Hidden } as SpinnerState));
                });
            break;
        default:
            break;
    }
}

export default adminAdministratorsDataService;