import React, { FunctionComponent } from 'react';

import { GrantInformation } from '../../../shared/utils/DataAccess';

import Button from '@material-ui/core/Button';
import { Grid, makeStyles } from '@material-ui/core';


interface Props {
    grant: GrantInformation
    onRevoke(grant: GrantInformation): void
}


const GrantComponent: FunctionComponent<Props> = (props: Props) => {

    const classes = makeStyles({
        container: {
            borderTop: 'solid 1px #000000',
            padding: '10px 0px'
        },
        name: {
            fontWeight: "bold",
            textAlign: "right",
            ['@media (max-width:600px)']: {
                textAlign: "left"
            }
        },
        btn: {
            textAlign: "right"
        }
    })();


    const onRevoke = () => {
        props.onRevoke(props.grant);
    }


    const { grant } = props;


    return (
        <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12} sm={3}><div className={classes.name}>Client:</div></Grid>
            <Grid item xs={12} sm={9}>{grant.clientName}</Grid>

            <Grid item xs={12} sm={3}><div className={classes.name}>Website:</div></Grid>
            <Grid item xs={12} sm={9}>{grant.clientUrl}</Grid>

            <Grid item xs={12} sm={3}><div className={classes.name}>Created:</div></Grid>
            <Grid item xs={12} sm={9}>{grant.created.toString()}</Grid>

            <Grid item xs={12} sm={3}><div className={classes.name}>Expires:</div></Grid>
            <Grid item xs={12} sm={9}>{grant.expires.toString()}</Grid>

            <Grid item xs={12} sm={3}><div className={classes.name}>Identity Grants:</div></Grid>
            <Grid item xs={12} sm={9}>{grant.identityGrantNames.toString()}</Grid>

            <Grid item xs={12} sm={3}><div className={classes.name}>API Grants:</div></Grid>
            <Grid item xs={12} sm={9}>{grant.apiGrantNames.toString()}</Grid>

            <Grid item xs={12} sm={12}><div className={classes.btn}>
                <Button variant="outlined" onClick={onRevoke}>Revoke Access</Button></div>
            </Grid>

        </Grid>
    );


}


export default GrantComponent;