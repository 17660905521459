import { AddClientClaimRequest, ClientClaimInformation, UpdateClientClaimRequest } from "../../../../../../shared/utils/DataAccess";
import { ENUMERATE_CLIENT_CLAIMS, ADD_CLIENT_CLAIM, DELETE_CLIENT_CLAIM, DISPLAY_STATE_CHANGE, UPDATE_CLIENT_CLAIM } from "./actionDef";
import { action } from "typesafe-actions";
import { DisplayState } from "../../../../../../shared/FullTable/components/FullTableRow";


export interface AdminClientClaimsAction {
    type: string;
    payload?:
    number | ChangeEditState |
    AddClaimRequestWithClientId | DeleteClaimRequest | AddClientClaimRequest | UpdateClientClaimRequestWithClientId |
    ClientClaimInformation | ClientClaimInformation[];
}


export interface AddClaimRequestWithClientId {
    clientId: number,
    request: AddClientClaimRequest
}


export interface UpdateClientClaimRequestWithClientId {
    clientId: number,
    id: number,
    request: UpdateClientClaimRequest
}


export interface DeleteClaimRequest {
    clientId: number,
    ClaimId: number
}


export interface ChangeEditState {
    id: number;
    displayState: DisplayState;
}


export const loadClientClaims = (clientId: number): AdminClientClaimsAction => action(ENUMERATE_CLIENT_CLAIMS, clientId);
export const addClientClaim = (clientId: number, request: AddClientClaimRequest): AdminClientClaimsAction =>
    action(
        ADD_CLIENT_CLAIM,
        { clientId: clientId, request: request } as AddClaimRequestWithClientId);
export const deleteClientClaim = (clientId: number, ClaimId: number): AdminClientClaimsAction =>
    action(DELETE_CLIENT_CLAIM,
        { clientId: clientId, ClaimId: ClaimId });

export const updateClientClaim = (clientId: number, id: number, request: UpdateClientClaimRequest): AdminClientClaimsAction =>
    action(
        UPDATE_CLIENT_CLAIM,
        { clientId: clientId, id: id, request: request } as UpdateClientClaimRequestWithClientId);

export const editStart = (id: number): AdminClientClaimsAction => action(DISPLAY_STATE_CHANGE, { id: id, displayState: DisplayState.Edit });
export const editChanges = (id: number, hasChanges: boolean) => action(DISPLAY_STATE_CHANGE, { id: id, displayState: hasChanges ? DisplayState.NotSaved : DisplayState.Edit });
export const editCancel = (id: number): AdminClientClaimsAction => action(DISPLAY_STATE_CHANGE, { id: id, displayState: DisplayState.ReadOnly });