import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../shared/redux/store';

import { AddAppPasswordRequest, AppPasswordInformation, NewAppPasswordInformation } from '../../../shared/utils/DataAccess';
import { enumerateAppPasswords, addAppPassword, removeAppPassword } from '../redux/actions';
import { AppPasswordsState } from '../redux/reducer';
import AppPasswordComponent from './AppPasswordComponent';
import NewAppPasswordComponent from './NewAppPasswordComponent';

import { Paper, makeStyles, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


const AppPasswordPage: FunctionComponent = () => {
    const dispatch = useDispatch();


    const appPasswordsState: AppPasswordsState = useSelector((state: StoreState) => {
        return state.appPasswordsReducer
    });


    const GetOneYearFromToday = () => {
        var tzoffset: number = (new Date()).getTimezoneOffset() * 60000;

        var oneYearFromTodayGMT: Date = new Date();
        oneYearFromTodayGMT.setHours(0);
        oneYearFromTodayGMT.setMinutes(0);
        oneYearFromTodayGMT.setSeconds(0);
        oneYearFromTodayGMT = new Date(oneYearFromTodayGMT.getTime() - tzoffset);
        oneYearFromTodayGMT.setFullYear(oneYearFromTodayGMT.getFullYear() + 1);

        return oneYearFromTodayGMT;
    }


    const [isDialogOpen, setisDialogOpen] = useState<boolean>(false);


    const [addAppPasswordRequest, setaddAppPasswordRequest] = useState<AddAppPasswordRequest>(AddAppPasswordRequest.fromJS({
        appName: "",
        dateExpires: GetOneYearFromToday()
    }));


    useEffect(() => {
        dispatch(enumerateAppPasswords());
    }, []);


    useEffect(() => {
        if (!appPasswordsState.newAppPassword || appPasswordsState.newAppPassword.password.length === 0) {
            return;
        }

        setisDialogOpen(true);
        setaddAppPasswordRequest(AddAppPasswordRequest.fromJS({
            appName: '',
            dateExpires: GetOneYearFromToday()
        }));
    }, [appPasswordsState.newAppPassword]);


    const onRevoke = (appPassword: AppPasswordInformation) => {
        dispatch(removeAppPassword(appPassword.id));
    }


    const onSubmit = () => {
        dispatch(addAppPassword(addAppPasswordRequest));
    }


    const onAppNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setaddAppPasswordRequest(AddAppPasswordRequest.fromJS({
            ...addAppPasswordRequest,
            appName: e.currentTarget.value
        }));
    }


    const onDateExpiresChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setaddAppPasswordRequest(AddAppPasswordRequest.fromJS({
            ...addAppPasswordRequest,
            dateExpires: new Date(e.target.valueAsNumber)
        }));
    };


    const onDialogClose = () => {
        appPasswordsState.newAppPassword = undefined;
        setisDialogOpen(false);
    }


    const displayInfo: NewAppPasswordInformation =
        appPasswordsState.newAppPassword ||
        new NewAppPasswordInformation({
            id: "",
            appName: "",
            dateCreated: new Date(),
            dateExpires: new Date(),
            password: ""
        });


    const classes = makeStyles({
        page: {
            width: '90%',
            margin: '80px auto 0px',
            padding: '20px'
        },
        container: {
            borderTop: 'solid 1px #000000',
            borderBottom: 'solid 1px #000000',
            padding: '20px',
            marginTop: '20px',
            marginBottom: '20px'
        }
    })();


    return (
        <Paper className={classes.page}>

            <h1>Application Passwords</h1>
            Below is the list of application passwords you have configured.  These can be used for applications that do not support multi-factor authentication, such as an email client.

            <Grid container spacing={2} className={classes.container}>
                {appPasswordsState.list.allIds.map(id => (
                    <AppPasswordComponent key={id} appPassword={appPasswordsState.list.byId[id]} onRevoke={onRevoke}></AppPasswordComponent>
                ))}
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <TextField
                        id="appName"
                        label="New Application Name"
                        value={addAppPasswordRequest.appName}
                        onChange={onAppNameChange}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        id="datetime-local"
                        label="Expiration Date"
                        type="datetime-local"
                        name="dateExpires"
                        value={addAppPasswordRequest.dateExpires.toISOString().slice(0, 16)}
                        onChange={onDateExpiresChange}
                        // className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <Button variant="outlined" onClick={onSubmit}>Add Application</Button>
                </Grid>
            </Grid>

            <NewAppPasswordComponent open={isDialogOpen} newAppPassword={displayInfo} onClose={onDialogClose} />
        </Paper>
    );


}


export default AppPasswordPage;