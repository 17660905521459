import { AddRedirectUriRequest, RedirectUri } from "../../../../../../shared/utils/DataAccess";
import { ENUMERATE_CLIENT_REDIRECT_URIS, ADD_CLIENT_REDIRECT_URI, DELETE_CLIENT_REDIRECT_URI } from "./actionDef";
import { action } from "typesafe-actions";


export interface AdminClientRedirectUrisAction {
    type: string;
    payload?: number | AddRedirectUriRequestWithClientId | DeleteRedirectUriRequest | RedirectUri | RedirectUri[];
}


export interface AddRedirectUriRequestWithClientId {
    clientId: number,
    request: AddRedirectUriRequest
}


export interface DeleteRedirectUriRequest {
    clientId: number,
    RedirectUriId: number
}


export const loadClientRedirectUris = (clientId: number): AdminClientRedirectUrisAction => action(ENUMERATE_CLIENT_REDIRECT_URIS, clientId);
export const addClientRedirectUris = (clientId: number, RedirectUri: string): AdminClientRedirectUrisAction =>
    action(
        ADD_CLIENT_REDIRECT_URI,
        { clientId: clientId, request: { redirectUri: RedirectUri } as AddRedirectUriRequest } as AddRedirectUriRequestWithClientId);
export const deleteClientRedirectUris = (clientId: number, RedirectUriId: number): AdminClientRedirectUrisAction =>
    action(DELETE_CLIENT_REDIRECT_URI,
        { clientId: clientId, RedirectUriId: RedirectUriId });

