import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { TextField, Button } from '@material-ui/core';
import { ResourceInformation, ResourceClaimInformation, AddResourceClaimRequest } from '../../../../../../shared/utils/DataAccess';
import { StoreState } from '../../../../../../shared/redux/store';
import FullTableComponent, { DisplayType, Column } from '../../../../../../shared/FullTable/components/FullTableComponent';
import ResourceClaimComponent from './ResourceClaimComponent';
import { AdminResourceClaimsState } from '../redux/reducer';
import { loadResourceClaims, deleteResourceClaim, addResourceClaim } from '../redux/actions';


type Props = {
    resourceInformation: ResourceInformation
}


const ResourceClaimsComponent: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();


    const [addResourceClaimRequest, setResourceClaimRequest] = useState<AddResourceClaimRequest>(AddResourceClaimRequest.fromJS({
        type: "",
        value: ""
    }));


    const resourceClaimsState: AdminResourceClaimsState = useSelector((state: StoreState) => {
        return state.adminResourceClaimsReducer;
    });


    const apiEnumerate = () => {
        if (props.resourceInformation.id) {
            dispatch(loadResourceClaims(props.resourceInformation.id));
        }
    };


    const onDelete = (request: number) => {
        dispatch(deleteResourceClaim(props.resourceInformation.id, request));
    };


    useEffect(() => {
        apiEnumerate();
    }, [props.resourceInformation]);


    const hasChanges = (): boolean => {
        return (addResourceClaimRequest.type.length > 0);
    }


    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newRequest: ResourceClaimInformation = ResourceClaimInformation.fromJS({
            ...addResourceClaimRequest,
            [e.currentTarget.id]: e.currentTarget.value
        });

        setResourceClaimRequest(newRequest);
    }


    const onSubmit = () => {
        dispatch(addResourceClaim(props.resourceInformation.id, addResourceClaimRequest));

        setResourceClaimRequest(AddResourceClaimRequest.fromJS({
            type: "",
            value: ""
        }));
    }


    const Columns: Column[] = [
        { name: "Type", displayName: "Type", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
        { name: "Delete", displayName: "", sortable: false, displayType: DisplayType.Always, textAlign: undefined },
    ];


    const classes = makeStyles({
        row: {
            maxWidth: "500px"
        }
    })();


    return (
        <>
            <div className={classes.row}>
                <FullTableComponent Columns={Columns} apiEnumerate={apiEnumerate}>
                    {resourceClaimsState ? resourceClaimsState.list.allIds.map(id => (
                        <ResourceClaimComponent
                            key={id}
                            claim={resourceClaimsState.list.byId[id]}
                            onDelete={onDelete}
                        ></ResourceClaimComponent>
                    )) : ""}
                </FullTableComponent>
            </div>
            <div className={classes.row} style={{ marginTop: '50px' }}>
                <TextField
                    id="type"
                    label="New Claim Type"
                    fullWidth={true}
                    value={addResourceClaimRequest.type}
                    onChange={onTextFieldChange}
                />
            </div>
            <div className={classes.row} style={{ textAlign: 'right' }}>
                <Button variant="outlined" color="primary" onClick={onSubmit} disabled={!hasChanges()}>Add Claim</Button>
            </div>
        </>
    );


}


export default ResourceClaimsComponent;