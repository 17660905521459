import { AdminResourceClaimsAction, ChangeEditState } from "./actions";
import { ResourceClaimInformation } from "../../../../../../shared/utils/DataAccess";
import {
    ENUMERATE_RESOURCE_CLAIMS_SUCCESS, ENUMERATE_RESOURCE_CLAIMS_ERROR,
    ADD_RESOURCE_CLAIM_SUCCESS, ADD_RESOURCE_CLAIM_ERROR,
    DELETE_RESOURCE_CLAIM_SUCCESS, DELETE_RESOURCE_CLAIM_ERROR, DISPLAY_STATE_CHANGE,
} from "./actionDef";
import _ from "lodash";
import { DisplayState } from "../../../../../../shared/FullTable/components/FullTableRow";


export interface ResourceClaimInformationDisplay extends ResourceClaimInformation {
    displayState: DisplayState
}


export interface AdminResourceClaimsState {
    list: {
        byId: {
            [id: number]: ResourceClaimInformationDisplay
        };
        allIds: number[];
    };
    newClaim?: ResourceClaimInformation;
};


export const initialState: AdminResourceClaimsState = {
    list: {
        byId: {},
        allIds: []
    },
    newClaim: undefined
};


export function adminResourceClaimsReducer(state: AdminResourceClaimsState = initialState, action: AdminResourceClaimsAction): AdminResourceClaimsState {
    switch (action.type) {
        case DISPLAY_STATE_CHANGE:
            const stateChange = action.payload as ChangeEditState;

            return {
                ...state,
                list: {
                    ...state.list,
                    byId: {
                        ...state.list.byId,
                        [stateChange.id]: {
                            ...state.list.byId[stateChange.id],
                            displayState: stateChange.displayState
                        } as ResourceClaimInformationDisplay
                    }
                }
            };

        case ENUMERATE_RESOURCE_CLAIMS_SUCCESS:
            const claimsInfo = action.payload as ResourceClaimInformation[];

            var claims: ResourceClaimInformationDisplay[] = [];
            claimsInfo.forEach(claim => {
                claims.push({
                    ...claim,
                    displayState: DisplayState.ReadOnly
                } as ResourceClaimInformationDisplay);
            });

            return {
                ...state,
                list: {
                    byId: {
                        ..._.keyBy(claims, "id")
                    },
                    allIds: _.map(claims, "id")
                }
            };
        case ADD_RESOURCE_CLAIM_SUCCESS:
            const newClaim = action.payload as ResourceClaimInformation;

            var newAddUpdateAllIds: number[] = state.list.allIds;
            var found: boolean = false;
            newAddUpdateAllIds.forEach((id: number) => {
                if (id === newClaim.id) {
                    found = true;
                }
            });

            if (!found) {
                newAddUpdateAllIds.push(newClaim.id)
            }

            return {
                ...state,
                list: {
                    byId: {
                        ...state.list.byId,
                        [newClaim.id]: {
                            ...newClaim,
                            displayState: DisplayState.ReadOnly
                        } as ResourceClaimInformationDisplay
                    },
                    allIds: newAddUpdateAllIds
                },
                newClaim: newClaim
            };
        case DELETE_RESOURCE_CLAIM_SUCCESS:
            const newAllIds = [...state.list.allIds];
            const idIndex = newAllIds.findIndex(el => el === action.payload as number);
            newAllIds.splice(idIndex, 1);

            const newByids = { ...state.list.byId };
            delete newByids[action.payload as number];

            return {
                ...state,
                list: {
                    byId: {
                        ...newByids
                    },
                    allIds: newAllIds
                }
            };
        case ENUMERATE_RESOURCE_CLAIMS_ERROR:
        case ADD_RESOURCE_CLAIM_ERROR:
        case DELETE_RESOURCE_CLAIM_ERROR:
        default:
            return state;
    }
}